.page-frist .page-header {
  display: none;
}

.cv-chart-block {
  display: block;
  box-sizing: border-box;
  height: 235px;
  width: 521px;
}

.btn-sf i {
  position: relative;
  font-size: 10px;
  color: #475160;
  margin-right: 5px;
  top: -2px;
}

.accordion-button {
  position: relative;
  padding: 0 !important;
  color: #475160 !important;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* font-size: 1rem; */
  /* background-color: var(--bs-accordion-btn-bg); */
  border: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

.accordion-button:not(.collapsed) .show-filters-btn {
  display: none;
}

.accordion-button:not(.collapsed) {
  background: #475160;
}

.accordion-button:not(.collapsed) .hide-filters-btn {
  display: block !important;
  font-size: 16px;
  color: #ffffff;
  line-height: 44px;
}

.accordion-button:not(.collapsed) i {
  color: #ffffff;
}

.accordion-button::after {
  background-image: none;
  content: none;
  font-family: feather !important;
}

.accordion-item {
  background-color: #f5f7f9 !important;
  border: 1px solid #f5f7f9 !important;
}

.accordion-collapse.show {
  margin-top: 1rem;
  position: inherit;
  margin-bottom: 13rem;
}

.accordion-collapse.show .accordion-body {
  background: #f1f3f6;
  border-radius: 20px;
  padding: 15px;
  width: 91rem;
  height: auto;
  position: absolute;
  top: 4rem;
}

.accordion-collapse .accordion-body {
  background: #f1f3f6;
  border-radius: 20px;
  padding: 15px;
  width: 91rem;
  height: auto;
  position: absolute;
  top: 4rem;
}

/* .Select__control .Select__indicators {
  display: none;
} */

.Select__control {
  width: 120px;
  border-radius: 12px 0 0 12px !important;
  border: 0px solid !important;
  height: 100%;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border: 0px !important;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.MuiFormControl-root .MuiOutlinedInput-root {
  padding-right: 0 !important;
  height: 38px;
  width: 122px;
}

.MuiFormControl-root .MuiFormLabel-root {
  display: none;
}

.MuiInputBase-root input {
  font-size: 14px;
}

.wrapper_form_filter_date_time_dsh {
  width: 270px;
  border-radius: 0 12px 12px 0 !important;
}

.wrapper_form_filter_date_time {
  width: 270px;
  border-radius: 0 12px 12px 0 !important;
}

.cuttomdate .css-13cymwt-control {
  height: 46px;
}

.Select__single-value {
  padding-left: 10px;
}

.wrapper_dsh_filter_search {
  width: auto;
  border: 1px solid #e2e5e9;
  border-radius: 12px;
  padding: 4px 4px 4px 10px;
}

.select-dsh-filter-items {
  width: 80%;
  font-size: 14px;
  border: 0px solid;
  border-radius: 8px;
  height: 34px;
  color: #475160;
}

.chart-line-dsh,
.chart-line-dsh .chart-dropshadow,
.chart-line-dsh .chart-dropshadow .apexcharts-canvas,
.chart-line-dsh .chart-dropshadow .apexcharts-canvas svg {
  height: inherit !important;
  min-height: auto !important;
}

.css-b62m3t-container {
  height: 100%;
}

.custom-filter-dash .css-1jqq78o-placeholder {
  display: flex;
  overflow: hidden;
  min-inline-size: max-content;
}

.wrapper-data-table-ds {
  position: relative !important;
}

.row_wrapper_filter {
  margin-left: 1px;
  margin-top: 10px;
}
.row_wrapper_filter fieldset {
  border: unset;
}

.select-filter-items .select__control {
  border: 0;
  border-radius: 8px;
  width: 100%;
}
.select-filter-items .select__control .select__indicator-separator {
  width: 0;
}

.Select__control {
  width: 100%;
}

.select-filter-items {
  /* width: 80%; */
  font-size: 14px;
  border: 0px solid;
  border-radius: 8px;
  height: 100%;
  color: #475160;
}

.custom-inner .css-13cymwt-control {
  font-size: 14px;
}

.date-input {
  display: contents;
}

.select-filter-items-date {
  width: 40% !important;
}
.wrapper_form_filter_date_time {
  width: 59% !important;
}
.wrapper_form_filter_date_time .MuiFormControl-root.MuiTextField-root {
  width: 100% !important;
}
.wrapper_form_filter_date_time
  .MuiFormControl-root.MuiTextField-root
  .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100% !important;
}
.css-t3ipsp-control {
  box-shadow: unset !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  width: 1px;
  background-color: hsl(0, 0%, 80%);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}

.css-1nmdiq5-menu {
  top: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}
.css-1nmdiq5-menu {
  top: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}
.css-1nmdiq5-menu {
  top: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  box-sizing: border-box;
}



.dashboard-datepicker-block {
  background: #fff;
  border-left: 1px solid #f5f7f9;
  border-radius: 0 12px 12px 0;
  padding-left: 10px;
  overflow: hidden;
}
.dashboard-datepicker-block .input-datepicker {
  width: 90px;
  border: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.dashboard-datepicker-block .bi-calendar-minus-fill {
  font-size: 16px;
  line-height: 42px;
  margin-right: 10px;
}
.dashboard-datepicker-block .bi-arrow-right-short {
  font-size: 24px;
  line-height: 42px;
}
.select-border-field-block {
  border: 1px solid #e2e5e9;
  border-radius: 12px;
  padding: 4px 4px 4px 10px;
  gap: 10px;
}
.select-border-field-block .label-block {
  margin-top: 5px;
}
.select-border-field-block .form-select {
  width: 135px;
  border: 0;
  border-radius: 8px;
  height: 34px;
  line-height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.dashboard-section-wrapper {
  margin-top: 20px;
  gap: 20px;
}
.dashboard-section .row {
  margin-top: 20px;
}
.dashboard-section .row:first-child {
  margin-top: 0;
}
.dashboard-card {
  height: 100%;
  border: 2px solid #F1F3F6;
  border-radius: 20px;
  background: #fff;
  padding: 24px;
}
.dashboard-card h3 {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 10px;
  padding-left: 10px;
}
.dashboard-card .deliver-label {
  font-size: 18px;
  line-height: 1;
  border: 1px solid #f1f3f6;
  border-radius: 8px;
  padding: 10px 14px;
  margin-top: -8px;
}
.dashboard-card .title-note {
  color: #afbbca;
}
.dashboard-card .chart-block {
  height: 235px;
  margin-top: 10px;
}
.dashboaord-info-card-section .info-card {
  /* width: 280px; */
  /* height: 150px; */
  height: 200px;
  background: #fff;
  border-radius: 20px;
  padding: 24px;
  margin-bottom: 20px;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
  position: relative;
}
.dashboaord-info-card-section .info-card:hover {
  color: #fff;
}
.dashboaord-info-card-section .info-card:hover .icon-block,
.dashboaord-info-card-section .info-card:hover .title-text {
  color: #fff;
}
.dashboaord-info-card-section .info-card:hover .icon-block i,
.dashboaord-info-card-section .info-card:hover .title-text i {
  color: #fff !important;
}
.dashboaord-info-card-section .info-card:hover .num-right-block p:nth-child(2) {
  color: #fff;
}
.dashboaord-info-card-section .info-card .title-text {
  color: #475160;
}
.dashboaord-info-card-section .info-card .icon-block {
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 1;
  border-radius: 12px;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.dashboaord-info-card-section .info-card .icon-block .bi-clipboard-minus {
  font-size: 18px;
  line-height: 48px;
}
.dashboaord-info-card-section .info-card .icon-block .fa-truck {
  font-size: 20px;
  line-height: 48px;
}
.dashboaord-info-card-section .info-card .num-block-wrapper {
  margin-top: 20px;
  gap: 10px;
}
.dashboaord-info-card-section .info-card .num-block {
  font-size: 32px;
  line-height: 1;
}
.dashboaord-info-card-section .info-card .num-right-block {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.dashboaord-info-card-section .info-card .num-right-block p {
  line-height: 1;
  margin: 0;
}
.dashboaord-info-card-section .info-card .num-right-block p:nth-child(2),
.since-last {
  color: #707784;
  font-size: 12px;
}
.dashboaord-info-card-section
  .info-card
  .num-right-block
  .bi-arrow-right-short {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* gmv-card */
.dashboaord-info-card-section .info-card.gmv-card:hover .money-up-green,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-up-green p,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-default,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-default p,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-up-green p.since-last,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-up-green i
.dashboaord-info-card-section .info-card.gmv-card:hover .money-down-red,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-down-red p,
.dashboaord-info-card-section .info-card.gmv-card:hover p.since-last,
.dashboaord-info-card-section .info-card.gmv-card:hover .money-down-red p i {
  color: #FFF!important;
}

.dashboaord-info-card-section .info-card.gmv-card:hover {
  background: #99d3f2;
}

.dashboaord-info-card-section .info-card.gmv-card:hover .img-arrow-color{
  filter: brightness(0) invert(1);
}
.dashboaord-info-card-section .info-card.gmv-card:hover .icon-block {
  background: #bde4f5;
}
.dashboaord-info-card-section .info-card.gmv-card .icon-block {
  background: #e3f5fb;
}
.dashboaord-info-card-section .info-card.gmv-card .icon-block i {
  color: #99d3f2;
}

/* credits-card */
.dashboaord-info-card-section .info-card.credits-card:hover .money-up-green,
.dashboaord-info-card-section .info-card.credits-card:hover .money-up-green p,
.dashboaord-info-card-section .info-card.credits-card:hover .money-default,
.dashboaord-info-card-section .info-card.credits-card:hover .money-default p,
.dashboaord-info-card-section .info-card.credits-card:hover .money-up-green p.since-last,
.dashboaord-info-card-section .info-card.credits-card:hover .money-up-green i
.dashboaord-info-card-section .info-card.credits-card:hover .money-down-red,
.dashboaord-info-card-section .info-card.credits-card:hover .money-down-red p,
.dashboaord-info-card-section .info-card.credits-card:hover p.since-last,
.dashboaord-info-card-section .info-card.credits-card:hover .money-down-red p i {
  color: #FFF!important;
}

.dashboaord-info-card-section .info-card.credits-card:hover {
  background: #ecb888;
}

.dashboaord-info-card-section .info-card.credits-card:hover .img-arrow-color{
  filter: brightness(0) invert(1);
}

.dashboaord-info-card-section .info-card.credits-card:hover .icon-block {
  background: #f5d49e;
}
.dashboaord-info-card-section .info-card.credits-card:hover .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.credits-card:hover p:first-child {
  color: #fff;
}
.dashboaord-info-card-section .info-card.credits-card .icon-block {
  background: #fbf0cc;
}
.dashboaord-info-card-section .info-card.credits-card .icon-block i {
  color: #ecb888;
}
.dashboaord-info-card-section .info-card.credits-card .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.credits-card p:first-child {
  color: #f5d49e;
}


/* visits-card */
.dashboaord-info-card-section .info-card.visits-card:hover .money-up-green,
.dashboaord-info-card-section .info-card.visits-card:hover .money-up-green p,
.dashboaord-info-card-section .info-card.visits-card:hover .money-default,
.dashboaord-info-card-section .info-card.visits-card:hover .money-default p,
.dashboaord-info-card-section .info-card.visits-card:hover .money-up-green p.since-last,
.dashboaord-info-card-section .info-card.visits-card:hover .money-up-green i
.dashboaord-info-card-section .info-card.visits-card:hover .money-down-red,
.dashboaord-info-card-section .info-card.visits-card:hover .money-down-red p,
.dashboaord-info-card-section .info-card.visits-card:hover p.since-last,
.dashboaord-info-card-section .info-card.visits-card:hover .money-down-red p i {
  color: #FFF!important;
}

.dashboaord-info-card-section .info-card.visits-card:hover {
  background: #475160;
}
.dashboaord-info-card-section .info-card.visits-card:hover .img-arrow-color {
  filter: brightness(0) invert(1);
}

.dashboaord-info-card-section .info-card.visits-card:hover .icon-block {
  background: #92a3b0;
}
.dashboaord-info-card-section .info-card.visits-card .icon-block {
  background: #e2e5e9;
}
.dashboaord-info-card-section .info-card.visits-card .icon-block i {
  color: #92a3b0;
}

/* deliveries-card */

.dashboaord-info-card-section .info-card.deliveries-card:hover .money-up-green,
.dashboaord-info-card-section .info-card.deliveries-card:hover .money-up-green p,
.dashboaord-info-card-section .info-card.deliveries-card:hover .money-default,
.dashboaord-info-card-section .info-card.deliveries-card:hover .money-default p,
.dashboaord-info-card-section .info-card.deliveries-card:hover .money-up-green p.since-last,
.dashboaord-info-card-section .info-card.deliveries-card:hover .money-up-green i
.dashboaord-info-card-section .info-card.deliveries-card:hover .money-down-red,
.dashboaord-info-card-section .info-card.deliveries-card:hover .money-down-red p,
.dashboaord-info-card-section .info-card.deliveries-card:hover p.since-last,
.dashboaord-info-card-section .info-card.deliveries-card:hover .money-down-red p i {
  color: #FFF!important;
}

.dashboaord-info-card-section .info-card.deliveries-card:hover {
  background: #add5b7;
}
.dashboaord-info-card-section .info-card.deliveries-card:hover .icon-block {
  background: #bee7c6;
}
.dashboaord-info-card-section .info-card.deliveries-card:hover .img-arrow-color {
  filter: brightness(0) invert(1);
}
.dashboaord-info-card-section
  .info-card.deliveries-card:hover
  .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.deliveries-card:hover p:first-child {
  color: #fff;
}
.dashboaord-info-card-section .info-card.deliveries-card .icon-block {
  background: #daf3d7;
}
.dashboaord-info-card-section .info-card.deliveries-card .icon-block i {
  color: #add5b7;
}
.dashboaord-info-card-section .info-card.deliveries-card .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.deliveries-card p:first-child {
  color: #add5b7;
}


.money-default,
.money-default p {
  color: #475160!important;
}

.money-up-green,
.money-up-green p {
  color: #70c2a8!important;
}
.money-down-red,
.money-down-red p {
  color: #e43346!important;
}

.select-single-value .select__single-value {
  padding-left: 10px;
}
.select-single-value .select__placeholder {
  padding-left: 10px;
}

.wrap-img-box img {
  margin-top: 10px;
  height: 24px;
}
.wrap-img-box-small img {
  margin-top: 15px;
  height: 18px;
}
.img-arrow-color {
  margin-left: 5px;
}