/*Color variables*/
/*Gradient variables*/
/*white variables*/
/*black variables*/
/*shadow variables*/
/*Dark Theme Variables*/
/*Transparent variables*/
body.transparent-mode {
  color: #fff;
  background-color: var(--transparent-body);
}

.transparent-mode.bg-img1 .login-img::before,
.transparent-mode.bg-img2 .login-img::before,
.transparent-mode.bg-img3 .login-img::before,
.transparent-mode.bg-img4 .login-img::before {
  background: none;
}

body.transparent-mode *::-webkit-scrollbar-thumb,
body.transparent-mode *:hover::-webkit-scrollbar-thumb {
  background: var(--transparent-body);
}

.transparent-mode {
  /*------ Accordion -------*/
  /* #accordion rotate icon option */
  /*----- Avatars -----*/
  /*-----Badges-----*/
  /*------ Breadcrumb ------*/
  /*------ Card -------*/
  /*------ Default Card Values -------*/
  /*Card maps*/
  /*------ Card -------*/
  /*------ Default Card Values -------*/
  /*Card maps*/
  /*------ Carousel -------*/
  /*------ Carousel -------*/
  /*------Drop Downs-------*/
  /*-----List Of Dropdwons-----*/
  /*------- Forms -------*/
  /*------ Modal -------*/
  /*------ Navigation -------*/
  /* #bs-collapse icon scale option */
  /*--------panel----------*/
  /*-----progress-----*/
  /*------ Tables -----*/
  /*---- Tags-----*/
  /*---------Thumbnails----------*/
  /*  ######## CUSTOM-STYLES ######## */
  /*----- Custom control -----*/
  /*----- Global Loader -----*/
  /* --------Added--------- css*/
  /* line 77, C:/wamp/www/github/addSlider/src/partials/_addSlider.scss */
  /*********************IE***************************/
  /*--- Offcanvas ---*/
  /*--Toast ---*/
  /*-----Gallery-----*/
  /*-----Lists-----*/
  /*------ Media object ------*/
  /*--scrollspy ---*/
  /*-----Pricing tables-----*/
  /*------ Pricing Styles ---------*/
  /*-- rating--*/
  /***** time-line*****/
  /*---- Time line -----*/
  /*----Timeline---*/
  /* ######## LAYOUT-STYLES ######## */
  /*Logo-center header */
  /*==============================================================================
                                Start Mobile CSS
  ===============================================================================*/
  /* ================== Mobile Menu Change Brake Point ================== */
  /* Extra @Media Query*/
  /* ######## LIB-STYLES ######## */
  /*----- Date Picker ----*/
  /*-----selectize ------*/
  /* ######## SIDEMENU-STYLES ######## */
  /*-- Subslide ---*/
  /*-- Subslide2 ---*/
  /* ######## TEMP-STYLES ######## */
  /*------ Charts styles ------*/
  /*----chart-drop-shadow----*/
  /*----chart-drop-shadow----*/
  /* ###### Chat  ###### */
  /* ###### Chat  ###### */
  /*----- Range slider -------*/
  /*----- Range slider -------*/
  /*----- Range slider -------*/
  /*----- Range slider -------*/
  /*----- Range slider -------*/
  /*--- WIZARD ELEMENTS ---*/
  /****** EQUAL COLUMN WIDTH STEP INDICATOR *****/
  /***** CUSTOM STYLES *****/
  /*--- WIZARD ELEMENTS ---*/
  /* ######## UTILITIES-STYLES ######## */
  /*--- gradient-backgrounds --*/
  /*------ Background colors -------*/
  /*--bg-transparents--*/
  /*--Box-shadow--*/
  /*------- Alignments & values-------*/
  /*----- Typography ------*/
  /*--text-shadow--*/
}

.transparent-mode table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.transparent-mode .dtr-bs-modal .dtr-details {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.transparent-mode .dtr-bs-modal .dtr-details tr td:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.transparent-mode caption {
  color: rgba(255, 255, 255, 0.5);
}

.transparent-mode hr {
  border-top-color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

.transparent-mode mark,
.transparent-mode .mark {
  background-color: #fcf8e3;
}

.transparent-mode .list-unstyled li {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode kbd {
  color: #fff;
  background-color: #343a40;
}

.transparent-mode pre {
  color: #fff;
}

@media print {
  .transparent-mode pre,
  .transparent-mode blockquote {
    border-color: #adb5bd;
  }

  .transparent-mode .badge {
    border-color: #000;
  }

  .transparent-mode .table td,
  .transparent-mode .text-wrap table td,
  .transparent-mode .table th,
  .transparent-mode .text-wrap table th {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  .transparent-mode .table-bordered th,
  .transparent-mode .text-wrap table th,
  .transparent-mode .table-bordered td,
  .transparent-mode .text-wrap table td {
    border-color: rgba(255, 255, 255, 0.2) !important;
  }
}

.transparent-mode body *::-webkit-scrollbar-thumb,
.transparent-mode body *:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode code {
  background: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode pre {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}

.transparent-mode .section-nav {
  background-color: #f8f9fa;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .accordionjs .acc_section {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .accordionjs .acc_section .acc_head h3:before {
  color: #fff;
}

.transparent-mode .accordionjs .acc_section .acc-header {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .accordionjs .acc_section .acc_head {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .accordionjs .acc_section.acc_active > .acc_head {
  color: #fff !important;
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .accordion-item {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
}

.transparent-mode .accordion-button {
  color: #fff;
}

.transparent-mode .accordion-button:focus {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .accordion-button:not(.collapsed) {
  color: #fff;
  background: var(--transparent-body);
}

.transparent-mode .accordion-button::after {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode #accordion .panel-default > .panel-heading {
  border: 0px solid rgba(255, 255, 255, 0.2);
}

.transparent-mode #accordion .panel-default .collapsing .panel-body {
  border-top: 0px solid transparent;
}

.transparent-mode .avatar-status {
  border: 2px solid var(--transparent-body);
}

.transparent-mode .avatar-list-stacked .avatar {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

.transparent-mode .btn-custom {
  background: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .blockquote-footer {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode blockquote {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .blockquote-reverse {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}

.transparent-mode .breadcrumb1 {
  background-color: var(--transparent-body);
}

.transparent-mode .breadcrumb-item1 a:hover {
  color: var(--primary-bg-color);
}

.transparent-mode .breadcrumb-item1 + .breadcrumb-item1::before {
  color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .breadcrumb-item1.active {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .btn-default {
  color: rgba(255, 255, 255, 0.6);
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-default:hover {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-default:focus,
.transparent-mode .btn-default.focus {
  box-shadow: 0 0 0 2px #e9e9f1;
}

.transparent-mode .btn-default.disabled,
.transparent-mode .btn-default:disabled {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-default:not(:disabled):not(.disabled):active,
.transparent-mode .btn-default:not(:disabled):not(.disabled).active {
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-light {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-light:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-light:focus,
.transparent-mode .btn-light.focus {
  box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}

.transparent-mode .btn-light.disabled,
.transparent-mode .btn-light:disabled {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  opacity: 0.7;
}

.transparent-mode .btn-light:not(:disabled):not(.disabled):active,
.transparent-mode .btn-light:not(:disabled):not(.disabled).active {
  color: #495057;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-outline-default {
  color: #fff;
  background: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-outline-default:hover {
  color: #282f53;
  background: #e9e9f1;
}

.transparent-mode .btn-white {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-white:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-white:focus,
.transparent-mode .btn-white.focus {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.transparent-mode .btn-white.disabled,
.transparent-mode .btn-white:disabled {
  color: #495057;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #fff;
}

.transparent-mode .btn-white:not(:disabled):not(.disabled):active,
.transparent-mode .btn-white:not(:disabled):not(.disabled).active {
  color: #495057;
  background-color: #545478;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .show > .btn-white.dropdown-toggle {
  color: #495057;
  background-color: #545478;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-check:active + .btn-outline-primary,
.transparent-mode .btn-check:checked + .btn-outline-primary {
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.transparent-mode .btn-outline-primary {
  border-color: rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.6);
}

.transparent-mode .btn-outline-primary.active,
.transparent-mode .btn-outline-primary.dropdown-toggle.show,
.transparent-mode .btn-outline-primary:active {
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.transparent-mode .card {
  background-color: rgba(0, 0, 0, 0.2);
  border: inherit !important;
}

.transparent-mode .card-footer {
  border-top-color: rgba(255, 255, 255, 0.2);
  color: #6e7687;
}

.transparent-mode .card .card {
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.transparent-mode .card-body + .card-body {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .card-header {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .card-title small {
  color: #9aa0ac;
}

.transparent-mode .card-subtitle {
  color: #9aa0ac;
}

.transparent-mode .card-body + .card-table {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .card-body + .card-list-group {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .card-options {
  color: #9aa0ac;
}

.transparent-mode .card-options a:not(.btn) {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .card-options a:not(.btn):hover {
  color: #6e7687;
}

.transparent-mode .card-map {
  background: #e9ecef;
}

.transparent-mode .card .box h2 {
  color: #262626;
}

.transparent-mode .card .box h2 span {
  color: #fff;
}

.transparent-mode .card .box p {
  color: #262626;
}

.transparent-mode .card-footer {
  border-top-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
}

.transparent-mode .card-body + .card-body {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .card-title small {
  color: #9aa0ac;
}

.transparent-mode .card-subtitle {
  color: #9aa0ac;
}

.transparent-mode .card-body + .card-table {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .card-body + .card-list-group {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .card-options {
  color: #9aa0ac;
}

.transparent-mode .card-options a:not(.btn) {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .card-options a:not(.btn):hover {
  color: #6e7687;
}

.transparent-mode .card-map {
  background: #e9ecef;
}

.transparent-mode .card .box h2 {
  color: #262626;
}

.transparent-mode .card .box h2 span {
  color: #fff;
}

.transparent-mode .card .box p {
  color: #262626;
}

.transparent-mode .cardheader-tabs .card-header {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .carousel-control-prev,
.transparent-mode .carousel-control-next {
  color: #fff;
}

.transparent-mode .carousel-control-prev:hover,
.transparent-mode .carousel-control-prev:focus {
  color: #fff;
}

.transparent-mode .carousel-control-next:hover,
.transparent-mode .carousel-control-next:focus {
  color: #fff;
}

.transparent-mode .carousel-indicators li,
.transparent-mode .carousel-indicators1 li,
.transparent-mode .carousel-indicators2 li,
.transparent-mode .carousel-indicators3 li,
.transparent-mode .carousel-indicators4 li,
.transparent-mode .carousel-indicators5 li {
  background-color: rgba(255, 255, 255, 0.5);
}

.transparent-mode .carousel-indicators .active,
.transparent-mode .carousel-indicators1 .active,
.transparent-mode .carousel-indicators2 .active,
.transparent-mode .carousel-indicators3 .active,
.transparent-mode .carousel-indicators4 .active,
.transparent-mode .carousel-indicators5 .active {
  background-color: #fff;
}

.transparent-mode .carousel-caption {
  color: #fff;
}

.transparent-mode .carousel-item-background {
  background: rgba(0, 0, 0, 0.5);
}

.transparent-mode .carousel-control-prev,
.transparent-mode .carousel-control-next {
  color: #fff;
}

.transparent-mode .carousel-control-prev:hover,
.transparent-mode .carousel-control-prev:focus {
  color: #fff;
}

.transparent-mode .carousel-control-next:hover,
.transparent-mode .carousel-control-next:focus {
  color: #fff;
}

.transparent-mode .carousel-indicators li,
.transparent-mode .carousel-indicators1 li,
.transparent-mode .carousel-indicators2 li,
.transparent-mode .carousel-indicators3 li,
.transparent-mode .carousel-indicators4 li,
.transparent-mode .carousel-indicators5 li {
  background-color: rgba(255, 255, 255, 0.5);
}

.transparent-mode .carousel-indicators .active,
.transparent-mode .carousel-indicators1 .active,
.transparent-mode .carousel-indicators2 .active,
.transparent-mode .carousel-indicators3 .active,
.transparent-mode .carousel-indicators4 .active,
.transparent-mode .carousel-indicators5 .active {
  background-color: #fff;
}

.transparent-mode .carousel-caption {
  color: #fff;
}

.transparent-mode .carousel-item-background {
  background: rgba(0, 0, 0, 0.5);
}

.transparent-mode .dropdown-menu {
  color: rgba(255, 255, 255, 0.7);
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .dropdown-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .drop-heading {
  color: #fff;
}

.transparent-mode .dropdown-item {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .dropdown-item:hover,
.transparent-mode .dropdown-item:focus,
.transparent-mode .dropdown-item.active,
.transparent-mode .dropdown-item:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .dropdown-item.disabled,
.transparent-mode .dropdown-item:disabled {
  color: #fff;
}

.transparent-mode .dropdown-menu.show {
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
}

.transparent-mode .dropdown-header {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .dropdown-item-text {
  color: #fff;
}

.transparent-mode btn.dropdown-toggle ~ .dropdown-menu,
.transparent-mode ul.dropdown-menu li.dropdown ul.dropdown-menu {
  background-color: #f4f4f4 !important;
  background-color: white !important;
  border: 0 solid #4285f4 !important;
  box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
}

.transparent-mode .dropdown-menu {
  background-color: var(--primary-bg-color);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.transparent-mode .dropdown-menu > li > a {
  color: #fff;
}

.transparent-mode .dropdown-menu > li > a:hover,
.transparent-mode .dropdown-menu > li > a:focus {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .dropdown-menu .divider {
  background-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .dropdown-menu .dropdown-plus-title {
  color: #fff !important;
  border: 0 solid rgba(255, 255, 255, 0.2) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .dropdown-menu-header {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .dropdown-menu-header label {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .dropdown-menu-arrow:before,
.transparent-mode .dropdown-menu.header-search:before {
  background: var(--primary-bg-color);
  border-top-color: rgba(255, 255, 255, 0.2);
  border-left-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .dropdown-menu {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.transparent-mode .dropdown-toggle .dropdown-label {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .form-control {
  color: #fff;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.transparent-mode .form-control::-ms-expand {
  background-color: transparent;
}

.transparent-mode .form-control:focus {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .form-control::placeholder {
  color: rgba(255, 255, 255, 0.7) !important;
}

.transparent-mode .form-control::-webkit-input-placeholder,
.transparent-mode .form-control::-moz-placeholder,
.transparent-mode .form-control:-ms-input-placeholder,
.transparent-mode .form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
}

.transparent-mode .form-control:disabled,
.transparent-mode .form-control[readonly] {
  background-color: var(--transparent-body);
}

.transparent-mode .form-control::-webkit-file-upload-button {
  color: #fff;
  background-color: var(--transparent-body);
}

.transparent-mode
  .form-control:hover:not(:disabled):not(
    [readonly]
  )::-webkit-file-upload-button {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .form-control-plaintext {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .form-check-input:disabled ~ .form-check-label {
  color: rgba(255, 255, 255, 0.5);
}

.transparent-mode
  .was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.transparent-mode
  .custom-control-input.is-valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(9, 173, 149, 0.25);
}

.transparent-mode .was-validated .form-control:invalid,
.transparent-mode .form-control.is-invalid,
.transparent-mode .was-validated .form-select:invalid,
.transparent-mode .form-select.is-invalid {
  border-color: #dc3545;
  background-repeat: no-repeat;
}

.transparent-mode .was-validated .form-control:invalid:focus,
.transparent-mode .form-control.is-invalid:focus,
.transparent-mode .was-validated .form-select:invalid:focus,
.transparent-mode .form-select.is-invalid:focus {
  border-color: #dc3545;
}

.transparent-mode .was-validated .form-check-input:invalid ~ .form-check-label,
.transparent-mode .form-check-input.is-invalid ~ .form-check-label,
.transparent-mode
  .was-validated
  .custom-control-input:invalid
  ~ .custom-control-label,
.transparent-mode .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.transparent-mode
  .was-validated
  .custom-control-input:invalid
  ~ .custom-control-label::before,
.transparent-mode
  .custom-control-input.is-invalid
  ~ .custom-control-label::before {
  background-color: #ec8080;
}

.transparent-mode
  .was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.transparent-mode
  .custom-control-input.is-invalid:checked
  ~ .custom-control-label::before {
  background-color: #e23e3d;
}

.transparent-mode
  .was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.transparent-mode
  .custom-control-input.is-invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(232, 38, 70, 0.25);
}

.transparent-mode .form-control.header-search {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(225, 225, 225, 0.1);
  color: #fff;
}

.transparent-mode .form-control.header-search::placeholder {
  color: #fff;
}

.transparent-mode .form-control.header-search:hover,
.transparent-mode .form-control.header-search:focus {
  border-color: rgba(225, 225, 225, 0.1);
}

.transparent-mode .form-required {
  color: #dc3545;
}

.transparent-mode .form-check-input:focus {
  border-color: var(--primary-bg-color);
}

.transparent-mode .input-group-text {
  color: #fff;
  background-color: var(--primary-bg-color);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .input-indec .input-group-btn > .btn {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .input-group-text {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .input-group-text.bg-white {
  background-color: transparent !important;
}

.transparent-mode .input-indec .input-group-btn > .btn {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .modal-content {
  background-color: var(--primary-bg-color);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}

.transparent-mode .modal-backdrop {
  background-color: #000;
}

.transparent-mode .modal-header {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .modal-footer {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .modal.effect-just-me .modal-content {
  background-color: #16192f;
  border-color: #4d4e50;
}

.transparent-mode .modal.effect-just-me .btn-close {
  color: #fff;
}

.transparent-mode .modal.effect-just-me .modal-header {
  background-color: transparent;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.transparent-mode .modal.effect-just-me .modal-header h6 {
  color: #fff;
}

.transparent-mode .modal.effect-just-me .modal-body {
  color: rgba(255, 255, 255, 0.8);
}

.transparent-mode .modal.effect-just-me .modal-body h6 {
  color: #fff;
}

.transparent-mode .modal.effect-just-me .modal-footer {
  background-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .nav-tabs {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .nav-pills .nav-link.active,
.transparent-mode .nav-pills .show > .nav-link {
  color: #fff;
}

.transparent-mode .nav.nav-pills.nav-stacked.labels-info p {
  color: #9d9f9e;
}

.transparent-mode .nav.nav-pills .nav-item .nav-link-icon {
  color: #fff;
}

.transparent-mode .nav1 {
  background: var(--transparent-body);
}

.transparent-mode .nav-item1:hover:not(.disabled),
.transparent-mode .nav-item1.active {
  color: #fff;
}

.transparent-mode .nav-item1 .nav-link {
  color: #fff;
}

.transparent-mode .nav-item1 .nav-link.active {
  color: var(--primary-bg-color);
}

.transparent-mode .nav-item1 .nav-link.disabled {
  color: rgba(255, 255, 255, 0.3);
}

.transparent-mode .nav-tabs {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .nav-tabs .nav-item1.nav-link {
  border-color: transparent;
}

.transparent-mode .nav-tabs .nav-item1 .nav-link {
  color: inherit;
  color: rgba(255, 255, 255, 0.7);
  transition: 0.3s border-color;
}

.transparent-mode .nav-tabs .nav-item1 .nav-link:hover:not(.disabled),
.transparent-mode .nav-tabs .nav-item1 .nav-link.active {
  color: #fff;
}

.transparent-mode .nav-tabs .nav-submenu .nav-item1 {
  color: #9aa0ac;
}

.transparent-mode .nav-tabs .nav-submenu .nav-item1.active {
  color: #467fcf;
}

.transparent-mode .nav-tabs .nav-submenu .nav-item1:hover {
  color: #6e7687;
  background: rgba(0, 0, 0, 0.024);
}

.transparent-mode .nav-link {
  color: var(--primary-bg-color);
}

.transparent-mode .nav-link.icon i::after {
  background: rgba(var(--primary-bg-color), 0);
}

.transparent-mode .app-header .header-right-icons .nav-link.icon {
  color: #fff;
}

.transparent-mode .app-header .header-right-icons .nav-link.icon:hover {
  color: #fff !important;
}

.transparent-mode .nav-tabs {
  color: #000;
}

.transparent-mode .nav-tabs .nav-link {
  color: inherit;
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .nav-tabs .nav-link:hover:not(.disabled),
.transparent-mode .nav-tabs .nav-link.active {
  color: #fff;
}

.transparent-mode .nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}

.transparent-mode .nav-tabs .nav-submenu {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  border-top: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.transparent-mode .nav-tabs .nav-submenu .nav-item {
  color: #9aa0ac;
}

.transparent-mode .nav-tabs .nav-submenu .nav-item:hover {
  color: #6e7687;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.024);
}

.transparent-mode .page-link {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .page-link:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .page-item.active .page-link {
  color: #fff;
}

.transparent-mode .page-item.disabled .page-link {
  color: rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .page-header {
  border-color: transparent;
}

.transparent-mode .panel-title-landing {
  color: #1643a3 !important;
}

.transparent-mode .panel-footer-landing {
  border: solid 2px #1643a3 !important;
  border-top: none !important;
  background: #f7f7f7;
}

.transparent-mode .panel-footer {
  background-color: rgba(0, 0, 0, 0.2);
  border-top-color: rgba(255, 255, 255, 0.2);
  border-left-color: rgba(255, 255, 255, 0.2);
  border-right-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .panel-group .panel,
.transparent-mode .panel-group1 .panel {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .panel-default > .panel-heading {
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode
  .panel-default
  > .panel-heading
  + .panel-collapse
  > .panel-body {
  border: 0px solid rgba(255, 255, 255, 0.2);
}

.transparent-mode .panel1 {
  border-color: #fff;
}

.transparent-mode .panel1:last-child {
  border-bottom: none;
}

.transparent-mode .panel-body1 {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .panel-group1 .panel-body {
  border: 0px solid rgba(255, 255, 255, 0.2);
}

.transparent-mode .panel-title1 a {
  color: #fff !important;
}

.transparent-mode .panel-title a:hover {
  color: #dedefd !important;
}

.transparent-mode .panel-title a.accordion-toggle:before,
.transparent-mode .panel-title a.accordion-toggle.collapsed:before {
  color: #fff;
}

.transparent-mode .expanel {
  background-color: transparent !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) !important;
}

.transparent-mode .expanel-default > .expanel-heading {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode .expanel-heading {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .expanel-footer {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-top-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .popover {
  background-color: var(--transparent-body);
  border-color: #dee3eb;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
}

.transparent-mode .bs-popover-top .popover-arrow::before,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='top']
  .popover-arrow::before {
  border-top-color: rgba(0, 0, 0, 0.25);
}

.transparent-mode .bs-popover-top .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='top']
  .popover-arrow::after {
  border-top-color: var(--transparent-body);
}

.transparent-mode .bs-popover-end .popover-arrow::before,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='right']
  .popover-arrow::before {
  border-right-color: #dee3eb;
}

.transparent-mode .bs-popover-end .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='right']
  .popover-arrow::after {
  border-right-color: transparent;
}

.transparent-mode .bs-popover-bottom .popover-arrow::before,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='bottom']
  .popover-arrow::before {
  border-bottom-color: #dee3eb;
}

.transparent-mode .bs-popover-bottom .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='bottom']
  .popover-arrow::after {
  border-bottom-color: transparent;
}

.transparent-mode .bs-popover-bottom .popover-header::before,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='bottom']
  .popover-header::before {
  border-bottom-color: transparent;
}

.transparent-mode .bs-popover-start .popover-arrow::before,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='left']
  .popover-arrow::before {
  border-left-color: #dee3eb;
}

.transparent-mode .bs-popover-start .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='left']
  .popover-arrow::after {
  border-left-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .popover-header {
  color: inherit;
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: #ebebeb;
}

.transparent-mode .popover-body {
  color: #6e7687;
}

.transparent-mode .progress {
  background-color: rgba(0, 0, 0, 0.1);
}

.transparent-mode .progress-bar {
  color: #fff;
}

.transparent-mode .table {
  color: #fff;
}

.transparent-mode .table thead th {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .text-wrap table thead th {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table tbody + tbody,
.transparent-mode .text-wrap table tbody + tbody {
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}

.transparent-mode .table .table,
.transparent-mode .text-wrap table .table,
.transparent-mode .table .text-wrap table {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .text-wrap .table table,
.transparent-mode .text-wrap table table {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .table-bordered,
.transparent-mode .text-wrap table,
.transparent-mode .table-bordered th,
.transparent-mode .text-wrap table th,
.transparent-mode .table-bordered td,
.transparent-mode .text-wrap table td {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.transparent-mode .table-hover tbody tr:hover,
.transparent-mode .table-hover tbody th {
  background-color: var(--transparent-body);
  color: #fff;
}

.transparent-mode .table-active {
  background-color: rgba(0, 0, 0, 0.04);
}

.transparent-mode .table-active > th,
.transparent-mode .table-active > td {
  background-color: rgba(0, 0, 0, 0.04);
}

.transparent-mode .table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.transparent-mode .table-hover .table-active:hover > td,
.transparent-mode .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.04);
}

.transparent-mode .table .thead-dark th,
.transparent-mode .text-wrap table .thead-dark th {
  color: #fff;
  background-color: #fff;
  border-color: #32383e;
}

.transparent-mode .table .thead-light th,
.transparent-mode .text-wrap table .thead-light th {
  color: #495057;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table-inbox {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table-inbox tr {
  border-bottom-color: rgba(238, 238, 238, 0.7);
}

.transparent-mode .table-inbox tr:last-child {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table-inbox tr td .fa-star.inbox-started,
.transparent-mode .table-inbox tr td .fa-star:hover {
  color: #f78a09;
}

.transparent-mode .table-inbox tr.unread td {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .table th,
.transparent-mode .text-wrap table th {
  color: #fff;
}

.transparent-mode .table .table-light th,
.transparent-mode .transparent-mode .text-wrap table th {
  color: #282f53;
}

.transparent-mode .table-vcenter td,
.transparent-mode .table-vcenter th {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table-secondary tbody + tbody,
.transparent-mode .table-secondary td,
.transparent-mode .table-secondary th,
.transparent-mode .table-secondary thead th {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table-primary tbody + tbody,
.transparent-mode .table-primary td,
.transparent-mode .table-primary th,
.transparent-mode .table-primary thead th {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table-striped tbody tr:nth-of-type(odd) {
  color: #fff;
}

.transparent-mode .table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .table-calendar-link {
  background: #f8f9fa;
  color: #495057;
}

.transparent-mode .table-calendar-link:before {
  background: #467fcf;
}

.transparent-mode .table-calendar-link:hover {
  color: #fff;
  background: #467fcf;
}

.transparent-mode .table-calendar-link:hover:before {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .table-header:hover,
.transparent-mode .table-header-asc,
.transparent-mode .table-header-desc {
  color: #495057 !important;
}

.transparent-mode .table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table tbody td {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table-hover > tbody > tr:hover > * {
  color: #dedefd;
}

.transparent-mode .table > :not(:first-child) {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table-striped > tbody > tr:nth-of-type(odd) > * {
  color: #dedefd;
}

.transparent-mode #data-table .text-primary span {
  color: #b7b7d3;
}

.transparent-mode .tag {
  color: #fff;
  background-color: var(--transparent-body);
}

.transparent-mode a.tag:hover {
  background-color: rgba(110, 118, 135, 0.2);
  color: inherit;
}

.transparent-mode .tag-addon {
  color: inherit;
}

.transparent-mode a.tag-addon:hover {
  background: inherit;
  color: inherit;
}

.transparent-mode .tag-blue {
  background-color: #467fcf !important;
  color: #fff;
}

.transparent-mode .tag-indigo {
  background-color: #6574cd !important;
  color: #fff;
}

.transparent-mode .tag-purple {
  background-color: #867efc !important;
  color: #fff;
}

.transparent-mode .tag-pink {
  background-color: #ec82ef !important;
  color: #fff;
}

.transparent-mode .tag-red {
  background-color: #c21a1a !important;
  color: #fff;
}

.transparent-mode .tag-orange {
  background-color: #fc7303 !important;
  color: #fff;
}

.transparent-mode .tag-yellow {
  background-color: #ecb403 !important;
  color: #fff;
}

.transparent-mode .tag-green {
  background-color: #4ecc48 !important;
  color: #fff;
}

.transparent-mode .tag-teal {
  background-color: #2bcbba !important;
  color: #fff;
}

.transparent-mode .tag-cyan {
  background-color: #17a2b8 !important;
  color: #fff;
}

.transparent-mode .tag-white {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.transparent-mode .tag-gray {
  background-color: #868e96 !important;
  color: #fff;
}

.transparent-mode .tag-gray-dark {
  background-color: #414160;
  color: #fff;
}

.transparent-mode .tag-azure {
  background-color: #45aaf2 !important;
  color: #fff;
}

.transparent-mode .tag-lime {
  background-color: #7bd235 !important;
  color: #fff;
}

.transparent-mode .tag-primary {
  background-color: #467fcf;
  color: #fff;
  background-color: var(--primary-bg-color) !important;
  color: #fff;
}

.transparent-mode .tag-secondary {
  background-color: #868e96;
  color: #fff;
}

.transparent-mode .tag-success {
  background-color: #4ecc48 !important;
  color: #fff;
}

.transparent-mode .tag-info {
  background-color: #45aaf2;
  color: #fff;
}

.transparent-mode .tag-warning {
  background-color: #ecb403 !important;
  color: #fff;
}

.transparent-mode .tag-danger {
  background-color: #c21a1a !important;
  color: #fff;
}

.transparent-mode .tag-light {
  background-color: #f8f9fa;
  color: #fff;
}

.transparent-mode .tag-dark {
  background-color: #25253e;
  color: #fff;
}

.transparent-mode .tag-round::before {
  background-color: #fff;
}

.transparent-mode .tag-outline-info {
  background-color: #c7e0fd;
  color: #1170e4;
  border-color: #1170e4;
}

.transparent-mode .tag-outline-info::before {
  border-color: #1170e4;
}

.transparent-mode .tag-outline {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tag-border {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

.transparent-mode .thumbnail {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .bs-popover-top .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='top']
  .popover-arrow::after {
  border-top-color: var(--transparent-body);
}

.transparent-mode .bs-popover-end .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='right']
  .popover-arrow::after {
  border-right-color: var(--transparent-body);
}

.transparent-mode .bs-popover-bottom .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='bottom']
  .popover-arrow::after {
  border-bottom-color: transparent;
}

.transparent-mode .bs-popover-start .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='left']
  .popover-arrow::after {
  border-left-color: var(--transparent-body);
}

.transparent-mode .tooltip-static-demo {
  background-color: var(--transparent-body);
}

.transparent-mode .popover-static-demo {
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tooltip-primary .tooltip-inner {
  background-color: var(--primary-bg-color) !important;
  color: #fff;
}

.transparent-mode .tooltip-primary.bs-tooltip-top .tooltip-arrow::before,
.transparent-mode
  .tooltip-primary.bs-tooltip-auto[data-popper-placement^='top']
  .tooltip-arrow::before {
  border-top-color: var(--primary-bg-color);
}

.transparent-mode .tooltip-primary.bs-tooltip-bottom .tooltip-arrow::before,
.transparent-mode
  .tooltip-primary.bs-tooltip-auto[data-popper-placement^='bottom']
  .tooltip-arrow::before {
  border-bottom-color: var(--primary-bg-color);
}

.transparent-mode .tooltip-primary.bs-tooltip-start .tooltip-arrow::before,
.transparent-mode
  .tooltip-primary.bs-tooltip-auto[data-popper-placement^='left']
  .tooltip-arrow::before {
  border-left-color: var(--primary-bg-color);
}

.transparent-mode .tooltip-primary.bs-tooltip-end .tooltip-arrow::before,
.transparent-mode
  .tooltip-primary.bs-tooltip-auto[data-popper-placement^='right']
  .tooltip-arrow::before {
  border-right-color: var(--primary-bg-color);
}

.transparent-mode .popover {
  background-color: var(--transparent-body);
  border: 0px solid rgba(0, 0, 0, 0.2);
}

.transparent-mode .bs-popover-top > .popover-arrow::before,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='top']
  > .popover-arrow::before {
  border-top-color: var(--transparent-body);
}

.transparent-mode .bs-popover-top > .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='top']
  > .popover-arrow::after {
  border-top-color: var(--transparent-body);
}

.transparent-mode .bs-popover-end > .popover-arrow::before,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='right']
  > .popover-arrow::before {
  border-right-color: var(--transparent-body);
}

.transparent-mode .bs-popover-end > .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='right']
  > .popover-arrow::after {
  border-right-color: var(--transparent-body);
}

.transparent-mode .bs-popover-bottom > .popover-arrow::before,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='bottom']
  > .popover-arrow::before {
  border-bottom-color: transparent;
}

.transparent-mode .bs-popover-bottom > .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='bottom']
  > .popover-arrow::after {
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .bs-popover-bottom .popover-header::before,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='bottom']
  .popover-header::before {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .bs-popover-start > .popover-arrow::before,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='left']
  > .popover-arrow::before {
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: var(--transparent-body);
}

.transparent-mode .bs-popover-start > .popover-arrow::after,
.transparent-mode
  .bs-popover-auto[data-popper-placement^='left']
  > .popover-arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: var(--transparent-body);
}

.transparent-mode .popover-header {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .popover-body {
  color: rgba(255, 255, 255, 0.5);
}

.transparent-mode .popover-head-primary .popover-header {
  color: #fff;
  background-color: var(--primary-bg-color) !important;
}

.transparent-mode .popover-head-primary.bs-popover-bottom .popover-arrow::after,
.transparent-mode
  .popover-head-primary.bs-popover-auto[data-popper-placement^='bottom']
  .popover-arrow::after {
  border-bottom-color: var(--primary-bg-color) !important;
}

.transparent-mode .popover-head-secondary .popover-header {
  color: #fff;
  background-color: #05c3fb !important;
}

.transparent-mode
  .popover-head-secondary.bs-popover-bottom
  .popover-arrow::after,
.transparent-mode
  .popover-head-secondary.bs-popover-auto[data-popper-placement^='bottom']
  .popover-arrow::after {
  border-bottom-color: #05c3fb !important;
}

.transparent-mode .popover-head-primary .popover-body,
.transparent-mode .popover-head-secondary .popover-body {
  border-color: rgba(20, 17, 45, 0.2);
}

.transparent-mode .popover-primary {
  background-color: var(--primary-bg-color) !important;
}

.transparent-mode .popover-primary .popover-header {
  background-color: var(--primary-bg-color) !important;
  border-bottom-width: 0 !important;
  color: #fff;
}

.transparent-mode .popover-primary.bs-popover-top .popover-arrow::after,
.transparent-mode
  .popover-primary.bs-popover-auto[data-popper-placement^='top']
  .popover-arrow::after {
  border-top-color: var(--primary-bg-color) !important;
}

.transparent-mode .popover-primary.bs-popover-bottom .popover-arrow::after,
.transparent-mode
  .popover-primary.bs-popover-auto[data-popper-placement^='bottom']
  .popover-arrow::after {
  border-bottom-color: var(--primary-bg-color) !important;
}

.transparent-mode .popover-primary.bs-popover-start .popover-arrow::after,
.transparent-mode
  .popover-primary.bs-popover-auto[data-popper-placement^='left']
  .popover-arrow::after {
  border-left-color: var(--primary-bg-color) !important;
}

.transparent-mode .popover-primary.bs-popover-end .popover-arrow::after,
.transparent-mode
  .popover-primary.bs-popover-auto[data-popper-placement^='right']
  .popover-arrow::after {
  border-right-color: var(--primary-bg-color) !important;
}

.transparent-mode
  .custom-control-input:checked
  ~ .custom-control-label::before {
  color: #fff;
}

.transparent-mode .custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: rgba(218, 201, 232, 0.5);
}

.transparent-mode .custom-control-input:disabled ~ .custom-control-label {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode
  .custom-control-input:disabled
  ~ .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode
  .custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(212, 182, 228, 0.5);
}

.transparent-mode .form-select {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.2);
  background: transparent;
}

.transparent-mode .form-select:focus {
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: none;
}

.transparent-mode .form-select:focus::-ms-value {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .form-select:disabled {
  color: #9fa7af;
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .form-file-label {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .form-file-label::after {
  color: #fff;
  border-left-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .form-range::-webkit-slider-thumb {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: none;
}

.transparent-mode .form-range::-webkit-slider-thumb:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.transparent-mode .form-range::-webkit-slider-thumb:active {
  background-color: #d4e1f4;
}

.transparent-mode .form-range::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.2);
  background: #467fcf;
}

.transparent-mode .form-range::-moz-range-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .form-range::-moz-range-thumb:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.transparent-mode .form-range::-moz-range-thumb:active {
  background-color: #d4e1f4;
}

.transparent-mode .form-range::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.2);
  background: rgba(0, 50, 126, 0.12);
}

.transparent-mode .form-range::-ms-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 30, 75, 0.12);
}

.transparent-mode .form-range::-ms-thumb:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.transparent-mode .form-range::-ms-thumb:active {
  background-color: #d4e1f4;
}

.transparent-mode .form-range::-ms-fill-lower {
  background-color: rgba(255, 255, 255, 0.2);
  background: #467fcf;
}

.transparent-mode .custom-control-label:before {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.02);
}

.transparent-mode .form-range:focus::-webkit-slider-thumb,
.transparent-mode .form-range:focus::-moz-range-thumb,
.transparent-mode .form-range:focus::-ms-thumb {
  border-color: #467fcf;
  background-color: #467fcf;
}

.transparent-mode .form-range::-moz-range-progress {
  background: #467fcf;
}

.transparent-mode .form-range::-ms-fill-upper {
  background: rgba(0, 50, 126, 0.12);
  background-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .custom-switch-description {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .custom-switch-input:checked ~ .custom-switch-description {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .custom-switch-indicator {
  background: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .custom-switch-indicator:before {
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.transparent-mode .custom-switch-input:focus ~ .custom-switch-indicator {
  border-color: #60529f;
}

.transparent-mode .custom-radio-md .custom-control-label::before,
.transparent-mode .custom-radio-lg .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .custom-checkbox-md .custom-control-label::before,
.transparent-mode .custom-checkbox-lg .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode
  .custom-checkbox-md
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before,
.transparent-mode
  .custom-checkbox-lg
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(212, 182, 228, 0.5);
}

.transparent-mode .custom-switch-input:disabled ~ .custom-switch-indicator {
  background: #e9ecef;
}

.transparent-mode .custom-switch-input:checked ~ .custom-switch-indicator {
  background: var(--primary-bg-color);
}

.transparent-mode .custom-switch-indicator-md,
.transparent-mode .custom-switch-indicator-lg {
  background: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .custom-switch-indicator-md::before,
.transparent-mode .custom-switch-indicator-lg::before {
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.transparent-mode .collapse:not(.show) {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .notifyimg {
  color: #fff;
}

.transparent-mode .aside {
  background: rgba(0, 0, 0, 0.2);
  border-left-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
}

@media (max-width: 991px) {
  .transparent-mode .about-con {
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }
}

@media (max-width: 480px) {
  .transparent-mode .tabs-menu ul li a,
  .transparent-mode .tabs-menu1 ul li {
    border-color: rgba(255, 255, 255, 0.2);
  }

  .transparent-mode .product-sale.nav.nav-pills .nav-item .nav-link {
    border-color: rgba(255, 255, 255, 0.2);
  }
}

@media (max-width: 320px) {
  .transparent-mode .construction .btn.btn-icon {
    color: #fff;
  }
}

@media (max-width: 360px) {
  .transparent-mode .breadcrumb {
    color: #fff;
  }
}

@media (max-width: 768px) {
  .transparent-mode .richText .richText-toolbar ul li a {
    border: rgba(0, 40, 100, 0.12) solid 1px;
  }
}

.transparent-mode .stamp {
  color: #fff;
  background: #868e96;
}

.transparent-mode .example {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .example-bg {
  background: transparent;
}

.transparent-mode .colorinput-color {
  border: 3px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.transparent-mode .colorinput-input:focus ~ .colorinput-color {
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.transparent-mode #back-to-top {
  color: #fff;
}

.transparent-mode #back-to-top:hover {
  background: #fff !important;
}

.transparent-mode .features span {
  color: #43414e;
}

.transparent-mode .feature .border {
  color: #fff;
}

.transparent-mode .actions:not(.a-alt) > li > a > i {
  color: #939393;
}

.transparent-mode #sidebar li a.active {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode #sidebar li a.active a[data-toggle='collapse'] {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .line-divide {
  border-color: rgba(218, 216, 219, 0.2);
}

.transparent-mode .activity {
  border-left-color: rgba(0, 0, 0, 0.125);
}

.transparent-mode .username {
  color: #fff;
}

.transparent-mode .username + p {
  color: #f2f2f2;
}

.transparent-mode #user-profile .profile-details ul > li > span {
  color: #643ba2;
}

@media (max-width: 1024px) {
  .transparent-mode body.search-show:before,
  .transparent-mode body.sidebar-show:before {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@media (max-width: 575.98px) {
  .transparent-mode .header .form-inline .search-element .form-control {
    background: rgba(0, 0, 0, 0.2);
  }

  .transparent-mode .form-control.header-search {
    color: #000;
  }

  .transparent-mode .header .form-inline .btn {
    color: #46494a !important;
  }

  .transparent-mode
    .header
    .form-inline
    .form-control::-webkit-input-placeholder {
    color: #fff !important;
  }

  .transparent-mode .header .navsearch i {
    color: #fff;
  }
}

.transparent-mode .settings {
  color: #fff;
}

.transparent-mode .member {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 998px) and (min-width: 768px) {
  .transparent-mode .note-popover .popover-content,
  .transparent-mode .card-header.note-toolbar {
    background: rgba(0, 0, 0, 0.2);
  }
}

.transparent-mode .material-switch > label::before {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 0px 10px rgba(42, 38, 53, 0.5);
}

.transparent-mode .material-switch > label::after {
  background: var(--transparent-body);
  box-shadow: 0px 0px 5px rgba(42, 38, 53, 0.9);
}

.transparent-mode
  .material-switch
  > input[type='checkbox']:checked
  + label::before,
.transparent-mode
  .material-switch
  > input[type='checkbox']:checked
  + label::after {
  background: inherit;
}

.transparent-mode .sw-theme-default > ul.step-anchor > li > a::after {
  background: none !important;
}

.transparent-mode .border-transparet {
  border-color: rgba(255, 255, 255, 0.1);
}

.transparent-mode .breadcrumb .breadcrumb-item.active,
.transparent-mode .breadcrumb-item1 a {
  color: rgba(255, 255, 255, 0.4);
}

.transparent-mode .input-group.input-indec .form-control {
  border-color: rgba(255, 255, 255, 0.2);
}

@media (min-width: 992px) {
  .transparent-mode .main-header-center .form-control {
    background: transparent;
    border-color: rgba(255, 255, 255, 0.2);
  }
}

@media (min-width: 992px) {
  .transparent-mode .main-header-center .btn {
    background-color: transparent;
    color: #b4bdce;
  }
}

.transparent-mode .pulse-danger {
  background: #ee335e;
}

.transparent-mode .pulse-danger:before {
  background: rgba(238, 51, 94, 0.8);
  box-shadow: 0 0 0 rgba(238, 51, 94, 0.9);
}

.transparent-mode .pulse {
  background: #22c03c;
}

.transparent-mode .pulse:before {
  background: rgba(34, 192, 60, 0.6);
  box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
}

.transparent-mode .progress.info1 {
  background: #fed5db;
}

.transparent-mode .progress.secondary {
  background: #f9d1bd;
}

.transparent-mode .avatar {
  color: #fff;
}

.transparent-mode .badge-success-light {
  background-color: rgba(19, 191, 27, 0.15) !important;
  color: #13bf1b;
}

.transparent-mode .badge-orange-light {
  color: #e17626;
  background-color: rgba(225, 118, 38, 0.15);
}

.transparent-mode .badge-danger-light {
  color: #f33819;
  background-color: rgba(243, 56, 25, 0.15);
}

.transparent-mode .badge.bg-white {
  background-color: #fff !important;
}

.transparent-mode .browser-stats img {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .box-shadow-primary {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.transparent-mode .box-shadow-secondary {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.transparent-mode .box-shadow-success {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.transparent-mode .box-shadow-danger {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.transparent-mode .box-shadow-pink {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.transparent-mode .sidebar .tab-menu-heading {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.transparent-mode .sidebar .tabs-menu ul li .active {
  color: #fff;
}

.transparent-mode .sidebar .feeds.avatar-circle.bg-primary-transparent::before {
  background: rgba(255, 255, 255, 0.3);
}

.transparent-mode .sidebar .feeds.avatar-circle-info::before {
  background: #0151b3;
}

.transparent-mode .sidebar .feeds.avatar-circle-info {
  background: rgba(142, 193, 255, 0.1) !important;
}

.transparent-mode .sidebar .feeds.avatar-circle-info .text-info {
  color: #0b54af;
}

.transparent-mode .sidebar-right .dropdown-item h6 {
  color: #282f53;
}

.transparent-mode .sidebar-right .panel-primary.card {
  background: var(--primary-bg-color);
}

.transparent-mode .sidebar-right .feeds.avatar-circle .text-primary {
  color: rgba(255, 255, 255, 0.5) !important;
}

.transparent-mode .sidebar-right .feeds.avatar-circle.bg-primary-transparent {
  background-color: rgba(255, 255, 255, 0.05);
}

.transparent-mode .nav.panel-tabs a.active {
  color: #fff;
}

.transparent-mode .nav.panel-tabs.panel-secondary a {
  color: #05c3fb;
}

.transparent-mode .nav.panel-tabs.panel-secondary a.active {
  background-color: #05c3fb !important;
  color: #fff;
}

.transparent-mode .nav.panel-tabs.panel-danger a {
  color: #e82646;
}

.transparent-mode .nav.panel-tabs.panel-danger a.active {
  background-color: #e82646 !important;
  color: #fff;
}

.transparent-mode .nav.panel-tabs.panel-success a {
  color: #09ad95;
}

.transparent-mode .nav.panel-tabs.panel-success a.active {
  background-color: #09ad95 !important;
  color: #fff;
}

.transparent-mode .nav.panel-tabs.panel-info a {
  color: #538ed7;
}

.transparent-mode .nav.panel-tabs.panel-info a.active {
  background-color: #1170e4 !important;
  color: #fff;
}

.transparent-mode .task-list {
  color: #fff;
}

.transparent-mode .task-list:before {
  border-left-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .mail-inbox .icons {
  color: #fff !important;
}

.transparent-mode .table-inbox tr td i {
  color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table-inbox tr td i:hover {
  color: #f7284a;
}

.transparent-mode .table-inbox tr td .fa-star:hover {
  color: #fbc518;
}

.transparent-mode .mail-option .btn-group a.btn,
.transparent-mode .mail-option .chk-all {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.transparent-mode .mail-option .btn-group a.all {
  box-shadow: none;
}

.transparent-mode .inbox-pagination a.np-btn {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .acc-header a {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .acc-header a.collapsed {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .acc-body {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .card-pay .tabs-menu li a {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.transparent-mode .card-pay .tabs-menu li a.active {
  background: var(--primary-bg-color);
  color: #fff;
}

.transparent-mode .main-content-label,
.transparent-mode .card-table-two .card-title,
.transparent-mode .card-dashboard-eight .card-title {
  color: #fff;
}

.transparent-mode .social-login {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .user-social-detail .social-profile {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .txt1 {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .hor-header.sticky.stickyClass .horizontal-main.hor-menu {
  box-shadow: 5px 7px 26px -5px rgba(104, 113, 123, 0.1);
}

.transparent-mode .mini-stat-icon {
  color: #fff;
}

.transparent-mode .product-grid6 {
  overflow: hidden;
}

.transparent-mode .product-grid6 .price span {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .product-grid6 .icons-wishlist li a i {
  color: inherit;
}

.transparent-mode .product-grid6 .icons-wishlist li a:hover,
.transparent-mode .product-grid6 .icons-wishlist li a:after,
.transparent-mode .product-grid6 .icons-wishlist li a:before {
  color: #fff;
}

.transparent-mode .product-grid6 .icons li a i {
  color: inherit;
}

.transparent-mode .product-grid6 .icons li a:hover,
.transparent-mode .product-grid6 .icons li a:after,
.transparent-mode .product-grid6 .icons li a:before {
  color: #fff;
}

.transparent-mode .apexcharts-radialbar-track.apexcharts-track path {
  stroke: rgba(0, 0, 0, 0.2);
}

.transparent-mode .apex-charts text {
  fill: #000200;
}

.transparent-mode .offcanvas {
  background: var(--transparent-body);
}

.transparent-mode .offcanvas-start {
  border-right-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .offcanvas-end {
  border-left-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .offcanvas-bottom {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .offcanvas-top {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .toast {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.transparent-mode .toast .toast-header {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .toast.show .btn-close {
  color: #fff;
}

.transparent-mode .task-icon1:first-child {
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.transparent-mode .nav.product-sale a.active {
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom: none;
}

.transparent-mode .profile-cover__img {
  color: #fff;
}

.transparent-mode .profile-cover__img .profile-img-1 > img {
  border: 5px solid #fff;
}

.transparent-mode .profile-cover__img > .h3 {
  color: #393939;
}

.transparent-mode .profile-cover__info .nav li {
  color: #464461;
}

.transparent-mode .social.social-profile-buttons .social-icon {
  background: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
  color: #dedefd !important;
}

.transparent-mode .profile-share {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .option-dots {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .option-dots:focus,
.transparent-mode .option-dots:hover,
.transparent-mode .option-dots[aria-expanded='true'] {
  background: rgba(0, 0, 0, 0.2);
}

@media (min-width: 601px) {
  .transparent-mode .social-profile-buttons .nav {
    color: #999;
  }
}

.transparent-mode .social-profile-buttons .nav li {
  color: #464461;
}

.transparent-mode .item2-gl-menu {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .item2-gl-menu li a {
  color: rgba(255, 255, 255, 0.5);
}

.transparent-mode .item2-gl-menu li .active {
  color: #fff;
}

.transparent-mode .product-label {
  background: rgba(178, 177, 183, 0.1);
}

.transparent-mode .ui-widget-header,
.transparent-mode .ui-state-default,
.transparent-mode .ui-widget-content .ui-state-default,
.transparent-mode .ui-widget-header .ui-state-default {
  background: var(--transparent-body);
}

.transparent-mode .ui-state-hover,
.transparent-mode .ui-widget-content .ui-state-hover,
.transparent-mode .ui-widget-header .ui-state-hover,
.transparent-mode .ui-state-focus,
.transparent-mode .ui-widget-content .ui-state-focus,
.transparent-mode .ui-widget-header .ui-state-focus {
  border-color: var(--primary-bg-color) !important;
  background: var(--primary-bg-color) !important;
}

.transparent-mode .ui-widget-content {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .product-list .icons li a:after,
.transparent-mode .product-list .icons li a:before {
  color: #fff;
}

.transparent-mode .product-grid6 .card-footer .btn-outline-primary:hover {
  color: #fff;
}

.transparent-mode .carousel-inner .carousel-item .thumb {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .carousel-inner .carousel-item .thumb.active {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .customer-services {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .customer-services span {
  background-color: transparent;
  color: var(--primary-bg-color);
}

.transparent-mode .login-social-icon::before,
.transparent-mode .login-social-icon::after {
  background-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .login-social-icon span {
  background: transparent;
}

.transparent-mode .custom-layout {
  color: #fff;
}

.transparent-mode .custom-layout .nav-link.icon i {
  color: #fff !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background: rgba(255, 255, 255, 0.08);
}

.transparent-mode .custom-layout .nav-link.icon i::after {
  background-color: transparent;
}

.transparent-mode .transparent-mode .custom-layout .nav-link.icon i {
  color: #fff !important;
}

.transparent-mode .country-selector .nav-link {
  color: #495046;
}

.transparent-mode .theme-container .active {
  border-color: var(--primary-bg-color);
  background: transparent;
  color: var(--primary-bg-color);
}

.transparent-mode .theme-container1 .active {
  border-color: #fc5296;
  background: transparent;
  color: #fc5296;
}

.transparent-mode .theme-container2 .active {
  border-color: #05c3fb;
  background: transparent;
  color: #05c3fb;
}

.transparent-mode .settings-icon {
  border-color: var(--primary-bg-color);
}

.transparent-mode .input-group-text.input-text-color {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .payment-icon.active svg {
  fill: #fff;
}

.transparent-mode .payment-icon svg {
  fill: #fff;
}

.transparent-mode .notification:before {
  background: rgba(255, 255, 255, 0.1);
}

.transparent-mode .notification .notification-time .date,
.transparent-mode .notification .notification-time .time {
  color: rgba(255, 255, 255, 0.3);
}

.transparent-mode .notification .notification-icon a {
  background: var(--transparent-body);
  color: #fff;
  border: 3px solid rgba(255, 255, 255, 0.5);
}

.transparent-mode .notification .notification-body {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .notification .notification-body:before {
  border: 10px solid transparent;
  border-right-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .notification-time-date {
  color: rgba(255, 255, 255, 0.3);
}

.transparent-mode .btn-country {
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: none !important;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1) !important;
  color: #fff;
}

.transparent-mode .btn-country:hover {
  border-color: var(--primary-bg-color) !important;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1) !important;
}

.transparent-mode .btn-check:checked + .btn-country {
  border-color: var(--primary-bg-color) !important;
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .btn-country.active,
.transparent-mode .btn-country:active {
  border-color: var(--primary-bg-color) !important;
}

.transparent-mode .email-icon {
  border-color: rgba(255, 255, 255, 0.2);
  color: #5c678f;
}

.transparent-mode
  .product-grid6
  .card-footer
  .btn-outline-primary:hover
  .wishlist-icon {
  color: #fff;
}

.transparent-mode .btn-outline-primary .wishlist-icon {
  color: rgba(255, 255, 255, 0.6) !important;
}

.transparent-mode .btn-outline-primary:hover .wishlist-icon {
  color: #fff !important;
}

.transparent-mode #chartZoom .btn-outline-primary:hover {
  color: #fff;
}

.transparent-mode .c3-legend-item text {
  fill: rgba(255, 255, 255, 0.5);
}

.transparent-mode .file-image .icons li a {
  color: #fff;
}

.transparent-mode .file-image .icons li a:after,
.transparent-mode .file-image .icons li a:before,
.transparent-mode .file-image .icons li a:hover {
  color: #fff;
}

.transparent-mode .file-name {
  color: #fff;
}

.transparent-mode .img-1 img {
  border: 6px solid rgba(225, 225, 225, 0.5);
}

.transparent-mode .profile-img {
  border-color: rgba(167, 180, 201, 0.2);
  background: rgba(225, 225, 225, 0.2);
}

.transparent-mode .demo-gallery > ul > li a {
  border: 3px solid #fff;
}

.transparent-mode .demo-gallery.dark > ul > li a {
  border: 3px solid #04070a;
}

.transparent-mode .gallery a img {
  border-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .example + .highlight {
  border-top: none;
}

.transparent-mode .highlight {
  border-color: rgba(255, 255, 255, 0.2);
  border-top: none;
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .highlight .hll {
  background-color: #ffc;
}

.transparent-mode .highlight .c {
  color: #999;
}

.transparent-mode .highlight .k {
  color: #069;
}

.transparent-mode .highlight .o {
  color: #555;
}

.transparent-mode .highlight .cm {
  color: #999;
}

.transparent-mode .highlight .cp {
  color: #099;
}

.transparent-mode .highlight .c1,
.transparent-mode .highlight .cs {
  color: #999;
}

.transparent-mode .highlight .gd {
  background-color: #fcc;
  border-color: #c00;
}

.transparent-mode .highlight .gr {
  color: #f00;
}

.transparent-mode .highlight .gh {
  color: #030;
}

.transparent-mode .highlight .gi {
  background-color: #cfc;
  border-color: #0c0;
}

.transparent-mode .highlight .go {
  color: #aaa;
}

.transparent-mode .highlight .gp {
  color: #009;
}

.transparent-mode .highlight .gu {
  color: #030;
}

.transparent-mode .highlight .gt {
  color: #9c6;
}

.transparent-mode .highlight .kc,
.transparent-mode .highlight .kd,
.transparent-mode .highlight .kn,
.transparent-mode .highlight .kp,
.transparent-mode .highlight .kr {
  color: #069;
}

.transparent-mode .highlight .kt {
  color: #078;
}

.transparent-mode .highlight .m {
  color: #f60;
}

.transparent-mode .highlight .s {
  color: #cc0099;
}

.transparent-mode .highlight .na {
  color: #00cc7a;
}

.transparent-mode .highlight .nb {
  color: #366;
}

.transparent-mode .highlight .nc {
  color: #0a8;
}

.transparent-mode .highlight .no {
  color: #360;
}

.transparent-mode .highlight .nd {
  color: #99f;
}

.transparent-mode .highlight .ni {
  color: #999;
}

.transparent-mode .highlight .ne {
  color: #c00;
}

.transparent-mode .highlight .nf {
  color: #c0f;
}

.transparent-mode .highlight .nl {
  color: #99f;
}

.transparent-mode .highlight .nn {
  color: #0cf;
}

.transparent-mode .highlight .nt {
  color: #800000;
}

.transparent-mode .highlight .nv {
  color: #033;
}

.transparent-mode .highlight .ow {
  color: #000;
}

.transparent-mode .highlight .w {
  color: #bbb;
}

.transparent-mode .highlight .mf,
.transparent-mode .highlight .mh,
.transparent-mode .highlight .mi,
.transparent-mode .highlight .mo {
  color: #f60;
}

.transparent-mode .highlight .sb,
.transparent-mode .highlight .sc,
.transparent-mode .highlight .sd,
.transparent-mode .highlight .s2,
.transparent-mode .highlight .se,
.transparent-mode .highlight .sh {
  color: #c30;
}

.transparent-mode .highlight .si {
  color: #a00;
}

.transparent-mode .highlight .sx {
  color: #c30;
}

.transparent-mode .highlight .sr {
  color: #3aa;
}

.transparent-mode .highlight .s1 {
  color: #c30;
}

.transparent-mode .highlight .ss {
  color: #fc3;
}

.transparent-mode .highlight .bp {
  color: #366;
}

.transparent-mode .highlight .vc,
.transparent-mode .highlight .vg,
.transparent-mode .highlight .vi {
  color: #033;
}

.transparent-mode .highlight .il {
  color: #f60;
}

.transparent-mode .highlight .css .o {
  color: #999;
}

.transparent-mode .highlight .css .o + .nt {
  color: #999;
}

.transparent-mode .highlight .css .nt + .nt {
  color: #999;
}

.transparent-mode .highlight .language-bash::before,
.transparent-mode .highlight .language-sh::before,
.transparent-mode .highlight .language-powershell::before {
  color: #009;
}

.transparent-mode .label-default {
  background: #d5e0ec;
  color: #fff;
}

.transparent-mode .label-success {
  background: #09ad95;
  color: #fff;
}

.transparent-mode .label-danger {
  background: #f5334f;
  color: #fff;
}

.transparent-mode .label-warning {
  background: #f7b731;
  color: #fff;
}

.transparent-mode .label-info {
  background: #1170e4;
  color: #fff;
}

.transparent-mode .list-group-item.active {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .list-group-item-action {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .list-group-item-action:hover,
.transparent-mode .list-group-item-action:focus,
.transparent-mode .list-group-item-action:active {
  color: rgba(255, 255, 255, 0.7);
  background-color: var(--transparent-body);
}

.transparent-mode .list-group-item,
.transparent-mode .listorder,
.transparent-mode .listorder1,
.transparent-mode .listunorder,
.transparent-mode .listunorder1 {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .list-group-item.disabled,
.transparent-mode .list-group-item:disabled {
  color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

.transparent-mode .list-group-item-primary {
  color: #24426c !important;
  background-color: #cbdbf2;
}

.transparent-mode .list-group-item-primary.list-group-item-action:hover,
.transparent-mode .list-group-item-primary.list-group-item-action:focus {
  color: #24426c;
  background-color: #b7cded;
}

.transparent-mode .list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #24426c;
  border-color: #24426c;
}

.transparent-mode .list-group-item-secondary {
  color: #464a4e !important;
  background-color: #dddfe2;
}

.transparent-mode .list-group-item-secondary.list-group-item-action:hover,
.transparent-mode .list-group-item-secondary.list-group-item-action:focus {
  color: #464a4e;
  background-color: #cfd2d6;
}

.transparent-mode .list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #464a4e;
  border-color: #464a4e;
}

.transparent-mode .list-group-item-success {
  color: #09ad95 !important;
  background-color: rgba(9, 173, 149, 0.4);
}

.transparent-mode .list-group-item-success.list-group-item-action:hover,
.transparent-mode .list-group-item-success.list-group-item-action:focus {
  color: #09ad95;
  background-color: rgba(9, 173, 149, 0.2);
}

.transparent-mode .list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #09ad95;
  border-color: #09ad95;
}

.transparent-mode .list-group-item-info {
  color: #1170e4 !important;
  background-color: rgba(17, 112, 228, 0.4);
}

.transparent-mode .list-group-item-info.list-group-item-action:hover,
.transparent-mode .list-group-item-info.list-group-item-action:focus {
  color: #1170e4;
  background-color: rgba(17, 112, 228, 0.2);
}

.transparent-mode .list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: rgba(17, 112, 228, 0.2);
  border-color: rgba(17, 112, 228, 0.2);
}

.transparent-mode .list-group-item-warning {
  color: #f7b731 !important;
  background-color: rgba(247, 183, 49, 0.4);
}

.transparent-mode .list-group-item-warning.list-group-item-action:hover,
.transparent-mode .list-group-item-warning.list-group-item-action:focus {
  color: #f7b731;
  background-color: rgba(247, 183, 49, 0.2);
}

.transparent-mode .list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #f7b731;
  border-color: #f7b731;
}

.transparent-mode .list-group-item-danger {
  color: #e82646 !important;
  background-color: rgba(232, 38, 70, 0.4);
}

.transparent-mode .list-group-item-danger.list-group-item-action:hover,
.transparent-mode .list-group-item-danger.list-group-item-action:focus {
  color: #e82646;
  background-color: rgba(232, 38, 70, 0.2);
}

.transparent-mode .list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #e82646;
  border-color: #e82646;
}

.transparent-mode .list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.transparent-mode .list-group-item-light.list-group-item-action:hover,
.transparent-mode .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.transparent-mode .list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.transparent-mode .list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.transparent-mode .list-group-item-dark.list-group-item-action:hover,
.transparent-mode .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.transparent-mode .list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.transparent-mode .list-group-item {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .list-group-item.active .icon {
  color: inherit !important;
}

.transparent-mode .list-group-item .icon {
  color: #fff !important;
}

.transparent-mode .list-group-transparent .list-group-item.active {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.transparent-mode .list-group-transparent.file-manager .list-group-item {
  color: #fff;
}

.transparent-mode .file-radius-attachments i {
  color: #dedefd;
}

.transparent-mode .file-square-attachments a {
  color: #dedefd;
}

.transparent-mode .file-image-1 {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .file-image-1 .file-name-1 {
  color: #dedefd;
}

.transparent-mode
  .list-group-transparent.file-manager.file-manager-border
  .list-group-item {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .btn-close {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .btn-close:hover,
.transparent-mode .btn-close:focus {
  color: inherit;
}

.transparent-mode .navbar-toggler {
  background-color: transparent;
  border-color: transparent;
}

@media (min-width: 992px) {
  .transparent-mode .responsive-navbar .navbar-collapse {
    background: transparent;
  }
}

.transparent-mode a.icon:hover {
  color: #dcdfed !important;
}

.transparent-mode .navbar-light .navbar-brand {
  color: #fff;
}

.transparent-mode .navbar-light .navbar-brand:hover,
.transparent-mode .navbar-light .navbar-brand:focus {
  color: #fff;
}

.transparent-mode .navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.transparent-mode .navbar-light .navbar-nav .nav-link:hover,
.transparent-mode .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.transparent-mode .navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.transparent-mode .navbar-light .navbar-nav .show > .nav-link,
.transparent-mode .navbar-light .navbar-nav .active > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.transparent-mode .navbar-light .navbar-nav .nav-link.show,
.transparent-mode .navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.transparent-mode .navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.transparent-mode .navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.transparent-mode .navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.transparent-mode .navbar-light .navbar-text a:hover,
.transparent-mode .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.transparent-mode .navbar-dark .navbar-brand {
  color: #fff;
}

.transparent-mode .navbar-dark .navbar-brand:hover,
.transparent-mode .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.transparent-mode .navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.transparent-mode .navbar-dark .navbar-nav .nav-link:hover,
.transparent-mode .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.transparent-mode .navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.transparent-mode .navbar-dark .navbar-nav .show > .nav-link,
.transparent-mode .navbar-dark .navbar-nav .active > .nav-link {
  color: #fff;
}

.transparent-mode .navbar-dark .navbar-nav .nav-link.show,
.transparent-mode .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.transparent-mode .navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.transparent-mode .navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.transparent-mode .navbar-dark .navbar-text a {
  color: #fff;
}

.transparent-mode .navbar-dark .navbar-text a:hover,
.transparent-mode .navbar-dark .navbar-text a:focus {
  color: #fff;
}

@media (max-width: 991px) {
  .transparent-mode .navresponsive-toggler span:after {
    background: rgba(var(--primary-bg-color), 0);
  }

  .transparent-mode.header-light #navbarSupportedContent-4 .nav-link.icon {
    color: #fff !important;
  }

  .transparent-mode.dark-header #navbarSupportedContent-4 .nav-link.icon {
    color: #fff !important;
  }

  .transparent-mode.dark-header .navresponsive-toggler span {
    color: #fff;
  }
}

@media (max-width: 991px) {
  .transparent-mode .responsive-navbar .navbar-collapse {
    background: var(--transparent-body);
    box-shadow: 0 12px 11px -3px rgba(0, 0, 0, 0.5);
    border-top-color: rgba(255, 255, 255, 0.2);
  }

  .transparent-mode .responsive-navbar .navbar-collapse .icon.navsearch {
    border-color: #e4e6f9;
  }
}

.transparent-mode .scrollspy-example {
  border-right-color: rgba(255, 255, 255, 0.2);
  border-left-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .scrollspy-example-2 {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode #navbar-example3 .nav-link {
  color: #fff;
}

.transparent-mode #navbar-example3 .nav-link.active {
  color: #fff;
}

.transparent-mode .nav-link:focus,
.transparent-mode .nav-link:hover {
  color: var(--primary-bg-color);
}

.transparent-mode .pricing {
  color: #fff;
}

.transparent-mode .pricing1 {
  color: #707070;
}

.transparent-mode .pricing .list-unstyled li {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.transparent-mode .pricing1 .list-unstyled li {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .panel-heading-landing {
  background: #f7f7f7 !important;
  border: solid 2px #1643a3 !important;
  border-bottom: none !important;
}

.transparent-mode .panel-heading {
  border-bottom-color: rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .panel.price {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .panel.price > .panel-heading {
  color: #fff;
}

.transparent-mode .price .panel-footer {
  background-color: transparent;
}

.transparent-mode .price.panel-color > .panel-body {
  background-color: transparent;
}

.transparent-mode .ribbone1-price .ribbon span {
  color: #fff;
  background: #79a70a;
  background: var(--primary-bg-color);
  box-shadow: 0 3px 10px -5px black;
}

.transparent-mode .ribbone1-price .ribbon span::before {
  border-left: 3px solid var(--primary-bg-color);
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--primary-bg-color);
}

.transparent-mode .ribbone1-price .ribbon span::after {
  border-left: 3px solid transparent;
  border-right: 3px solid var(--primary-bg-color);
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--primary-bg-color);
}

.transparent-mode .secondary .pricing-divider {
  background: transparent;
}

.transparent-mode .danger .pricing-divider {
  background: transparent !important;
}

.transparent-mode .primary .pricing-divider {
  background: transparent !important;
}

.transparent-mode .success .pricing-divider {
  background: transparent;
}

.transparent-mode .primary .princing-item .bg-white {
  background-color: transparent !important;
}

.transparent-mode .secondary .princing-item .bg-white {
  background-color: transparent !important;
}

.transparent-mode .danger .princing-item .bg-white {
  background-color: transparent !important;
}

.transparent-mode .success .princing-item .bg-white {
  background-color: transparent !important;
}

.transparent-mode .rating-stars input {
  color: #495057;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tabs-menu ul li a {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .tabs-menu ul li .active {
  color: var(--primary-bg-color);
}

.transparent-mode .tabs-menu1 ul li a {
  color: #fff;
}

.transparent-mode .tab-menu-heading {
  border-bottom-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .tabs-menu2 ul li a {
  color: #636262;
}

.transparent-mode .tabs-menu2 ul li .fade {
  color: rgba(238, 238, 238, 0.9333333333);
}

.transparent-mode .sidebar-right .tab-content i,
.transparent-mode .tabs-menu2 ul li .active {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .search-tabs ul li a.active {
  border-bottom: 3px solid var(--primary-bg-color);
  background-color: transparent !important;
}

.transparent-mode .search-tabs ul li a:hover {
  background-color: transparent !important;
}

.transparent-mode .tabs-menu-border ul li .active {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tabs-menu-boxed ul li a {
  color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tabs-menu-boxed ul li .active {
  border-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: transparent;
}

.transparent-mode .timeline__item:after {
  background: #fff !important;
}

.transparent-mode .timeline__content {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .timeline:before {
  background-color: #e9ecef;
}

.transparent-mode .timeline-item:first-child:before,
.transparent-mode .timeline-item:last-child:before {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .timeline-badge {
  border-color: #fff;
  background: #adb5bd;
}

.transparent-mode .timeline-time {
  color: #9aa0ac;
}

.transparent-mode .timeline__item--right .timeline__content:before {
  border-right: 12px solid rgba(238, 232, 239, 0.9);
}

.transparent-mode ul.timeline:before {
  background: #d4d9df;
}

.transparent-mode ul.timeline > li:before {
  border-color: #6c6c6f;
}

.transparent-mode .vtimeline::before {
  background-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .vtimeline .timeline-wrapper .timeline-panel {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 12px 0 #101329;
}

.transparent-mode .vtimeline .timeline-wrapper .timeline-panel:after {
  border-top: 10px solid transparent;
  border-left: 10px solid rgba(255, 255, 255, 0.2);
  border-right: 0 solid rgba(255, 255, 255, 0.2);
  border-bottom: 10px solid transparent;
}

.transparent-mode .vtimeline .timeline-wrapper .timeline-badge {
  border: 2px solid var(--transparent-body);
}

.transparent-mode .vtimeline .timeline-wrapper .timeline-badge i {
  color: #fff;
}

.transparent-mode
  .vtimeline
  .timeline-wrapper.timeline-inverted
  .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 10px;
}

.transparent-mode .timeline-wrapper-primary .timeline-panel:before,
.transparent-mode .timeline-wrapper-primary .timeline-badge {
  background: var(--primary-bg-color);
}

.transparent-mode .timeline-wrapper-secondary .timeline-panel:before,
.transparent-mode .timeline-wrapper-secondary .timeline-badge {
  background: #05c3fb;
}

.transparent-mode .timeline-wrapper-success .timeline-panel:before,
.transparent-mode .timeline-wrapper-success .timeline-badge {
  background: #09ad95;
}

.transparent-mode .timeline-wrapper-green .timeline-panel:before,
.transparent-mode .timeline-wrapper-green .timeline-badge {
  background: #4ecc48;
}

.transparent-mode .timeline-wrapper-warning .timeline-panel:before,
.transparent-mode .timeline-wrapper-warning .timeline-badge {
  background: #fcd539;
}

.transparent-mode .timeline-wrapper-danger .timeline-panel:before,
.transparent-mode .timeline-wrapper-danger .timeline-badge {
  background: #f16d75;
}

.transparent-mode .timeline-wrapper-light .timeline-panel:before,
.transparent-mode .timeline-wrapper-light .timeline-badge {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .timeline-wrapper-dark .timeline-panel:before,
.transparent-mode .timeline-wrapper-dark .timeline-badge {
  background: #828db1;
}

@media (max-width: 767px) {
  .transparent-mode .vtimeline .timeline-wrapper .timeline-panel:after {
    border-right: 14px solid rgba(255, 255, 255, 0.2) !important;
    border-left: 0 solid rgba(255, 255, 255, 0.2) !important;
  }
}

.transparent-mode .footer {
  background: transparent;
  border-top-color: rgba(255, 255, 255, 0.02);
}

.transparent-mode .footer .social ul li a {
  border-color: rgba(255, 255, 255, 0.2);
  background: var(--transparent-body);
  color: #fff;
}

.transparent-mode .top-footer p {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .top-footer a {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .top-footer a address {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .top-footer img {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .top-footer img:hover {
  color: #8e9090;
}

.transparent-mode .footer-payments a {
  color: #a7a8c7;
}

.transparent-mode .main-footer {
  background-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .header {
  background: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .header-brand,
.transparent-mode .app-header .header-brand,
.transparent-mode .header-brand:hover {
  color: inherit;
}

.transparent-mode .app-header.sticky.stickyClass {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0) !important;
}

@media (max-width: 991px) {
  .transparent-mode .header.hor-header {
    border-bottom-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }

  .transparent-mode .hor-header .header-brand-img.light-logo {
    margin: 0 auto;
    margin-top: 6px;
  }
}

.transparent-mode .header .form-inline .form-control {
  border-color: rgba(225, 225, 225, 0.1);
  background: rgba(225, 225, 225, 0.3);
  color: #000 !important;
}

.transparent-mode .header .form-inline .btn {
  border: 2px solid transparent;
  box-shadow: none;
  background: transparent;
  color: #fff;
}

@media (max-width: 767.98px) and (min-width: 576px) {
  .transparent-mode .header .navsearch i {
    color: #fff;
  }

  .transparent-mode .search-element .form-control {
    background: #fff !important;
    color: #000;
  }

  .transparent-mode
    .header
    .form-inline
    .form-control::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.7);
  }

  .transparent-mode .header .navsearch i {
    color: #fff;
  }

  .transparent-mode .header .form-inline .btn {
    color: #46494a !important;
  }
}

@media only screen and (max-width: 991px) {
  .transparent-mode .animated-arrow span:before,
  .transparent-mode .animated-arrow span:after {
    background: #282f53;
  }

  .transparent-mode .animated-arrow span:before,
  .transparent-mode .animated-arrow span:after {
    background: #282f53;
  }
}

@media only screen and (max-width: 991px) {
  .transparent-mode body {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.transparent-mode .hor-header .header-brand1 {
  color: inherit;
}

.transparent-mode .header-right-icons .nav-link.icon:hover {
  background: none;
}

.transparent-mode .header-right-icons .profile-user:hover {
  box-shadow: none;
}

.transparent-mode .hor-header .header-right-icons .nav-link.icon {
  color: #fff;
}

.transparent-mode .logo-horizontal .header-brand-img.desktop-logo {
  display: block !important;
}

.transparent-mode .logo-horizontal .header-brand-img.light-logo1 {
  display: none;
}

.transparent-mode .hor-header .header-brand-img.light-logo {
  display: block;
}

@media (max-width: 991px) {
  .transparent-mode .header {
    border-bottom-color: rgba(255, 255, 255, 0.2);
  }

  .transparent-mode .header.hor-header {
    background-color: var(--transparent-body);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.01) !important;
  }
}

.transparent-mode .header .dropdown-menu {
  box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .header .dropdown-menu .dropdown-item {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .header .dropdown-item,
.transparent-mode .header .notifications-menu h5,
.transparent-mode .header .message-menu h5 {
  color: #fff;
}

.transparent-mode .header .notifications-menu span,
.transparent-mode .header .message-menu span {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .header .dropdown-menu {
  box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .header .dropdown-menu .dropdown-item {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .header .profile-1 .dropdown-item .dropdown-icon {
  color: var(--primary-bg-color);
}

.transparent-mode .header .profile-1 .dropdown-item .dropdown-icon::after {
  background: rgba(var(--primary-bg-color), 0);
}

.transparent-mode .responsive-navbar .notifications-menu h5,
.transparent-mode .responsive-navbar .message-menu h5 {
  color: #fff;
}

.transparent-mode .responsive-navbar .notifications-menu span,
.transparent-mode .responsive-navbar .message-menu span {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .responsive-navbar .dropdown-menu {
  box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .responsive-navbar .dropdown-menu .dropdown-item {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .responsive-navbar .profile-1 .dropdown-item .dropdown-icon {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode
  .responsive-navbar
  .profile-1
  .dropdown-item
  .dropdown-icon::after {
  background: rgba(var(--primary-bg-color), 0);
}

.transparent-mode.horizontal .side-menu > li > a {
  color: rgba(255, 255, 255, 0.8);
}

.transparent-mode.horizontal .horizontal-main .slide .slide-menu,
.transparent-mode.horizontal .horizontal-main .slide .sub-slide-menu,
.transparent-mode.horizontal .horizontal-main .slide .sub-slide-menu2 {
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 16px 18px rgba(0, 0, 0, 0.3) !important;
}

.transparent-mode.horizontal .logo-horizontal .header-brand-img.light-logo1 {
  display: none;
}

.transparent-mode .ps__rail-y:hover > .ps__thumb-y,
.transparent-mode .ps__rail-y:focus > .ps__thumb-y,
.transparent-mode .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 991px) {
  .transparent-mode {
    /* ================== Mobile Slide Down Links CSS ================== */
    /* ================== Mobile Mega Menus CSS  ================== */
    /* ================== Mobile Header CSS ================== */
    /* Mobile Toggle Menu icon (X ICON) */
    /* ================== Mobile Overlay/Drawer CSS ================== */
    /*End Media Query*/
  }

  .transparent-mode .horizontal-header {
    border-bottom-color: rgba(0, 0, 0, 0.1);
    border-top-color: rgba(0, 0, 0, 0.1);
  }

  .transparent-mode .callusbtn {
    color: #a9a9a9;
  }

  .transparent-mode .callusbtn:hover .fa {
    color: #a9a9a9;
  }

  .transparent-mode .animated-arrow span {
    background: rgba(255, 255, 255, 0.7);
  }

  .transparent-mode .animated-arrow span:before,
  .transparent-mode .animated-arrow span:after {
    background: rgba(255, 255, 255, 0.7);
  }

  .transparent-mode.active .animated-arrow span {
    background-color: transparent;
  }

  .transparent-mode .horizontal-overlapbg {
    background-color: rgba(0, 0, 0, 0.45);
  }
}

.transparent-mode .horizontal-main.hor-menu {
  background: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .icons-list-item {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .icons-list-item i {
  color: rgba(255, 255, 255, 0.5);
}

.transparent-mode .browser {
  background: no-repeat center/100% 100%;
}

.transparent-mode .flag,
.transparent-mode .payment {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}

.transparent-mode .col-sm-3 a {
  border-color: transparent;
}

.transparent-mode .col-sm-3 a:hover {
  border-color: #ff4647;
  background: linear-gradient(rgba(56, 123, 131, 0.7), rgba(56, 123, 131, 0.7));
}

.transparent-mode .ui-datepicker {
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .ui-datepicker .ui-datepicker-header {
  color: #fff;
}

.transparent-mode .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.transparent-mode .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  text-indent: -99999px;
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next:hover::before,
.transparent-mode
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next:focus::before {
  color: #343a40;
}

.transparent-mode
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev:hover::before,
.transparent-mode
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev:focus::before {
  color: #343a40;
}

.transparent-mode
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-next-hover,
.transparent-mode
  .ui-datepicker
  .ui-datepicker-header
  .ui-datepicker-prev-hover {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .ui-datepicker .ui-datepicker-calendar th {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .ui-datepicker .ui-datepicker-calendar td {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

.transparent-mode .ui-datepicker .ui-datepicker-calendar td span {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.transparent-mode .ui-datepicker .ui-datepicker-calendar td a {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .ui-datepicker .ui-datepicker-calendar td a:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.transparent-mode
  .ui-datepicker
  .ui-datepicker-calendar
  .ui-datepicker-today
  a {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.transparent-mode .ui-datepicker .ui-datepicker-title {
  color: #fff;
}

.transparent-mode .jvectormap-tip {
  background: #fff;
  color: white;
}

.transparent-mode .jvectormap-zoomin,
.transparent-mode .jvectormap-zoomout,
.transparent-mode .jvectormap-goback {
  background: var(--transparent-body);
  color: white;
}

.transparent-mode .jvectormap-legend {
  background: black;
  color: white;
}

.transparent-mode .select2-container--default .select2-selection--single {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.transparent-mode
  .select2-container--default.select2-container--focus
  .select2-selection--multiple {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #9ca3b1;
  box-shadow: none;
}

.transparent-mode .select2-container--default .select2-selection--multiple {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  border-color: rgba(255, 255, 255, 0.2) !important;
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice,
.transparent-mode
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff !important;
}

.transparent-mode
  .select2-container--default
  .select2-results
  > .select2-results__options {
  box-shadow: 0px 16px 18px rgba(104, 113, 123, 0.2);
}

.transparent-mode
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #fff;
}

.transparent-mode
  .select2-container--default.select2-container--disabled
  .select2-selection--single {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .selectgroup-button {
  border-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .selectgroup-input:checked + .selectgroup-button {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .selectgroup-input:focus + .selectgroup-button {
  box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.transparent-mode .selectize-dropdown {
  color: #495057;
}

.transparent-mode .selectize-input {
  color: #495057;
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .selectize-input input {
  color: #495057;
}

.transparent-mode .selectize-input {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .selectize-input.full {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .selectize-input.focus {
  border-color: #467fcf;
  box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.transparent-mode .selectize-input.dropdown-active::before {
  background: #f0f0f0;
}

.transparent-mode .selectize-dropdown {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .selectize-dropdown [data-selectable] .highlight {
  background: rgba(125, 168, 208, 0.2);
}

.transparent-mode .selectize-dropdown .optgroup-header {
  color: #495057;
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .selectize-dropdown .active {
  background-color: #f1f4f8;
  color: #467fcf;
}

.transparent-mode .selectize-dropdown .active.create {
  color: #495057;
}

.transparent-mode .selectize-dropdown .create {
  color: rgba(48, 48, 48, 0.5);
}

.transparent-mode .selectize-dropdown .image img,
.transparent-mode .selectize-input .image img {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

@media (min-width: 992px) {
  .transparent-mode .side-header {
    background: transparent;
  }
}

@media (max-width: 991px) {
  .transparent-mode.app.sidebar-mini .app-sidebar {
    background: var(--transparent-body);
  }
}

@media print {
  .transparent-mode .app-content {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.transparent-mode .app-header {
  border-bottom-color: rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .app-header__logo {
  color: #fff;
}

.transparent-mode .ps__thumb-y {
  background-color: transparent;
  width: 1px;
}

.transparent-mode .app-sidebar__toggle {
  color: #fff;
}

.transparent-mode .app-sidebar__toggle:after {
  background: rgba(var(--primary-bg-color), 0);
}

.transparent-mode .app-sidebar {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 0px 0px rgba(42, 38, 53, 0);
  box-shadow: 0px 0px 0px 0px rgba(42, 38, 53, 0);
  border-right-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .app-sidebar__user {
  color: #a8a8a8;
}

.transparent-mode .app-sidebar__user img {
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.1);
  border: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
}

.transparent-mode .app-sidebar__user-name,
.transparent-mode .app-sidebar__user-designation {
  color: #e5e9ec;
}

.transparent-mode .side-menu__item {
  color: rgba(255, 255, 255, 0.8);
}

.transparent-mode .side-menu__item.active {
  color: #fff !important;
}

.transparent-mode .side-menu__item.active:hover,
.transparent-mode .side-menu__item.active:focus {
  color: var(--primary-bg-color);
}

.transparent-mode .side-menu__item:hover,
.transparent-mode .side-menu__item:focus {
  color: var(--primary-bg-color);
}

.transparent-mode .side-menu__item:hover .side-menu__icon,
.transparent-mode .side-menu__item:hover .side-menu__label {
  color: #fff !important;
}

.transparent-mode .side-menu__item:focus .side-menu__icon,
.transparent-mode .side-menu__item:focus .side-menu__label {
  color: #fff;
}

.transparent-mode .slide-item.active,
.transparent-mode .slide-item:hover,
.transparent-mode .slide-item:focus {
  color: #b5c1d2;
}

.transparent-mode .slide-menu a.active {
  color: var(--primary-bg-color);
}

.transparent-mode .slide-item.active,
.transparent-mode .slide-item:hover,
.transparent-mode .slide-item:focus,
.transparent-mode .sub-slide-item.active,
.transparent-mode .sub-slide-item:hover,
.transparent-mode .sub-slide-item:focus,
.transparent-mode .sub-slide-item2.active,
.transparent-mode .sub-slide-item2:hover,
.transparent-mode .sub-slide-item2:focus {
  color: #fff !important;
}

.transparent-mode .sub-side-menu__item,
.transparent-mode .sub-side-menu__item2 {
  color: rgba(255, 255, 255, 0.8);
}

.transparent-mode .sub-slide-item.active,
.transparent-mode .sub-slide-item:hover,
.transparent-mode .sub-slide-item:focus,
.transparent-mode .sub-slide-item2.active,
.transparent-mode .sub-slide-item2:hover,
.transparent-mode .sub-slide-item2:focus,
.transparent-mode .sub-side-menu__label.active,
.transparent-mode .sub-side-menu__label:hover,
.transparent-mode .sub-side-menu__label:focus,
.transparent-mode .sub-side-menu__label1.active,
.transparent-mode .sub-side-menu__label1:hover,
.transparent-mode .sub-side-menu__label1:focus,
.transparent-mode .sub-side-menu__label2.active,
.transparent-mode .sub-side-menu__label2:hover,
.transparent-mode .sub-side-menu__label2:focus {
  color: #fff;
}

.transparent-mode .slide-menu li .slide-item:before {
  color: rgba(255, 255, 255, 0.6);
}

.transparent-mode .side-menu .side-menu__icon {
  color: rgba(255, 255, 255, 0.7) !important;
}

.transparent-mode .side-menu .side-menu__icon::after {
  background: rgba(var(--primary-bg-color), 0);
}

.transparent-mode .slide-item {
  color: #fff;
}

.transparent-mode .side-menu__item.active .side-menu__icon {
  color: #fff !important;
}

@media (min-width: 992px) {
  .transparent-mode .sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    background: none !important;
    box-shadow: none;
  }

  .transparent-mode
    .sidebar-mini.sidenav-toggled
    .sidebar-mini.sidenav-toggled.user-notification::before {
    background: transparent;
  }

  .transparent-mode .sidebar-mini.sidenav-toggled .app-sidebar__user {
    border-bottom-color: rgba(225, 225, 225, 0.05);
  }

  .transparent-mode.horizontal .horizontal-main {
    background: rgba(0, 0, 0, 0.2) !important;
  }

  .transparent-mode .header .main-header-center .btn {
    background-color: var(--transparent-body);
  }
}

.transparent-mode .app-title {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.transparent-mode .app-breadcrumb {
  background-color: transparent;
}

.transparent-mode .user-info .text-dark {
  color: #25252a !important;
}

.transparent-mode .user-info .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}

.transparent-mode .side-header {
  border-bottom-color: rgba(255, 255, 255, 0.2);
  border-right-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode.sidebar-mini .side-menu {
  height: 100%;
}

.transparent-mode.horizontal .side-menu {
  background: transparent;
}

.transparent-mode.horizontal .main-sidemenu .slide-left,
.transparent-mode.horizontal .main-sidemenu .slide-right {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode.horizontal .main-sidemenu .slide-left svg,
.transparent-mode.horizontal .main-sidemenu .slide-right svg {
  fill: #dedefd;
}

.transparent-mode .side-menu .sub-category {
  color: rgba(255, 255, 255, 0.3);
}

.transparent-mode .side-menu .sub-side-menu__label,
.transparent-mode .sub-slide-label,
.transparent-mode .sub-side-menu__label2 {
  color: rgba(255, 255, 255, 0.8);
}

.transparent-mode .sub-slide-item,
.transparent-mode .sub-slide-item2 {
  color: #dedefd;
}

.transparent-mode .richText {
  border: rgba(255, 255, 255, 0.2) solid 1px;
  background-color: transparent !important;
}

.transparent-mode .richText .richText-toolbar {
  border-bottom: rgba(156, 162, 161, 0) solid 1px;
}

.transparent-mode .richText .richText-toolbar ul li a {
  border-right: rgba(156, 162, 161, 0.2) solid 1px;
}

.transparent-mode
  .transparent-mode
  .cal1
  .clndr
  .clndr-table
  tr
  .day.event:hover,
.transparent-mode .cal1 .clndr .clndr-table tr .day.my-event:hover,
.transparent-mode .transparent-mode .cal1 .clndr .clndr-table tr .day.today,
.transparent-mode .cal1 .clndr .clndr-table tr .day.my-today {
  color: #fff;
}

.transparent-mode .cal1 .clndr .clndr-table .header-days .header-day {
  border-left-color: rgba(255, 255, 255, 0.2);
  border-top-color: rgba(255, 255, 255, 0.2);
  border-right-color: rgba(255, 255, 255, 0.2);
  color: #dedefd;
}

.transparent-mode .cal1 .clndr .clndr-table tr .day.event:hover,
.transparent-mode
  .cal1
  .clndr
  .clndr-table
  .cal1
  .clndr
  .clndr-table
  tr
  .day.my-event:hover {
  color: #fff;
}

.transparent-mode .cal1 .clndr .clndr-table tr:last-child .day,
.transparent-mode
  .cal1
  .clndr
  .clndr-table
  .cal1
  .clndr
  .clndr-table
  tr:last-child
  .my-day {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .cal1 .clndr .clndr-table tr .empty,
.transparent-mode .cal1 .clndr .clndr-table tr .adjacent-month,
.transparent-mode .cal1 .clndr .clndr-table tr .my-empty,
.transparent-mode .cal1 .clndr .clndr-table tr .my-adjacent-month {
  border-left-color: rgba(255, 255, 255, 0.2);
  border-top-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .cal1 .clndr .clndr-table tr .day {
  border-left-color: rgba(255, 255, 255, 0.2);
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .cal1 .clndr .clndr-table tr .day.event,
.transparent-mode .cal1 .clndr .clndr-table tr .day.my-event {
  background: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode .cal1 .clndr .clndr-table tr .day:last-child {
  border-right-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .cal1 .clndr .clndr-table tr .day:hover {
  background: var(--transparent-body);
}

.transparent-mode .cal1 .clndr .clndr-controls {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

.transparent-mode
  .cal1
  .clndr
  .clndr-controls
  .clndr-control-button
  .clndr-previous-button,
.transparent-mode
  .cal1
  .clndr
  .clndr-controls
  .clndr-control-button
  .clndr-next-button {
  color: #fff;
}

.transparent-mode .fc-unthemed .fc-content,
.transparent-mode .fc-unthemed .fc-divider,
.transparent-mode .fc-unthemed .fc-list-heading td,
.transparent-mode .fc-unthemed .fc-list-view,
.transparent-mode .fc-unthemed .fc-popover,
.transparent-mode .fc-unthemed .fc-row,
.transparent-mode .fc-unthemed tbody,
.transparent-mode .fc-unthemed td,
.transparent-mode .fc-unthemed th,
.transparent-mode .fc-unthemed thead {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .fc-event,
.transparent-mode .fc-event-dot {
  color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .fc-unthemed .fc-divider,
.transparent-mode .fc-unthemed .fc-list-heading td,
.transparent-mode .fc-unthemed .fc-popover .fc-header {
  background: rgba(255, 255, 255, 0.2);
}

.transparent-mode .fc-toolbar .fc-state-active,
.transparent-mode .fc-toolbar .ui-state-active {
  background: #b4b4b4;
}

.transparent-mode
  .fc-today-button
  fc-button
  fc-state-default
  fc-corner-left
  fc-corner-right
  fc-state-disabled:focus {
  border: none !important;
  box-shadow: none !important;
}

.transparent-mode .fc-unthemed .fc-list-item:hover td {
  background-color: #eeeeee;
}

.transparent-mode .cal1 .clndr .clndr-table tr .empty:hover,
.transparent-mode .cal1 .clndr .clndr-table tr .adjacent-month:hover,
.transparent-mode .cal1 .clndr .clndr-table tr .my-empty:hover,
.transparent-mode .cal1 .clndr .clndr-table tr .my-adjacent-month:hover {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .cal1 .clndr .clndr-table .header-days .header-day {
  color: #fff;
}

.transparent-mode .instagram {
  background: linear-gradient(to right bottom, #de497b 0%, #e1164f 100%);
}

.transparent-mode .linkedin {
  background-image: linear-gradient(to right bottom, #0d97de 0%, #13547a 100%);
}

.transparent-mode .twitter {
  background-image: linear-gradient(to right bottom, #00f2fe 0%, #1e63c3 100%);
}

.transparent-mode .facebook {
  background-image: linear-gradient(to right bottom, #3d6cbf 0%, #1e3c72 100%);
}

.transparent-mode .map-header:before {
  background: linear-gradient(
    to bottom,
    rgba(245, 247, 251, 0) 5%,
    rgba(0, 0, 0, 0.2) 95%
  );
}

.transparent-mode .chart-dropshadow {
  -webkit-filter: drop-shadow(-6px 5px 4px #2a2635);
  filter: drop-shadow(-6px 5px 4px #2a2635);
}

.transparent-mode .chart-dropshadow-primary {
  -webkit-filter: drop-shadow(-6px 12px 4px rgba(133, 67, 246, 0.2));
  filter: drop-shadow(-6px 12px 4px rgba(133, 67, 246, 0.2));
}

.transparent-mode .chart-dropshadow-primary-1 {
  -webkit-filter: drop-shadow(-6px 12px 4px rgba(133, 67, 246, 0.2));
  filter: drop-shadow(-6px 12px 4px rgba(133, 67, 246, 0.2));
}

.transparent-mode .chart-dropshadow-danger {
  -webkit-filter: drop-shadow(-6px 12px 4px rgba(244, 88, 91, 0.1));
  filter: drop-shadow(-6px 5px 4px rgba(244, 88, 91, 0.1));
}

.transparent-mode .chart-dropshadow-warning {
  -webkit-filter: drop-shadow(-6px 5px 4px rgba(247, 183, 49, 0.1));
  filter: drop-shadow(-6px 5px 4px rgba(247, 183, 49, 0.1));
}

.transparent-mode .BarChartShadow {
  -webkit-filter: drop-shadow(-4px 9px 4px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(-6px 9px 4px rgba(0, 0, 0, 0.3));
}

.transparent-mode .chart-dropshadow2 {
  -webkit-filter: drop-shadow(-6px 5px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(-6px 5px 4px rgba(0, 0, 0, 0.2));
}

.transparent-mode .chart-dropshadow-secondary {
  -webkit-filter: drop-shadow(-6px 5px 4px rgba(130, 207, 242, 0.1));
  filter: drop-shadow(-6px 5px 4px rgba(130, 207, 242, 0.1));
}

.transparent-mode .chart-dropshadow-success {
  -webkit-filter: drop-shadow(-6px 5px 4px rgba(19, 191, 166, 0.1));
  filter: drop-shadow(-6px 5px 4px rgba(19, 191, 166, 0.1));
}

.transparent-mode .chart-dropshadow-info {
  -webkit-filter: drop-shadow(-6px 5px 4px rgba(7, 116, 248, 0.1));
  filter: drop-shadow(-6px 5px 4px rgba(7, 116, 248, 0.1));
}

.transparent-mode .donutShadow {
  -webkit-filter: drop-shadow(-5px 4px 6px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(-1px 0px 2px rgba(159, 120, 255, 0.5));
}

.transparent-mode .donutShadow-yellow {
  -webkit-filter: drop-shadow(-5px 4px 6px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(-1px 0px 2px rgba(251, 196, 52, 0.5));
}

.transparent-mode .donutShadow-blue {
  -webkit-filter: drop-shadow(-5px 4px 6px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(-1px 0px 2px rgba(36, 72, 135, 0.5));
}

.transparent-mode .main-chat-contacts-wrapper {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .main-chat-list .media {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .main-chat-list .media + .media {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .main-chat-list .media.new {
  background-color: transparent;
}

.transparent-mode
  .main-chat-list
  .media.new
  .media-contact-name
  span:first-child {
  color: #fff;
}

.transparent-mode .main-chat-list .media.new .media-body p {
  color: rgba(255, 255, 255, 0.5);
}

.transparent-mode .main-chat-list .media:hover,
.transparent-mode .main-chat-list .media:focus {
  background-color: rgba(0, 0, 0, 0.2);
  border-top-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .main-chat-list .media:hover:first-child,
.transparent-mode .main-chat-list .media:focus:first-child {
  border-top-color: transparent;
}

.transparent-mode .main-chat-list .media.selected {
  background-color: rgba(0, 0, 0, 0.2);
  border-top-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .main-chat-list .media.selected:first-child {
  border-top-color: transparent;
}

.transparent-mode
  .main-chat-list
  .media.selected
  .media-contact-name
  span:first-child {
  color: #fff;
}

.transparent-mode .main-chat-list .media.selected .media-body p {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .main-chat-list .main-img-user span {
  color: #fff;
  background-color: #05c3fb;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

.transparent-mode .main-chat-list .media-body p {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .main-chat-list .media-contact-name span:first-child {
  color: #fff;
}

.transparent-mode .main-chat-list .media-contact-name span:last-child {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .main-chat-header {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .main-chat-header .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .main-chat-msg-name small,
.transparent-mode .main-chat-body .media-body > div:last-child {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .main-chat-time span {
  background: transparent;
}

.transparent-mode .main-chat-time::before,
.transparent-mode .main-chat-time::after {
  background-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .main-chat-footer {
  border-top-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .main-chat-footer .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .main-chat-footer .form-control {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .main-chat-footer .form-control:hover,
.transparent-mode .main-chat-footer .form-control:focus {
  box-shadow: none;
}

.transparent-mode .main-content-title {
  color: #170c6b;
}

.transparent-mode .main-msg-wrapper {
  background-color: var(--transparent-body);
}

.transparent-mode .main-chat-body .media.flex-row-reverse .main-msg-wrapper {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.transparent-mode .chat-profile {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .shared-files {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .main-chat-list .media:hover,
.transparent-mode .main-chat-list .media:focus {
  background: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode .authentication .card:hover {
  box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.4), 0 3px 6px 0 rgba(0, 0, 0, 0.4);
}

.transparent-mode .authentication .form-control:focus {
  box-shadow: none;
}

.transparent-mode .authentication input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .wrap-login100 {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 9px 0 rgba(28, 28, 51, 0.15);
}

.transparent-mode .login100-form-title {
  color: #fff;
}

.transparent-mode .input100 {
  color: #dedefd;
  background: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .symbol-input100,
.transparent-mode .wrap-input100 input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .construction .btn.btn-icon {
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
}

.transparent-mode .range {
  background-color: whitesmoke;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: whitesmoke;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: whitesmoke;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: whitesmoke;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: whitesmoke;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.transparent-mode .range input[type='range'] {
  background-color: transparent;
}

.transparent-mode .range input[type='range']::-webkit-slider-thumb,
.transparent-mode .range input[type='range']::-moz-slider-thumb {
  color: white;
  background-color: #999999;
}

.transparent-mode .range output {
  color: white;
  background-color: #999999;
}

.transparent-mode
  .range.range-success
  input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-success input[type='range']::-moz-slider-thumb {
  background-color: #4ecc48;
}

.transparent-mode .range.range-success output {
  background-color: #4ecc48;
}

.transparent-mode .range.range-success input[type='range'] {
  outline-color: #4ecc48;
}

.transparent-mode .range.range-info input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-info input[type='range']::-moz-slider-thumb {
  background-color: #45aaf2;
}

.transparent-mode .range.range-info output {
  background-color: #45aaf2;
}

.transparent-mode .range.range-info input[type='range'] {
  outline-color: #45aaf2;
}

.transparent-mode
  .range.range-warning
  input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-warning input[type='range']::-moz-slider-thumb {
  background-color: #ecb403;
}

.transparent-mode .range.range-warning output {
  background-color: #ecb403;
}

.transparent-mode .range.range-warning input[type='range'] {
  outline-color: #ecb403;
}

.transparent-mode .range.range-danger input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-danger input[type='range']::-moz-slider-thumb {
  background-color: #c21a1a;
}

.transparent-mode .range.range-danger output {
  background-color: #c21a1a;
}

.transparent-mode .range.range-danger input[type='range'] {
  outline-color: #c21a1a;
}

.transparent-mode .range input[type='range'] {
  background-color: transparent;
}

.transparent-mode .range input[type='range']::-webkit-slider-thumb,
.transparent-mode .range input[type='range']::-moz-slider-thumb {
  color: white;
  background-color: #999999;
}

.transparent-mode .range output {
  color: white;
  background-color: #999999;
}

.transparent-mode
  .range.range-success
  input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-success input[type='range']::-moz-slider-thumb {
  background-color: #4ecc48;
}

.transparent-mode .range.range-success output {
  background-color: #4ecc48;
}

.transparent-mode .range.range-success input[type='range'] {
  outline-color: #4ecc48;
}

.transparent-mode .range.range-info input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-info input[type='range']::-moz-slider-thumb {
  background-color: #45aaf2;
}

.transparent-mode .range.range-info output {
  background-color: #45aaf2;
}

.transparent-mode .range.range-info input[type='range'] {
  outline-color: #45aaf2;
}

.transparent-mode
  .range.range-warning
  input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-warning input[type='range']::-moz-slider-thumb {
  background-color: #ecb403;
}

.transparent-mode .range.range-warning output {
  background-color: #ecb403;
}

.transparent-mode .range.range-warning input[type='range'] {
  outline-color: #ecb403;
}

.transparent-mode .range.range-danger input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-danger input[type='range']::-moz-slider-thumb {
  background-color: #c21a1a;
}

.transparent-mode .range.range-danger output {
  background-color: #c21a1a;
}

.transparent-mode .range.range-danger input[type='range'] {
  outline-color: #c21a1a;
}

.transparent-mode .range input[type='range'] {
  background-color: transparent;
}

.transparent-mode .range input[type='range']::-webkit-slider-thumb,
.transparent-mode .range input[type='range']::-moz-slider-thumb {
  color: white;
  background-color: #999999;
}

.transparent-mode .range output {
  color: white;
  background-color: #999999;
}

.transparent-mode
  .range.range-success
  input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-success input[type='range']::-moz-slider-thumb {
  background-color: #4ecc48;
}

.transparent-mode .range.range-success output {
  background-color: #4ecc48;
}

.transparent-mode .range.range-success input[type='range'] {
  outline-color: #4ecc48;
}

.transparent-mode .range.range-info input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-info input[type='range']::-moz-slider-thumb {
  background-color: #45aaf2;
}

.transparent-mode .range.range-info output {
  background-color: #45aaf2;
}

.transparent-mode .range.range-info input[type='range'] {
  outline-color: #45aaf2;
}

.transparent-mode
  .range.range-warning
  input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-warning input[type='range']::-moz-slider-thumb {
  background-color: #ecb403;
}

.transparent-mode .range.range-warning output {
  background-color: #ecb403;
}

.transparent-mode .range.range-warning input[type='range'] {
  outline-color: #ecb403;
}

.transparent-mode .range.range-danger input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-danger input[type='range']::-moz-slider-thumb {
  background-color: #c21a1a;
}

.transparent-mode .range.range-danger output {
  background-color: #c21a1a;
}

.transparent-mode .range.range-danger input[type='range'] {
  outline-color: #c21a1a;
}

.transparent-mode .range input[type='range'] {
  background-color: transparent;
}

.transparent-mode .range input[type='range']::-webkit-slider-thumb,
.transparent-mode .range input[type='range']::-moz-slider-thumb {
  color: white;
  background-color: #999999;
}

.transparent-mode .range output {
  color: white;
  background-color: #999999;
}

.transparent-mode
  .range.range-success
  input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-success input[type='range']::-moz-slider-thumb {
  background-color: #4ecc48;
}

.transparent-mode .range.range-success output {
  background-color: #4ecc48;
}

.transparent-mode .range.range-success input[type='range'] {
  outline-color: #4ecc48;
}

.transparent-mode .range.range-info input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-info input[type='range']::-moz-slider-thumb {
  background-color: #45aaf2;
}

.transparent-mode .range.range-info output {
  background-color: #45aaf2;
}

.transparent-mode .range.range-info input[type='range'] {
  outline-color: #45aaf2;
}

.transparent-mode
  .range.range-warning
  input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-warning input[type='range']::-moz-slider-thumb {
  background-color: #ecb403;
}

.transparent-mode .range.range-warning output {
  background-color: #ecb403;
}

.transparent-mode .range.range-warning input[type='range'] {
  outline-color: #ecb403;
}

.transparent-mode .range.range-danger input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-danger input[type='range']::-moz-slider-thumb {
  background-color: #c21a1a;
}

.transparent-mode .range.range-danger output {
  background-color: #c21a1a;
}

.transparent-mode .range.range-danger input[type='range'] {
  outline-color: #c21a1a;
}

.transparent-mode .range input[type='range'] {
  background-color: transparent;
}

.transparent-mode .range input[type='range']::-webkit-slider-thumb,
.transparent-mode .range input[type='range']::-moz-slider-thumb {
  color: white;
  background-color: #999999;
}

.transparent-mode .range output {
  color: white;
  background-color: #999999;
}

.transparent-mode
  .range.range-success
  input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-success input[type='range']::-moz-slider-thumb {
  background-color: #4ecc48;
}

.transparent-mode .range.range-success output {
  background-color: #4ecc48;
}

.transparent-mode .range.range-success input[type='range'] {
  outline-color: #4ecc48;
}

.transparent-mode .range.range-info input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-info input[type='range']::-moz-slider-thumb {
  background-color: #45aaf2;
}

.transparent-mode .range.range-info output {
  background-color: #45aaf2;
}

.transparent-mode .range.range-info input[type='range'] {
  outline-color: #45aaf2;
}

.transparent-mode
  .range.range-warning
  input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-warning input[type='range']::-moz-slider-thumb {
  background-color: #ecb403;
}

.transparent-mode .range.range-warning output {
  background-color: #ecb403;
}

.transparent-mode .range.range-warning input[type='range'] {
  outline-color: #ecb403;
}

.transparent-mode .range.range-danger input[type='range']::-webkit-slider-thumb,
.transparent-mode .range.range-danger input[type='range']::-moz-slider-thumb {
  background-color: #c21a1a;
}

.transparent-mode .range.range-danger output {
  background-color: #c21a1a;
}

.transparent-mode .range.range-danger input[type='range'] {
  outline-color: #c21a1a;
}

.transparent-mode .ribbon1 {
  color: #fff;
}

.transparent-mode .ribbon1:after {
  border-left: 20px solid transparent;
  border-right: 24px solid transparent;
  border-top: 13px solid #f8463f;
}

.transparent-mode .ribbon1 span {
  background: #f8463f;
}

.transparent-mode .ribbon1 span:before {
  background: #f8463f;
}

.transparent-mode .ribbon1 span:after {
  background: #c02031;
}

.transparent-mode .ribbon span {
  color: #fff;
  background: #79a70a;
  background: linear-gradient(#f8463f 0%, #f8463f 100%);
  box-shadow: 0 3px 10px -5px black;
}

.transparent-mode .ribbon span::before {
  border-left: 3px solid #f8463f;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #f8463f;
}

.transparent-mode .ribbon span::after {
  border-left: 3px solid transparent;
  border-right: 3px solid #f8463f;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #f8463f;
}

.transparent-mode .wizard {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .wizard > .steps a {
  color: var(--primary-bg-color);
}

.transparent-mode .wizard > .steps a:hover,
.transparent-mode .wizard > .steps a:active {
  color: var(--primary-bg-color);
}

.transparent-mode .wizard > .steps a .number,
.transparent-mode .wizard > .steps a:hover .number,
.transparent-mode .wizard > .steps a:active .number {
  background-color: #ededf3;
}

.transparent-mode .wizard > .steps .disabled a {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .wizard > .steps .disabled a:hover,
.transparent-mode .wizard > .steps .disabled a:active {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .wizard > .steps .current a {
  color: var(--primary-bg-color);
}

.transparent-mode .wizard > .steps .current a:hover,
.transparent-mode .wizard > .steps .current a:active {
  color: var(--primary-bg-color);
}

.transparent-mode .wizard > .steps .current a .number,
.transparent-mode .wizard > .steps .current a:hover .number,
.transparent-mode .wizard > .steps .current a:active .number {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.transparent-mode .wizard > .steps .done a {
  color: #09ad95;
}

.transparent-mode .wizard > .steps .done a:hover,
.transparent-mode .wizard > .steps .done a:active {
  color: #09ad95;
}

.transparent-mode .wizard > .steps .done a .number,
.transparent-mode .wizard > .steps .done a:hover .number,
.transparent-mode .wizard > .steps .done a:active .number {
  background-color: #09ad95;
  color: #fff;
}

.transparent-mode .wizard > .content {
  border-top-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .wizard > .content > .title {
  color: #282f53;
}

.transparent-mode .wizard > .content > .body input.parsley-error {
  border-color: #ff5c77;
}

.transparent-mode .wizard > .actions > ul > li:last-child a {
  background-color: #09ad95;
}

.transparent-mode .wizard > .actions a {
  background-color: var(--primary-bg-color);
  color: #fff;
}

.transparent-mode .wizard > .actions a:hover,
.transparent-mode .wizard > .actions a:active {
  background-color: var(--primary-bg-color);
  color: #fff;
}

.transparent-mode .wizard > .actions .disabled a {
  background-color: var(--transparent-body);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .wizard > .actions .disabled a:hover,
.transparent-mode .wizard > .actions .disabled a:active {
  background-color: var(--transparent-body);
  color: rgba(255, 255, 255, 0.7);
}

@media (min-width: 576px) {
  .transparent-mode .wizard.vertical > .content {
    border-left-color: rgba(255, 255, 255, 0.2);
    border-right-color: rgba(255, 255, 255, 0.2);
  }
}

@media (min-width: 576px) {
  .transparent-mode .wizard.vertical > .actions {
    border-left-color: rgba(255, 255, 255, 0.2);
    border-right-color: rgba(255, 255, 255, 0.2);
  }
}

.transparent-mode .wizard-style-1 > .steps > ul a .number,
.transparent-mode .wizard-style-1 > .steps > ul a:hover .number,
.transparent-mode .wizard-style-1 > .steps > ul a:active .number {
  color: #696e8d;
  background-color: #f3f7fd;
}

.transparent-mode .wizard-style-1 > .steps > ul .current a .number,
.transparent-mode .wizard-style-1 > .steps > ul .current a:hover .number,
.transparent-mode .wizard-style-1 > .steps > ul .current a:active .number {
  background-color: var(--primary-bg-color);
  color: #fff;
}

.transparent-mode .wizard-style-1 > .steps > ul .done a .number,
.transparent-mode .wizard-style-1 > .steps > ul .done a:hover .number,
.transparent-mode .wizard-style-1 > .steps > ul .done a:active .number {
  background-color: #643ab0;
  color: #fff;
}

.transparent-mode .wizard-style-2 > .steps > ul a .number,
.transparent-mode .wizard-style-2 > .steps > ul a:hover .number,
.transparent-mode .wizard-style-2 > .steps > ul a:active .number {
  border: 2px solid #f3f7fd;
  color: #696e8d;
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .wizard-style-2 > .steps > ul .current a .number,
.transparent-mode .wizard-style-2 > .steps > ul .current a:hover .number,
.transparent-mode .wizard-style-2 > .steps > ul .current a:active .number {
  border-color: var(--primary-bg-color);
  color: var(--primary-bg-color);
}

.transparent-mode .wizard-style-2 > .steps > ul .done a .number,
.transparent-mode .wizard-style-2 > .steps > ul .done a:hover .number,
.transparent-mode .wizard-style-2 > .steps > ul .done a:active .number {
  border-color: #8c3feb;
  color: #8c3feb;
}

.transparent-mode .parsley-required {
  color: #ff5c77;
}

.transparent-mode .wizard-card .moving-tab {
  background-color: var(--primary-bg-color) !important;
}

.transparent-mode .form-group label.control-label {
  color: var(--primary-bg-color);
}

.transparent-mode .wizard-card.form-group .form-control {
  background-image: linear-gradient(
    #c4c4c4,
    #c4c4c4,
    linear-gradient(#e1e1e2, #e1e1e2)
  );
}

.transparent-mode .wizard-container .wizard-navigation {
  background: #ebeff8;
}

.transparent-mode .wizard-card .choice:hover .icon,
.transparent-mode .wizard-card .choice.active .icon {
  border-color: var(--primary-bg-color) !important;
  color: var(--primary-bg-color) !important;
}

.transparent-mode .widgets-cards .wrp.icon-circle i {
  color: #fff;
}

.transparent-mode .bg-secondary {
  background: #05c3fb !important;
}

.transparent-mode a.bg-secondary:hover,
.transparent-mode a.bg-secondary:focus {
  background-color: #05c3fb !important;
}

.transparent-mode button.bg-secondary:hover,
.transparent-mode button.bg-secondary:focus {
  background-color: #05c3fb !important;
}

.transparent-mode .bg-success {
  background: #09ad95 !important;
}

.transparent-mode a.bg-success:hover,
.transparent-mode a.bg-success:focus {
  background-color: #15bf42 !important;
}

.transparent-mode button.bg-success:hover,
.transparent-mode button.bg-success:focus {
  background-color: #15bf42 !important;
}

.transparent-mode .bg-info {
  background: #1170e4 !important;
}

.transparent-mode a.bg-info:hover,
.transparent-mode a.bg-info:focus {
  background-color: #1eb0e2 !important;
}

.transparent-mode button.bg-info:hover,
.transparent-mode button.bg-info:focus {
  background-color: #1eb0e2 !important;
}

.transparent-mode .bg-warning {
  background: #f7b731 !important;
}

.transparent-mode a.bg-warning:hover,
.transparent-mode a.bg-warning:focus {
  background-color: #e0a325 !important;
}

.transparent-mode button.bg-warning:hover,
.transparent-mode button.bg-warning:focus {
  background-color: #e0a325 !important;
}

.transparent-mode .bg-danger {
  background: #e82646 !important;
}

.transparent-mode a.bg-danger:hover,
.transparent-mode a.bg-danger:focus {
  background-color: #de223d !important;
}

.transparent-mode button.bg-danger:hover,
.transparent-mode button.bg-danger:focus {
  background-color: #de223d !important;
}

.transparent-mode .bg-light {
  background-color: var(--transparent-body) !important;
}

.transparent-mode a.bg-light:hover,
.transparent-mode a.bg-light:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode button.bg-light:hover,
.transparent-mode button.bg-light:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode .bg-dark {
  background-color: #343a40 !important;
}

.transparent-mode a.bg-dark:hover,
.transparent-mode a.bg-dark:focus {
  background-color: #1d2124 !important;
}

.transparent-mode button.bg-dark:hover,
.transparent-mode button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.transparent-mode .bg-facebook {
  background: #2b4170 !important;
}

.transparent-mode .bg-secondary-gradient {
  background: linear-gradient(
    to bottom right,
    #82cff2 0%,
    #28b7f9 100%
  ) !important;
}

.transparent-mode a.bg-secondary-gradient:hover,
.transparent-mode a.bg-secondary-gradient:focus {
  background-color: #05c3fb !important;
}

.transparent-mode button.bg-secondary-gradient:hover,
.transparent-mode button.bg-secondary-gradient:focus {
  background-color: #05c3fb !important;
}

.transparent-mode .bg-success-gradient {
  background: linear-gradient(
    to bottom right,
    #1ea38f 0%,
    #5cf9e2 100%
  ) !important;
}

.transparent-mode a.bg-success-gradient:hover,
.transparent-mode a.bg-success-gradient:focus {
  background-color: #448700 !important;
}

.transparent-mode button.bg-success-gradient:hover,
.transparent-mode button.bg-success-gradient:focus {
  background-color: #448700 !important;
}

.transparent-mode .bg-info-gradient {
  background: linear-gradient(
    to bottom right,
    #1e63c3 0%,
    #00f2fe 100%
  ) !important;
}

.transparent-mode a.bg-info-gradient:hover,
.transparent-mode a.bg-info-gradient:focus {
  background-color: #1594ef !important;
}

.transparent-mode button.bg-info-gradient:hover,
.transparent-mode button.bg-info-gradient:focus {
  background-color: #1594ef !important;
}

.transparent-mode .bg-warning-gradient {
  background: linear-gradient(
    to bottom right,
    #f66b4e 0%,
    #fbc434 100%
  ) !important;
}

.transparent-mode a.bg-warning-gradient:hover,
.transparent-mode a.bg-warning-gradient:focus {
  background-color: #fbb034 !important;
}

.transparent-mode button.bg-warning-gradient:hover,
.transparent-mode button.bg-warning-gradient:focus {
  background-color: #fbb034 !important;
}

.transparent-mode .bg-danger-gradient {
  background-image: linear-gradient(
    to bottom right,
    #b51b35 0%,
    #fd4a68 100%
  ) !important;
}

.transparent-mode a.bg-danger-gradient:hover,
.transparent-mode a.bg-danger-gradient:focus {
  background-color: #a11918 !important;
}

.transparent-mode button.bg-danger-gradient:hover,
.transparent-mode button.bg-danger-gradient:focus {
  background-color: #a11918 !important;
}

.transparent-mode .bg-light-gradient {
  background-color: #f8f9fa !important;
}

.transparent-mode a.bg-light-gradient:hover,
.transparent-mode a.bg-light-gradient:focus {
  background-color: #dae0e5 !important;
}

.transparent-mode button.bg-light-gradient:hover,
.transparent-mode button.bg-light-gradient:focus {
  background-color: #dae0e5 !important;
}

.transparent-mode .bg-dark-gradient {
  background-color: #343a40 !important;
}

.transparent-mode a.bg-dark-gradient:hover,
.transparent-mode a.bg-dark-gradient:focus {
  background-color: #1d2124 !important;
}

.transparent-mode button.bg-dark-gradient:hover,
.transparent-mode button.bg-dark-gradient:focus {
  background-color: #1d2124 !important;
}

.transparent-mode .bg-facebook-gradient {
  background: linear-gradient(to bottom right, #3b5998, #2b4170) !important;
}

.transparent-mode .bg-white {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode .bg-transparent {
  background-color: transparent !important;
}

.transparent-mode .bg1 {
  background: linear-gradient(to right bottom, #163b7c 0%, #548beb 100%);
}

.transparent-mode .bg2 {
  background: linear-gradient(
    to bottom right,
    #00f2fe 0%,
    #1e63c3 100%
  ) !important;
}

.transparent-mode .bg3 {
  background: linear-gradient(to bottom right, #f53e31, #dd4b39);
}

.transparent-mode .bg-purple {
  background: #8927ec !important;
  color: #fff !important;
}

.transparent-mode a.bg-purple:hover,
.transparent-mode a.bg-purple:focus {
  background-color: #8c31e4 !important;
}

.transparent-mode button.bg-purple:hover,
.transparent-mode button.bg-purple:focus {
  background-color: #8c31e4 !important;
}

.transparent-mode .bg-blue-lightest {
  background-color: #edf2fa !important;
}

.transparent-mode a.bg-blue-lightest:hover,
.transparent-mode a.bg-blue-lightest:focus {
  background-color: #c5d5ef !important;
}

.transparent-mode button.bg-blue-lightest:hover,
.transparent-mode button.bg-blue-lightest:focus {
  background-color: #c5d5ef !important;
}

.transparent-mode .bg-blue-lighter {
  background-color: #c8d9f1 !important;
}

.transparent-mode a.bg-blue-lighter:hover,
.transparent-mode a.bg-blue-lighter:focus {
  background-color: #9fbde7 !important;
}

.transparent-mode button.bg-blue-lighter:hover,
.transparent-mode button.bg-blue-lighter:focus {
  background-color: #9fbde7 !important;
}

.transparent-mode .bg-blue-light {
  background-color: #7ea5dd !important;
}

.transparent-mode a.bg-blue-light:hover,
.transparent-mode a.bg-blue-light:focus {
  background-color: #5689d2 !important;
}

.transparent-mode button.bg-blue-light:hover,
.transparent-mode button.bg-blue-light:focus {
  background-color: #5689d2 !important;
}

.transparent-mode .bg-blue-dark {
  background-color: #3866a6 !important;
}

.transparent-mode a.bg-blue-dark:hover,
.transparent-mode a.bg-blue-dark:focus {
  background-color: #2b4f80 !important;
}

.transparent-mode button.bg-blue-dark:hover,
.transparent-mode button.bg-blue-dark:focus {
  background-color: #2b4f80 !important;
}

.transparent-mode .bg-blue-darker {
  background-color: #1c3353 !important;
}

.transparent-mode a.bg-blue-darker:hover,
.transparent-mode a.bg-blue-darker:focus {
  background-color: #0f1c2d !important;
}

.transparent-mode button.bg-blue-darker:hover,
.transparent-mode button.bg-blue-darker:focus {
  background-color: #0f1c2d !important;
}

.transparent-mode .bg-blue-darkest {
  background-color: #0e1929 !important;
}

.transparent-mode a.bg-blue-darkest:hover,
.transparent-mode a.bg-blue-darkest:focus {
  background-color: #010203 !important;
}

.transparent-mode button.bg-blue-darkest:hover,
.transparent-mode button.bg-blue-darkest:focus {
  background-color: #010203 !important;
}

.transparent-mode .bg-purssianblue {
  background-color: #362f71;
}

.transparent-mode a.bg-purssianblue-lightest:hover,
.transparent-mode a.bg-purssianblue-lightest:focus {
  background-color: #3f3688 !important;
}

.transparent-mode button.bg-purssianblue-lightest:hover,
.transparent-mode button.bg-purssianblue-lightest:focus {
  background-color: #3f3688 !important;
}

.transparent-mode .bg-indigo-lightest {
  background-color: #f0f1fa !important;
}

.transparent-mode a.bg-indigo-lightest:hover,
.transparent-mode a.bg-indigo-lightest:focus {
  background-color: #cacded !important;
}

.transparent-mode button.bg-indigo-lightest:hover,
.transparent-mode button.bg-indigo-lightest:focus {
  background-color: #cacded !important;
}

.transparent-mode .bg-indigo-lighter {
  background-color: #d1d5f0 !important;
}

.transparent-mode a.bg-indigo-lighter:hover,
.transparent-mode a.bg-indigo-lighter:focus {
  background-color: #abb2e3 !important;
}

.transparent-mode button.bg-indigo-lighter:hover,
.transparent-mode button.bg-indigo-lighter:focus {
  background-color: #abb2e3 !important;
}

.transparent-mode .bg-indigo-light {
  background-color: #939edc !important;
}

.transparent-mode a.bg-indigo-light:hover,
.transparent-mode a.bg-indigo-light:focus {
  background-color: #6c7bd0 !important;
}

.transparent-mode button.bg-indigo-light:hover,
.transparent-mode button.bg-indigo-light:focus {
  background-color: #6c7bd0 !important;
}

.transparent-mode .bg-indigo-dark {
  background-color: #515da4 !important;
}

.transparent-mode a.bg-indigo-dark:hover,
.transparent-mode a.bg-indigo-dark:focus {
  background-color: #404a82 !important;
}

.transparent-mode button.bg-indigo-dark:hover,
.transparent-mode button.bg-indigo-dark:focus {
  background-color: #404a82 !important;
}

.transparent-mode .bg-indigo-darker {
  background-color: #282e52 !important;
}

.transparent-mode a.bg-indigo-darker:hover,
.transparent-mode a.bg-indigo-darker:focus {
  background-color: #171b30 !important;
}

.transparent-mode button.bg-indigo-darker:hover,
.transparent-mode button.bg-indigo-darker:focus {
  background-color: #171b30 !important;
}

.transparent-mode .bg-indigo-darkest {
  background-color: #141729 !important;
}

.transparent-mode a.bg-indigo-darkest:hover,
.transparent-mode a.bg-indigo-darkest:focus {
  background-color: #030407 !important;
}

.transparent-mode button.bg-indigo-darkest:hover,
.transparent-mode button.bg-indigo-darkest:focus {
  background-color: #030407 !important;
}

.transparent-mode .bg-purple-lightest {
  background-color: #f6effd !important;
}

.transparent-mode a.bg-purple-lightest:hover,
.transparent-mode a.bg-purple-lightest:focus {
  background-color: #ddc2f7 !important;
}

.transparent-mode button.bg-purple-lightest:hover,
.transparent-mode button.bg-purple-lightest:focus {
  background-color: #ddc2f7 !important;
}

.transparent-mode .bg-purple-lighter {
  background-color: #e4cff9 !important;
}

.transparent-mode a.bg-purple-lighter:hover,
.transparent-mode a.bg-purple-lighter:focus {
  background-color: #cba2f3 !important;
}

.transparent-mode button.bg-purple-lighter:hover,
.transparent-mode button.bg-purple-lighter:focus {
  background-color: #cba2f3 !important;
}

.transparent-mode .bg-purple-light {
  background-color: #c08ef0 !important;
}

.transparent-mode a.bg-purple-light:hover,
.transparent-mode a.bg-purple-light:focus {
  background-color: #a761ea !important;
}

.transparent-mode button.bg-purple-light:hover,
.transparent-mode button.bg-purple-light:focus {
  background-color: #a761ea !important;
}

.transparent-mode .bg-purple-dark {
  background-color: #844bbb !important;
}

.transparent-mode a.bg-purple-dark:hover,
.transparent-mode a.bg-purple-dark:focus {
  background-color: #6a3a99 !important;
}

.transparent-mode button.bg-purple-dark:hover,
.transparent-mode button.bg-purple-dark:focus {
  background-color: #6a3a99 !important;
}

.transparent-mode .bg-purple-darker {
  background-color: #42265e !important;
}

.transparent-mode a.bg-purple-darker:hover,
.transparent-mode a.bg-purple-darker:focus {
  background-color: #29173a !important;
}

.transparent-mode button.bg-purple-darker:hover,
.transparent-mode button.bg-purple-darker:focus {
  background-color: #29173a !important;
}

.transparent-mode .bg-purple-darkest {
  background-color: #21132f !important;
}

.transparent-mode a.bg-purple-darkest:hover,
.transparent-mode a.bg-purple-darkest:focus {
  background-color: #08040b !important;
}

.transparent-mode button.bg-purple-darkest:hover,
.transparent-mode button.bg-purple-darkest:focus {
  background-color: #08040b !important;
}

.transparent-mode .bg-pink-lightest {
  background-color: #fef0f5 !important;
}

.transparent-mode a.bg-pink-lightest:hover,
.transparent-mode a.bg-pink-lightest:focus {
  background-color: #fbc0d5 !important;
}

.transparent-mode button.bg-pink-lightest:hover,
.transparent-mode button.bg-pink-lightest:focus {
  background-color: #fbc0d5 !important;
}

.transparent-mode .bg-pink-lighter {
  background-color: #fcd3e1 !important;
}

.transparent-mode a.bg-pink-lighter:hover,
.transparent-mode a.bg-pink-lighter:focus {
  background-color: #f9a3c0 !important;
}

.transparent-mode button.bg-pink-lighter:hover,
.transparent-mode button.bg-pink-lighter:focus {
  background-color: #f9a3c0 !important;
}

.transparent-mode .bg-pink-light {
  background-color: #f999b9 !important;
}

.transparent-mode a.bg-pink-light:hover,
.transparent-mode a.bg-pink-light:focus {
  background-color: #f66998 !important;
}

.transparent-mode button.bg-pink-light:hover,
.transparent-mode button.bg-pink-light:focus {
  background-color: #f66998 !important;
}

.transparent-mode .bg-pink-dark {
  background-color: #c5577c !important;
}

.transparent-mode a.bg-pink-dark:hover,
.transparent-mode a.bg-pink-dark:focus {
  background-color: #ad3c62 !important;
}

.transparent-mode button.bg-pink-dark:hover,
.transparent-mode button.bg-pink-dark:focus {
  background-color: #ad3c62 !important;
}

.transparent-mode .bg-pink-darker {
  background-color: #622c3e !important;
}

.transparent-mode a.bg-pink-darker:hover,
.transparent-mode a.bg-pink-darker:focus {
  background-color: #3f1c28 !important;
}

.transparent-mode button.bg-pink-darker:hover,
.transparent-mode button.bg-pink-darker:focus {
  background-color: #3f1c28 !important;
}

.transparent-mode .bg-pink-darkest {
  background-color: #31161f !important;
}

.transparent-mode a.bg-pink-darkest:hover,
.transparent-mode a.bg-pink-darkest:focus {
  background-color: #0e0609 !important;
}

.transparent-mode button.bg-pink-darkest:hover,
.transparent-mode button.bg-pink-darkest:focus {
  background-color: #0e0609 !important;
}

.transparent-mode .bg-red-lightest {
  background-color: #fae9e9 !important;
}

.transparent-mode a.bg-red-lightest:hover,
.transparent-mode a.bg-red-lightest:focus {
  background-color: #f1bfbf !important;
}

.transparent-mode button.bg-red-lightest:hover,
.transparent-mode button.bg-red-lightest:focus {
  background-color: #f1bfbf !important;
}

.transparent-mode .bg-red-lighter {
  background-color: #f0bcbc !important;
}

.transparent-mode a.bg-red-lighter:hover,
.transparent-mode a.bg-red-lighter:focus {
  background-color: #e79292 !important;
}

.transparent-mode button.bg-red-lighter:hover,
.transparent-mode button.bg-red-lighter:focus {
  background-color: #e79292 !important;
}

.transparent-mode .bg-red-light {
  background-color: #dc6362 !important;
}

.transparent-mode a.bg-red-light:hover,
.transparent-mode a.bg-red-light:focus {
  background-color: #d33a38 !important;
}

.transparent-mode button.bg-red-light:hover,
.transparent-mode button.bg-red-light:focus {
  background-color: #d33a38 !important;
}

.transparent-mode .bg-red-dark {
  background-color: #a41a19 !important;
}

.transparent-mode a.bg-red-dark:hover,
.transparent-mode a.bg-red-dark:focus {
  background-color: #781312 !important;
}

.transparent-mode button.bg-red-dark:hover,
.transparent-mode button.bg-red-dark:focus {
  background-color: #781312 !important;
}

.transparent-mode .bg-red-darker {
  background-color: #520d0c !important;
}

.transparent-mode a.bg-red-darker:hover,
.transparent-mode a.bg-red-darker:focus {
  background-color: #260605 !important;
}

.transparent-mode button.bg-red-darker:hover,
.transparent-mode button.bg-red-darker:focus {
  background-color: #260605 !important;
}

.transparent-mode .bg-red-darkest {
  background-color: #290606 !important;
}

.transparent-mode a.bg-red-darkest:hover,
.transparent-mode a.bg-red-darkest:focus {
  background-color: black !important;
}

.transparent-mode button.bg-red-darkest:hover,
.transparent-mode button.bg-red-darkest:focus {
  background-color: black !important;
}

.transparent-mode .bg-orange-lightest {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode a.bg-orange-lightest:hover,
.transparent-mode a.bg-orange-lightest:focus {
  background-color: peachpuff !important;
}

.transparent-mode button.bg-orange-lightest:hover,
.transparent-mode button.bg-orange-lightest:focus {
  background-color: peachpuff !important;
}

.transparent-mode .bg-orange-lighter {
  background-color: #fee0c7 !important;
}

.transparent-mode a.bg-orange-lighter:hover,
.transparent-mode a.bg-orange-lighter:focus {
  background-color: #fdc495 !important;
}

.transparent-mode button.bg-orange-lighter:hover,
.transparent-mode button.bg-orange-lighter:focus {
  background-color: #fdc495 !important;
}

.transparent-mode .bg-orange-light {
  background-color: #feb67c !important;
}

.transparent-mode a.bg-orange-light:hover,
.transparent-mode a.bg-orange-light:focus {
  background-color: #fe9a49 !important;
}

.transparent-mode button.bg-orange-light:hover,
.transparent-mode button.bg-orange-light:focus {
  background-color: #fe9a49 !important;
}

.transparent-mode .bg-orange-dark {
  background-color: #ca7836 !important;
}

.transparent-mode a.bg-orange-dark:hover,
.transparent-mode a.bg-orange-dark:focus {
  background-color: #a2602b !important;
}

.transparent-mode button.bg-orange-dark:hover,
.transparent-mode button.bg-orange-dark:focus {
  background-color: #a2602b !important;
}

.transparent-mode .bg-orange-darker {
  background-color: #653c1b !important;
}

.transparent-mode a.bg-orange-darker:hover,
.transparent-mode a.bg-orange-darker:focus {
  background-color: #3d2410 !important;
}

.transparent-mode button.bg-orange-darker:hover,
.transparent-mode button.bg-orange-darker:focus {
  background-color: #3d2410 !important;
}

.transparent-mode .bg-orange-darkest {
  background-color: #331e0e !important;
}

.transparent-mode a.bg-orange-darkest:hover,
.transparent-mode a.bg-orange-darkest:focus {
  background-color: #0b0603 !important;
}

.transparent-mode button.bg-orange-darkest:hover,
.transparent-mode button.bg-orange-darkest:focus {
  background-color: #0b0603 !important;
}

.transparent-mode .bg-yellow-lightest {
  background-color: #fef9e7 !important;
}

.transparent-mode a.bg-yellow-lightest:hover,
.transparent-mode a.bg-yellow-lightest:focus {
  background-color: #fcedb6 !important;
}

.transparent-mode button.bg-yellow-lightest:hover,
.transparent-mode button.bg-yellow-lightest:focus {
  background-color: #fcedb6 !important;
}

.transparent-mode .bg-yellow-lighter {
  background-color: #fbedb7 !important;
}

.transparent-mode a.bg-yellow-lighter:hover,
.transparent-mode a.bg-yellow-lighter:focus {
  background-color: #f8e187 !important;
}

.transparent-mode button.bg-yellow-lighter:hover,
.transparent-mode button.bg-yellow-lighter:focus {
  background-color: #f8e187 !important;
}

.transparent-mode .bg-yellow-light {
  background-color: #f5d657 !important;
}

.transparent-mode a.bg-yellow-light:hover,
.transparent-mode a.bg-yellow-light:focus {
  background-color: #f2ca27 !important;
}

.transparent-mode button.bg-yellow-light:hover,
.transparent-mode button.bg-yellow-light:focus {
  background-color: #f2ca27 !important;
}

.transparent-mode .bg-yellow-dark {
  background-color: #c19d0c !important;
}

.transparent-mode a.bg-yellow-dark:hover,
.transparent-mode a.bg-yellow-dark:focus {
  background-color: #917609 !important;
}

.transparent-mode button.bg-yellow-dark:hover,
.transparent-mode button.bg-yellow-dark:focus {
  background-color: #917609 !important;
}

.transparent-mode .bg-yellow-darker {
  background-color: #604e06 !important;
}

.transparent-mode a.bg-yellow-darker:hover,
.transparent-mode a.bg-yellow-darker:focus {
  background-color: #302703 !important;
}

.transparent-mode button.bg-yellow-darker:hover,
.transparent-mode button.bg-yellow-darker:focus {
  background-color: #302703 !important;
}

.transparent-mode .bg-yellow-darkest {
  background-color: #302703 !important;
}

.transparent-mode a.bg-yellow-darkest:hover,
.transparent-mode a.bg-yellow-darkest:focus {
  background-color: black !important;
}

.transparent-mode button.bg-yellow-darkest:hover,
.transparent-mode button.bg-yellow-darkest:focus {
  background-color: black !important;
}

.transparent-mode .bg-green-lightest {
  background-color: #eff8e6 !important;
}

.transparent-mode a.bg-green-lightest:hover,
.transparent-mode a.bg-green-lightest:focus {
  background-color: #d6edbe !important;
}

.transparent-mode button.bg-green-lightest:hover,
.transparent-mode button.bg-green-lightest:focus {
  background-color: #d6edbe !important;
}

.transparent-mode .bg-green-lighter {
  background-color: #cfeab3 !important;
}

.transparent-mode a.bg-green-lighter:hover,
.transparent-mode a.bg-green-lighter:focus {
  background-color: #b6df8b !important;
}

.transparent-mode button.bg-green-lighter:hover,
.transparent-mode button.bg-green-lighter:focus {
  background-color: #b6df8b !important;
}

.transparent-mode .bg-green-light {
  background-color: #8ecf4d !important;
}

.transparent-mode a.bg-green-light:hover,
.transparent-mode a.bg-green-light:focus {
  background-color: #75b831 !important;
}

.transparent-mode button.bg-green-light:hover,
.transparent-mode button.bg-green-light:focus {
  background-color: #75b831 !important;
}

.transparent-mode .bg-green-dark {
  background-color: #4b9500 !important;
}

.transparent-mode a.bg-green-dark:hover,
.transparent-mode a.bg-green-dark:focus {
  background-color: #316200 !important;
}

.transparent-mode button.bg-green-dark:hover,
.transparent-mode button.bg-green-dark:focus {
  background-color: #316200 !important;
}

.transparent-mode .bg-green-darker {
  background-color: #264a00 !important;
}

.transparent-mode a.bg-green-darker:hover,
.transparent-mode a.bg-green-darker:focus {
  background-color: #0c1700 !important;
}

.transparent-mode button.bg-green-darker:hover,
.transparent-mode button.bg-green-darker:focus {
  background-color: #0c1700 !important;
}

.transparent-mode .bg-green-darkest {
  background-color: #132500 !important;
}

.transparent-mode a.bg-green-darkest:hover,
.transparent-mode a.bg-green-darkest:focus {
  background-color: black !important;
}

.transparent-mode button.bg-green-darkest:hover,
.transparent-mode button.bg-green-darkest:focus {
  background-color: black !important;
}

.transparent-mode .bg-teal-lightest {
  background-color: #eafaf8 !important;
}

.transparent-mode a.bg-teal-lightest:hover,
.transparent-mode a.bg-teal-lightest:focus {
  background-color: #c1f0ea !important;
}

.transparent-mode button.bg-teal-lightest:hover,
.transparent-mode button.bg-teal-lightest:focus {
  background-color: #c1f0ea !important;
}

.transparent-mode .bg-teal-lighter {
  background-color: #bfefea !important;
}

.transparent-mode a.bg-teal-lighter:hover,
.transparent-mode a.bg-teal-lighter:focus {
  background-color: #96e5dd !important;
}

.transparent-mode button.bg-teal-lighter:hover,
.transparent-mode button.bg-teal-lighter:focus {
  background-color: #96e5dd !important;
}

.transparent-mode .bg-teal-light {
  background-color: #6bdbcf !important;
}

.transparent-mode a.bg-teal-light:hover,
.transparent-mode a.bg-teal-light:focus {
  background-color: #42d1c2 !important;
}

.transparent-mode button.bg-teal-light:hover,
.transparent-mode button.bg-teal-light:focus {
  background-color: #42d1c2 !important;
}

.transparent-mode .bg-teal-dark {
  background-color: #22a295 !important;
}

.transparent-mode a.bg-teal-dark:hover,
.transparent-mode a.bg-teal-dark:focus {
  background-color: #19786e !important;
}

.transparent-mode button.bg-teal-dark:hover,
.transparent-mode button.bg-teal-dark:focus {
  background-color: #19786e !important;
}

.transparent-mode .bg-teal-darker {
  background-color: #11514a !important;
}

.transparent-mode a.bg-teal-darker:hover,
.transparent-mode a.bg-teal-darker:focus {
  background-color: #082723 !important;
}

.transparent-mode button.bg-teal-darker:hover,
.transparent-mode button.bg-teal-darker:focus {
  background-color: #082723 !important;
}

.transparent-mode .bg-teal-darkest {
  background-color: #092925 !important;
}

.transparent-mode a.bg-teal-darkest:hover,
.transparent-mode a.bg-teal-darkest:focus {
  background-color: black !important;
}

.transparent-mode button.bg-teal-darkest:hover,
.transparent-mode button.bg-teal-darkest:focus {
  background-color: black !important;
}

.transparent-mode .bg-cyan-lightest {
  background-color: #e8f6f8 !important;
}

.transparent-mode a.bg-cyan-lightest:hover,
.transparent-mode a.bg-cyan-lightest:focus {
  background-color: #c1e7ec !important;
}

.transparent-mode button.bg-cyan-lightest:hover,
.transparent-mode button.bg-cyan-lightest:focus {
  background-color: #c1e7ec !important;
}

.transparent-mode .bg-cyan-lighter {
  background-color: #b9e3ea !important;
}

.transparent-mode a.bg-cyan-lighter:hover,
.transparent-mode a.bg-cyan-lighter:focus {
  background-color: #92d3de !important;
}

.transparent-mode button.bg-cyan-lighter:hover,
.transparent-mode button.bg-cyan-lighter:focus {
  background-color: #92d3de !important;
}

.transparent-mode .bg-cyan-light {
  background-color: #5dbecd !important;
}

.transparent-mode a.bg-cyan-light:hover,
.transparent-mode a.bg-cyan-light:focus {
  background-color: #3aabbd !important;
}

.transparent-mode button.bg-cyan-light:hover,
.transparent-mode button.bg-cyan-light:focus {
  background-color: #3aabbd !important;
}

.transparent-mode .bg-cyan-dark {
  background-color: #128293 !important;
}

.transparent-mode a.bg-cyan-dark:hover,
.transparent-mode a.bg-cyan-dark:focus {
  background-color: #0c5a66 !important;
}

.transparent-mode button.bg-cyan-dark:hover,
.transparent-mode button.bg-cyan-dark:focus {
  background-color: #0c5a66 !important;
}

.transparent-mode .bg-cyan-darker {
  background-color: #09414a !important;
}

.transparent-mode a.bg-cyan-darker:hover,
.transparent-mode a.bg-cyan-darker:focus {
  background-color: #03191d !important;
}

.transparent-mode button.bg-cyan-darker:hover,
.transparent-mode button.bg-cyan-darker:focus {
  background-color: #03191d !important;
}

.transparent-mode .bg-cyan-darkest {
  background-color: #052025 !important;
}

.transparent-mode a.bg-cyan-darkest:hover,
.transparent-mode a.bg-cyan-darkest:focus {
  background-color: black !important;
}

.transparent-mode button.bg-cyan-darkest:hover,
.transparent-mode button.bg-cyan-darkest:focus {
  background-color: black !important;
}

.transparent-mode .bg-white-lightest {
  background-color: white !important;
}

.transparent-mode a.bg-white-lightest:hover,
.transparent-mode a.bg-white-lightest:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode button.bg-white-lightest:hover,
.transparent-mode button.bg-white-lightest:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode .bg-white-lighter {
  background-color: white !important;
}

.transparent-mode a.bg-white-lighter:hover,
.transparent-mode a.bg-white-lighter:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode button.bg-white-lighter:hover,
.transparent-mode button.bg-white-lighter:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode .bg-white-light {
  background-color: white !important;
}

.transparent-mode a.bg-white-light:hover,
.transparent-mode a.bg-white-light:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode button.bg-white-light:hover,
.transparent-mode button.bg-white-light:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode .bg-white-dark {
  background-color: #cccccc !important;
}

.transparent-mode a.bg-white-dark:hover,
.transparent-mode a.bg-white-dark:focus {
  background-color: #b3b2b2 !important;
}

.transparent-mode button.bg-white-dark:hover,
.transparent-mode button.bg-white-dark:focus {
  background-color: #b3b2b2 !important;
}

.transparent-mode .bg-white-darker {
  background-color: #666666 !important;
}

.transparent-mode a.bg-white-darker:hover,
.transparent-mode a.bg-white-darker:focus {
  background-color: #4d4c4c !important;
}

.transparent-mode button.bg-white-darker:hover,
.transparent-mode button.bg-white-darker:focus {
  background-color: #4d4c4c !important;
}

.transparent-mode .bg-white-darkest {
  background-color: #333333 !important;
}

.transparent-mode a.bg-white-darkest:hover,
.transparent-mode a.bg-white-darkest:focus {
  background-color: #1a1919 !important;
}

.transparent-mode button.bg-white-darkest:hover,
.transparent-mode button.bg-white-darkest:focus {
  background-color: #1a1919 !important;
}

.transparent-mode .bg-gray-lightest {
  background-color: #f3f4f5 !important;
}

.transparent-mode a.bg-gray-lightest:hover,
.transparent-mode a.bg-gray-lightest:focus {
  background-color: #d7dbde !important;
}

.transparent-mode button.bg-gray-lightest:hover,
.transparent-mode button.bg-gray-lightest:focus {
  background-color: #d7dbde !important;
}

.transparent-mode .bg-gray-lighter {
  background-color: #dbdde0 !important;
}

.transparent-mode a.bg-gray-lighter:hover,
.transparent-mode a.bg-gray-lighter:focus {
  background-color: #c0c3c8 !important;
}

.transparent-mode button.bg-gray-lighter:hover,
.transparent-mode button.bg-gray-lighter:focus {
  background-color: #c0c3c8 !important;
}

.transparent-mode .bg-gray-light {
  background-color: #aab0b6 !important;
}

.transparent-mode a.bg-gray-light:hover,
.transparent-mode a.bg-gray-light:focus {
  background-color: #8f979e !important;
}

.transparent-mode button.bg-gray-light:hover,
.transparent-mode button.bg-gray-light:focus {
  background-color: #8f979e !important;
}

.transparent-mode .bg-gray-dark {
  background-color: #6b7278 !important;
  background: #343a40 !important;
}

.transparent-mode a.bg-gray-dark:hover,
.transparent-mode a.bg-gray-dark:focus {
  background-color: #53585d !important;
}

.transparent-mode button.bg-gray-dark:hover,
.transparent-mode button.bg-gray-dark:focus {
  background-color: #53585d !important;
}

.transparent-mode .bg-gray-darker {
  background-color: #36393c !important;
}

.transparent-mode a.bg-gray-darker:hover,
.transparent-mode a.bg-gray-darker:focus {
  background-color: #1e2021 !important;
}

.transparent-mode button.bg-gray-darker:hover,
.transparent-mode button.bg-gray-darker:focus {
  background-color: #1e2021 !important;
}

.transparent-mode .bg-gray-darkest {
  background-color: #1b1c1e !important;
}

.transparent-mode a.bg-gray-darkest:hover,
.transparent-mode a.bg-gray-darkest:focus {
  background-color: #030303 !important;
}

.transparent-mode button.bg-gray-darkest:hover,
.transparent-mode button.bg-gray-darkest:focus {
  background-color: #030303 !important;
}

.transparent-mode .bg-gray-dark-lightest {
  background-color: #ebebec !important;
}

.transparent-mode a.bg-gray-dark-lightest:hover,
.transparent-mode a.bg-gray-dark-lightest:focus {
  background-color: #d1d1d3 !important;
}

.transparent-mode button.bg-gray-dark-lightest:hover,
.transparent-mode button.bg-gray-dark-lightest:focus {
  background-color: #d1d1d3 !important;
}

.transparent-mode .bg-gray-dark-lighter {
  background-color: #c2c4c6 !important;
}

.transparent-mode a.bg-gray-dark-lighter:hover,
.transparent-mode a.bg-gray-dark-lighter:focus {
  background-color: #a8abad !important;
}

.transparent-mode button.bg-gray-dark-lighter:hover,
.transparent-mode button.bg-gray-dark-lighter:focus {
  background-color: #a8abad !important;
}

.transparent-mode .bg-gray-dark-light {
  background-color: #717579 !important;
}

.transparent-mode a.bg-gray-dark-light:hover,
.transparent-mode a.bg-gray-dark-light:focus {
  background-color: #585c5f !important;
}

.transparent-mode button.bg-gray-dark-light:hover,
.transparent-mode button.bg-gray-dark-light:focus {
  background-color: #585c5f !important;
}

.transparent-mode .bg-gray-dark-dark {
  background-color: #2a2e33 !important;
}

.transparent-mode a.bg-gray-dark-dark:hover,
.transparent-mode a.bg-gray-dark-dark:focus {
  background-color: #131517 !important;
}

.transparent-mode button.bg-gray-dark-dark:hover,
.transparent-mode button.bg-gray-dark-dark:focus {
  background-color: #131517 !important;
}

.transparent-mode .bg-gray-dark-darker {
  background-color: #15171a !important;
}

.transparent-mode a.bg-gray-dark-darker:hover,
.transparent-mode a.bg-gray-dark-darker:focus {
  background-color: black !important;
}

.transparent-mode button.bg-gray-dark-darker:hover,
.transparent-mode button.bg-gray-dark-darker:focus {
  background-color: black !important;
}

.transparent-mode .bg-gray-dark-darkest {
  background-color: #0a0c0d !important;
}

.transparent-mode a.bg-gray-dark-darkest:hover,
.transparent-mode a.bg-gray-dark-darkest:focus {
  background-color: black !important;
}

.transparent-mode button.bg-gray-dark-darkest:hover,
.transparent-mode button.bg-gray-dark-darkest:focus {
  background-color: black !important;
}

.transparent-mode .bg-azure-lightest {
  background-color: #ecf7fe !important;
}

.transparent-mode a.bg-azure-lightest:hover,
.transparent-mode a.bg-azure-lightest:focus {
  background-color: #bce3fb !important;
}

.transparent-mode button.bg-azure-lightest:hover,
.transparent-mode button.bg-azure-lightest:focus {
  background-color: #bce3fb !important;
}

.transparent-mode .bg-azure-lighter {
  background-color: #c7e6fb !important;
}

.transparent-mode a.bg-azure-lighter:hover,
.transparent-mode a.bg-azure-lighter:focus {
  background-color: #97d1f8 !important;
}

.transparent-mode button.bg-azure-lighter:hover,
.transparent-mode button.bg-azure-lighter:focus {
  background-color: #97d1f8 !important;
}

.transparent-mode .bg-azure-light {
  background-color: #7dc4f6 !important;
}

.transparent-mode a.bg-azure-light:hover,
.transparent-mode a.bg-azure-light:focus {
  background-color: #4daef3 !important;
}

.transparent-mode button.bg-azure-light:hover,
.transparent-mode button.bg-azure-light:focus {
  background-color: #4daef3 !important;
}

.transparent-mode .bg-azure-dark {
  background-color: #3788c2 !important;
}

.transparent-mode a.bg-azure-dark:hover,
.transparent-mode a.bg-azure-dark:focus {
  background-color: #2c6c9a !important;
}

.transparent-mode button.bg-azure-dark:hover,
.transparent-mode button.bg-azure-dark:focus {
  background-color: #2c6c9a !important;
}

.transparent-mode .bg-azure-darker {
  background-color: #1c4461 !important;
}

.transparent-mode a.bg-azure-darker:hover,
.transparent-mode a.bg-azure-darker:focus {
  background-color: #112839 !important;
}

.transparent-mode button.bg-azure-darker:hover,
.transparent-mode button.bg-azure-darker:focus {
  background-color: #112839 !important;
}

.transparent-mode .bg-azure-darkest {
  background-color: #0e2230 !important;
}

.transparent-mode a.bg-azure-darkest:hover,
.transparent-mode a.bg-azure-darkest:focus {
  background-color: #020609 !important;
}

.transparent-mode button.bg-azure-darkest:hover,
.transparent-mode button.bg-azure-darkest:focus {
  background-color: #020609 !important;
}

.transparent-mode .bg-lime-lightest {
  background-color: #f2fbeb !important;
}

.transparent-mode a.bg-lime-lightest:hover,
.transparent-mode a.bg-lime-lightest:focus {
  background-color: #d6f3c1 !important;
}

.transparent-mode button.bg-lime-lightest:hover,
.transparent-mode button.bg-lime-lightest:focus {
  background-color: #d6f3c1 !important;
}

.transparent-mode .bg-lime-lighter {
  background-color: #d7f2c2 !important;
}

.transparent-mode a.bg-lime-lighter:hover,
.transparent-mode a.bg-lime-lighter:focus {
  background-color: #bbe998 !important;
}

.transparent-mode button.bg-lime-lighter:hover,
.transparent-mode button.bg-lime-lighter:focus {
  background-color: #bbe998 !important;
}

.transparent-mode .bg-lime-light {
  background-color: #a3e072 !important;
}

.transparent-mode a.bg-lime-light:hover,
.transparent-mode a.bg-lime-light:focus {
  background-color: #88d748 !important;
}

.transparent-mode button.bg-lime-light:hover,
.transparent-mode button.bg-lime-light:focus {
  background-color: #88d748 !important;
}

.transparent-mode .bg-lime-dark {
  background-color: #62a82a !important;
}

.transparent-mode a.bg-lime-dark:hover,
.transparent-mode a.bg-lime-dark:focus {
  background-color: #4a7f20 !important;
}

.transparent-mode button.bg-lime-dark:hover,
.transparent-mode button.bg-lime-dark:focus {
  background-color: #4a7f20 !important;
}

.transparent-mode .bg-lime-darker {
  background-color: #315415 !important;
}

.transparent-mode a.bg-lime-darker:hover,
.transparent-mode a.bg-lime-darker:focus {
  background-color: #192b0b !important;
}

.transparent-mode button.bg-lime-darker:hover,
.transparent-mode button.bg-lime-darker:focus {
  background-color: #192b0b !important;
}

.transparent-mode .bg-lime-darkest {
  background-color: #192a0b !important;
}

.transparent-mode a.bg-lime-darkest:hover,
.transparent-mode a.bg-lime-darkest:focus {
  background-color: #010200 !important;
}

.transparent-mode button.bg-lime-darkest:hover,
.transparent-mode button.bg-lime-darkest:focus {
  background-color: #010200 !important;
}

.transparent-mode .bg-blue-1 {
  background-color: #0061da;
  color: #fff !important;
}

.transparent-mode .bg-blue {
  background: #3223f1;
  color: #fff !important;
}

.transparent-mode a.bg-blue:hover,
.transparent-mode a.bg-blue:focus {
  background-color: #4032f1 !important;
}

.transparent-mode button.bg-blue:hover,
.transparent-mode button.bg-blue:focus {
  background-color: #4032f1 !important;
}

.transparent-mode .bg-indigo {
  background: #6574cd;
  color: #fff !important;
}

.transparent-mode a.bg-indigo:hover,
.transparent-mode a.bg-indigo:focus {
  background-color: #3f51c1 !important;
}

.transparent-mode button.bg-indigo:hover,
.transparent-mode button.bg-indigo:focus {
  background-color: #3f51c1 !important;
}

.transparent-mode .bg-purple-gradient {
  background: linear-gradient(
    to bottom right,
    #8927ec 0%,
    #647dee 100%
  ) !important;
  color: #fff !important;
}

.transparent-mode a.bg-purple-gradient:hover,
.transparent-mode a.bg-purple-gradient:focus {
  background-color: #8c31e4 !important;
}

.transparent-mode button.bg-purple-gradient:hover,
.transparent-mode button.bg-purple-gradient:focus {
  background-color: #8c31e4 !important;
}

.transparent-mode .bg-pink {
  background: #fc5296 !important;
  color: #fff !important;
}

.transparent-mode .bg-darkpink {
  background-color: #14a485 !important;
  color: #fff !important;
}

.transparent-mode a.bg-pink:hover,
.transparent-mode a.bg-pink:focus {
  background-color: #f33d7a !important;
}

.transparent-mode button.bg-pink:hover,
.transparent-mode button.bg-pink:focus {
  background-color: #f33d7a !important;
}

.transparent-mode .bg-red {
  background: #e73827;
  color: #fff !important;
}

.transparent-mode a.bg-red:hover,
.transparent-mode a.bg-red:focus {
  background-color: #a11918 !important;
}

.transparent-mode button.bg-red:hover,
.transparent-mode button.bg-red:focus {
  background-color: #a11918 !important;
}

.transparent-mode .bg-orange {
  background: #fc7303;
  color: #fff !important;
}

.transparent-mode a.bg-orange:hover,
.transparent-mode a.bg-orange:focus {
  background-color: #fc7a12 !important;
}

.transparent-mode button.bg-orange:hover,
.transparent-mode button.bg-orange:focus {
  background-color: #fc7a12 !important;
}

.transparent-mode .bg-yellow-1 {
  background-color: #fbb034;
  color: #fff !important;
}

.transparent-mode .bg-yellow {
  background: #fbb034;
  color: #fff !important;
}

.transparent-mode a.bg-yellow:hover,
.transparent-mode a.bg-yellow:focus {
  background-color: #c29d0b !important;
}

.transparent-mode button.bg-yellow:hover,
.transparent-mode button.bg-yellow:focus {
  background-color: #c29d0b !important;
}

.transparent-mode .bg-green-1 {
  background-color: #4ecc48;
  color: #fff !important;
}

.transparent-mode .bg-green {
  background: #4ecc48;
  color: #fff !important;
}

.transparent-mode a.bg-green:hover,
.transparent-mode a.bg-green:focus {
  background-color: #448700 !important;
}

.transparent-mode button.bg-green:hover,
.transparent-mode button.bg-green:focus {
  background-color: #448700 !important;
}

.transparent-mode .bg-teal {
  background: #1caf9f !important;
}

.transparent-mode a.bg-teal:hover,
.transparent-mode a.bg-teal:focus {
  background-color: #22a193 !important;
}

.transparent-mode button.bg-teal:hover,
.transparent-mode button.bg-teal:focus {
  background-color: #22a193 !important;
}

.transparent-mode .bg-cyan {
  background: #007ea7 !important;
  color: #fff !important;
}

.transparent-mode a.bg-cyan:hover,
.transparent-mode a.bg-cyan:focus {
  background-color: #117a8b !important;
}

.transparent-mode button.bg-cyan:hover,
.transparent-mode button.bg-cyan:focus {
  background-color: #117a8b !important;
}

.transparent-mode a.bg-white:hover,
.transparent-mode a.bg-white:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode button.bg-white:hover,
.transparent-mode button.bg-white:focus {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode .bg-gray {
  background: #5a6970 !important;
}

.transparent-mode a.bg-gray:hover,
.transparent-mode a.bg-gray:focus {
  background-color: #6c757d !important;
}

.transparent-mode button.bg-gray:hover,
.transparent-mode button.bg-gray:focus {
  background-color: #6c757d !important;
}

.transparent-mode .bg-lightpink-red {
  color: #ff7088 !important;
}

.transparent-mode a.bg-gray-dark:hover,
.transparent-mode a.bg-gray-dark:focus {
  background-color: #1d2124 !important;
}

.transparent-mode button.bg-gray-dark:hover,
.transparent-mode button.bg-gray-dark:focus {
  background-color: #1d2124 !important;
}

.transparent-mode .bg-azure {
  background: #45aaf2;
}

.transparent-mode a.bg-azure:hover,
.transparent-mode a.bg-azure:focus {
  background-color: #1594ef !important;
}

.transparent-mode button.bg-azure:hover,
.transparent-mode button.bg-azure:focus {
  background-color: #1594ef !important;
}

.transparent-mode .bg-purple-1 {
  background: #8927ec;
}

.transparent-mode .bg-lime {
  background: #7bd235;
}

.transparent-mode a.bg-lime:hover,
.transparent-mode a.bg-lime:focus {
  background-color: #63ad27 !important;
}

.transparent-mode button.bg-lime:hover,
.transparent-mode button.bg-lime:focus {
  background-color: #63ad27 !important;
}

.transparent-mode .bg-square {
  color: #fff;
  background: #868e96;
}

.transparent-mode .bg-primary-light {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .bg-google-plus {
  background-color: #dd4b39;
}

.transparent-mode .bg-pinterest {
  background: linear-gradient(to right bottom, #c51629 0%, #bd081c 100%);
}

.transparent-mode .bg-light-gray {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .bg-progress-white {
  background-color: #eaeceb;
}

.transparent-mode .bg-dribbble {
  background: linear-gradient(to bottom right, #ea4c89, #c93764) !important;
}

.transparent-mode .bg-google {
  background: linear-gradient(to bottom right, #e64522, #c33219) !important;
  color: #fff;
}

.transparent-mode .bg-success-transparent {
  background-color: rgba(0, 230, 130, 0.1) !important;
}

.transparent-mode .bg-info-transparent {
  background-color: #123d68 !important;
}

.transparent-mode .bg-warning-transparent {
  background-color: rgba(255, 162, 43, 0.1) !important;
}

.transparent-mode .bg-danger-transparent {
  background-color: rgba(255, 56, 43, 0.1) !important;
}

.transparent-mode .bg-pink-transparent {
  background-color: rgba(213, 109, 252, 0.1) !important;
}

.transparent-mode .bg-purple-transparent {
  background-color: rgba(96, 77, 216, 0.1) !important;
}

.transparent-mode .bg-dark-transparent {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.transparent-mode .bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .bg-secondary-transparent {
  background-color: rgba(5, 195, 251, 0.1) !important;
}

.transparent-mode .shadow-primary {
  box-shadow: 0 7px 30px var(--primary03) !important;
}

.transparent-mode .shadow-secondary {
  box-shadow: 0 7px 30px rgba(130, 207, 242, 0.1) !important;
}

.transparent-mode .shadow-warning {
  box-shadow: 0 7px 30px rgba(251, 176, 52, 0.1) !important;
}

.transparent-mode .shadow-info {
  box-shadow: 0 7px 30px rgba(40, 146, 235, 0.5) !important;
}

.transparent-mode .shadow-success {
  box-shadow: 0 7px 30px rgba(26, 122, 16, 0.1) !important;
}

.transparent-mode .shadow-danger {
  box-shadow: 0 7px 30px rgba(245, 167, 184, 0.1) !important;
}

.transparent-mode .google-plus1 {
  background: linear-gradient(to right bottom, #dd4b39 0%, #ef6a5a 100%);
}

.transparent-mode .pinterest1 {
  background: linear-gradient(to right bottom, #bd081c 0%, #eb4553 100%);
}

.transparent-mode .bg-default {
  background: #e9e9f1;
  color: #282f53;
}

.transparent-mode .border {
  border-color: rgba(255, 255, 255, 0.2) !important;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .border-top {
  border-top-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .border-end {
  border-right-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .border-bottom {
  border-bottom-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .border-start {
  border-left-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .border-secondary {
  border: 1px solid #05c3fb !important;
}

.transparent-mode .border-success {
  border-color: #1bbfa7 !important;
}

.transparent-mode .border-info {
  border-color: #45aaf2 !important;
}

.transparent-mode .border-warning {
  border-color: #ecb403 !important;
}

.transparent-mode .border-danger {
  border-color: #f82649 !important;
}

.transparent-mode .border-pink {
  border-color: #fc5296 !important;
}

.transparent-mode .border-orange {
  border-color: #fc7303 !important;
}

.transparent-mode .border-light {
  border-color: #f8f9fa !important;
}

.transparent-mode .border-transparent {
  border-color: #656a71 !important;
}

.transparent-mode .transparent-border {
  border-color: #343a40 !important;
}

.transparent-mode .border-white {
  border-color: #fff !important;
}

.transparent-mode .border-end-1 {
  border-right-color: #d5dce3;
}

.transparent-mode .text-white-transparent,
.transparent-mode .text-white-transparent-1 {
  color: #fff !important;
}

.transparent-mode a.text-primary:hover,
.transparent-mode a.text-primary:focus {
  color: var(--primary-bg-color) !important;
}

.transparent-mode .text-secondary {
  color: #05c3fb !important;
}

.transparent-mode a.text-secondary:hover,
.transparent-mode a.text-secondary:focus {
  color: #05c3fb !important;
}

.transparent-mode .text-success {
  color: #09ad95 !important;
}

.transparent-mode a.text-success:hover,
.transparent-mode a.text-success:focus {
  color: #448700 !important;
}

.transparent-mode .text-info {
  color: #538ed7 !important;
}

.transparent-mode a.text-info:hover,
.transparent-mode a.text-info:focus {
  color: #1594ef !important;
}

.transparent-mode .alert-info {
  color: #538ed7 !important;
}

.transparent-mode .alert-info .alert-link {
  color: #79b6ff;
}

.transparent-mode .text-warning {
  color: #f7b731 !important;
}

.transparent-mode a.text-warning:hover,
.transparent-mode a.text-warning:focus {
  color: #c29d0b !important;
}

.transparent-mode .text-danger {
  color: #e82646 !important;
}

.transparent-mode a.text-danger:hover,
.transparent-mode a.text-danger:focus {
  color: #d22827 !important;
}

.transparent-mode .text-light {
  color: #ebedef !important;
}

.transparent-mode a.text-light:hover,
.transparent-mode a.text-light:focus {
  color: #dae0e5 !important;
}

.transparent-mode .text-dark {
  color: #fff !important;
}

.transparent-mode a.text-dark:hover,
.transparent-mode a.text-dark:focus {
  color: var(--primary-bg-color) !important;
}

.transparent-mode .text-body {
  color: #495057 !important;
}

.transparent-mode .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}

.transparent-mode .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.transparent-mode .text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.transparent-mode .text-hide {
  color: transparent;
  background-color: transparent;
}

.transparent-mode .heading-inverse {
  background-color: #333;
  color: #fff;
}

.transparent-mode .heading-success {
  background-color: #1643a3;
  color: #fff;
}

.transparent-mode .heading-info {
  background-color: #45aaf2;
  color: #fff;
}

.transparent-mode .heading-warning {
  background-color: #ecb403;
  color: #fff;
}

.transparent-mode .heading-danger {
  background-color: #c21a1a;
  color: #fff;
}

.transparent-mode .text-inherit {
  color: inherit !important;
}

.transparent-mode .text-default {
  color: #fff !important;
}

.transparent-mode .text-muted-dark {
  color: #1c232f !important;
}

.transparent-mode .text-fb-blue {
  color: #234684 !important;
}

.transparent-mode .text-blue {
  color: #467fcf !important;
}

.transparent-mode .text-indigo {
  color: #6574cd !important;
}

.transparent-mode .text-purple {
  color: #867efc !important;
}

.transparent-mode .text-lightpink-red {
  color: #ff7088 !important;
}

.transparent-mode .text-lightgreen {
  color: #26eda2 !important;
}

.transparent-mode .text-pink {
  color: #ec82ef !important;
}

.transparent-mode .text-red {
  color: #c21a1a !important;
}

.transparent-mode .text-orange {
  color: #fc7303 !important;
}

.transparent-mode .text-yellow {
  color: #ecb403 !important;
}

.transparent-mode .text-green {
  color: #4ecc48 !important;
}

.transparent-mode .text-green-1 {
  color: #0dff01 !important;
}

.transparent-mode .text-teal {
  color: #2bcbba !important;
}

.transparent-mode .text-cyan {
  color: #17a2b8 !important;
}

.transparent-mode .text-white {
  color: #fff !important;
}

.transparent-mode .text-gray {
  color: #969696 !important;
}

.transparent-mode .text-gray-dark {
  color: #343a40 !important;
}

.transparent-mode .text-azure {
  color: #45aaf2 !important;
}

.transparent-mode .text-lime {
  color: #7bd235 !important;
}

.transparent-mode .text-transparent {
  color: #332525;
}

.transparent-mode .text-facebook {
  color: #3b5998;
}

.transparent-mode .text-google-plus {
  color: #dd4b39;
}

.transparent-mode .text-twitter {
  color: #1da1f2;
}

.transparent-mode .text-pinterest {
  color: #bd081c;
}

.transparent-mode .text-secondary-gradient {
  background: linear-gradient(to bottom right, #82cff2 0%, #28b7f9 100%);
  -webkit-text-fill-color: transparent;
  background: linear-gradient(to right, #cd489c 0%, #ce4ba4 100%);
  -webkit-text-fill-color: transparent;
}

.transparent-mode .text-info-gradient {
  background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
  -webkit-text-fill-color: transparent;
}

.transparent-mode .text-success-gradient {
  background: linear-gradient(to bottom right, #1ea38f 0%, #5cf9e2 100%);
  -webkit-text-fill-color: transparent;
}

.transparent-mode .text-facebook-gradient {
  background: linear-gradient(to right bottom, #1e3c72 0%, #3d6cbf 100%);
  -webkit-text-fill-color: transparent;
}

.transparent-mode .text-twitter-gradient {
  background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
  -webkit-text-fill-color: transparent;
}

.transparent-mode .text-google-plus-gradient {
  background: linear-gradient(to right bottom, #dd4b39 0%, #ef6a5a 100%);
  -webkit-text-fill-color: transparent;
}

.transparent-mode .text-success-shadow {
  text-shadow: 0 5px 10px rgba(19, 191, 166, 0.3) !important;
}

.transparent-mode .text-info-shadow {
  text-shadow: 0 5px 10px rgba(7, 116, 248, 0.3) !important;
}

.transparent-mode .text-warning-shadow {
  text-shadow: 0 5px 10px rgba(255, 162, 43, 0.3) !important;
}

.transparent-mode .text-danger-shadow {
  text-shadow: 0 5px 10px rgba(255, 56, 43, 0.3) !important;
}

.transparent-mode .text-pink-shadow {
  text-shadow: 0 5px 10px rgba(213, 109, 252, 0.3) !important;
}

.transparent-mode .text-purple-shadow {
  text-shadow: 0 5px 10px rgba(96, 77, 216, 0.3) !important;
}

.transparent-mode .text-dark-shadow {
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
}

.transparent-mode .text-white-shadow {
  text-shadow: 0 5px 10px rgba(255, 255, 255, 0.3) !important;
}

.transparent-mode .text-secondary-shadow {
  text-shadow: 0 5px 10px rgba(130, 207, 242, 0.3);
}

.transparent-mode .alert-success hr {
  border-top-color: #09ad95;
}

.transparent-mode .alert-info hr {
  border-top-color: #1170e4;
}

.transparent-mode .alert-warning hr {
  border-top-color: #f7b731;
}

.transparent-mode .alert-danger hr {
  border-top-color: #e82646;
}

.transparent-mode .sidebar {
  background: var(--primary-bg-color);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 8px 14.72px 1.28px rgba(42, 38, 53, 0.5);
}

.transparent-mode .light-layout {
  display: none;
}

.transparent-mode .dark-layout {
  display: block;
}

.transparent-mode .slide.is-expanded a {
  color: rgba(255, 255, 255, 0.8);
}

.transparent-mode
  .select2-container--default
  .select2-results__option[aria-selected='true'] {
  background-color: #30304d;
}

.transparent-mode
  .select2-container--default
  .select2-results
  > .select2-results__options {
  box-shadow: 0px 8px 14.72px 1.28px rgba(34, 34, 61, 0.8);
}

.transparent-mode .select2-dropdown {
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.1);
}

.transparent-mode .side-header .header-brand-img.desktop-logo {
  display: block !important;
}

.transparent-mode .side-header .header-brand-img.light-logo1 {
  display: none !important;
}

.transparent-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.light-logo1 {
  display: none !important;
}

.transparent-mode .header-brand .header-brand-img.logo-3 {
  display: none;
}

.transparent-mode .header-brand .header-brand-img.logo {
  display: block;
}

.transparent-mode.sidebar-mini .app-header .light-logo1 {
  display: none !important;
}

.transparent-mode.sidenav-toggled .header-brand-img.light-logo {
  display: none !important;
}

.transparent-mode.hover-submenu.sidenav-toggled.sidenav-toggled-open
  .header-brand-img.light-logo {
  display: none !important;
}

.transparent-mode.sidebar-mini.sidenav-toggled
  .app-sidebar
  .side-header
  .header-brand-img.desktop-logo {
  display: none !important;
}

.transparent-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.desktop-logo {
  display: block !important;
}

.transparent-mode.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.desktop-logo {
  display: none !important;
}

.transparent-mode.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.desktop-logo {
  display: none !important;
}

.transparent-mode.sidebar-mini.sidenav-toggled
  .app-sidebar
  .side-header
  .header-brand-img.toggle-logo {
  display: block;
}

.transparent-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.toggle-logo {
  display: none;
}

.transparent-mode.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.toggle-logo {
  display: block;
}

.transparent-mode.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open
  .side-header
  .header-brand-img.light-logo {
  display: none !important;
}

.transparent-mode.hover-submenu.sidebar-mini.sidenav-toggled.sidenav-toggled-open
  .app-sidebar
  .side-header
  .header-brand-img.toggle-logo {
  display: block;
}

.transparent-mode.hover-submenu1.sidebar-mini.sidenav-toggled.sidenav-toggled-open
  .side-header
  .header-brand-img.light-logo {
  display: none !important;
}

@media (max-width: 991px) {
  .transparent-mode .app-header.header .header-brand-img.desktop-logo {
    display: block;
  }
}

.transparent-mode #external-events {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .fc-theme-standard td,
.transparent-mode .fc-theme-standard th {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .fc-theme-standard .fc-scrollgrid {
  border: 0px solid rgba(255, 255, 255, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.transparent-mode .fc .fc-daygrid-day-number {
  color: #fff;
}

.transparent-mode .fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .fc-theme-standard .fc-list {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .fc-theme-standard .fc-list-day-cushion {
  background: var(--transparent-body);
}

.transparent-mode .fc .fc-list-event:hover td {
  background: var(--transparent-body);
}

.transparent-mode .fc-list-event.fc-event {
  color: #fff !important;
}

.transparent-mode .fc-direction-ltr .fc-list-day-text,
.transparent-mode .fc-direction-rtl .fc-list-day-side-text {
  color: #fff;
}

.transparent-mode .fc-direction-ltr .fc-list-day-side-text,
.transparent-mode .fc-direction-rtl .fc-list-day-text {
  color: #fff;
}

.transparent-mode .sweet-alert {
  background: var(--transparent-body);
}

.transparent-mode .sweet-alert h2 {
  color: #fff;
}

.transparent-mode .sweet-alert P {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .alert-default {
  background-color: var(--transparent-body);
}

.transparent-mode .irs-line-mid,
.transparent-mode .irs-line-right,
.transparent-mode .irs-line-left {
  background-color: var(--transparent-body);
}

.transparent-mode .irs-from,
.transparent-mode .irs-to,
.transparent-mode .irs-single {
  background: var(--transparent-body);
}

.transparent-mode .irs-from,
.transparent-mode .irs-to,
.transparent-mode .irs-single {
  color: #fff;
}

.transparent-mode .irs-min,
.transparent-mode .irs-max {
  color: #fff;
  background: var(--transparent-body);
}

.transparent-mode .irs-grid-text {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .irs-modern .irs-slider,
.transparent-mode .irs-outline .irs-slider {
  background-color: var(--transparent-body);
}

.transparent-mode .irs-bar {
  background: var(--primary-bg-color);
}

.transparent-mode .irs-slider:before {
  background-color: var(--primary-bg-color);
}

.transparent-mode .irs-outline .irs-line {
  border-color: var(--primary-bg-color);
}

.transparent-mode .tree li {
  color: #fff;
}

.transparent-mode .tree li a {
  color: #fff;
}

.transparent-mode .tree li.branch li {
  background: transparent;
}

.transparent-mode .tree ul:before {
  border-left-color: rgba(255, 255, 255, 0.4);
}

.transparent-mode .tree ul li:before {
  border-top-color: rgba(255, 255, 255, 0.4);
}

.transparent-mode .dTree a {
  color: #fff;
}

.transparent-mode .tab_wrapper .content_wrapper {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tab_wrapper .content_wrapper .accordian_header {
  border-bottom-color: rgba(255, 255, 255, 0.2);
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tab_wrapper .content_wrapper .accordian_header .arrow {
  background: transparent;
  border-top-color: rgba(255, 255, 255, 0.3);
  border-left-color: rgba(255, 255, 255, 0.3);
}

.transparent-mode .tab_wrapper .content_wrapper .accordian_header.active {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tab_wrapper.right_side .content_wrapper {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tab_wrapper.right_side > ul li {
  border-left-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tab_wrapper.right_side > ul li.active {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tab_wrapper.right_side > ul li.active::before {
  background: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tab_wrapper.right_side > ul li:after {
  background: rgba(255, 255, 255, 0.1);
}

.transparent-mode .tab_wrapper > ul {
  border-bottom-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .tab_wrapper > ul li {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tab_wrapper > ul li.active:after {
  background: transparent;
}

.transparent-mode .dropify-wrapper {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .dropify-wrapper .dropify-preview {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .dropify-wrapper .dropify-message span.file-icon {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .dropify-wrapper:hover {
  background-image: linear-gradient(
    -45deg,
    var(--transparent-body) 25%,
    transparent 25%,
    transparent 50%,
    var(--transparent-body) 50%,
    var(--transparent-body) 75%,
    transparent 75%,
    transparent
  );
}

.transparent-mode .ff_fileupload_wrap .ff_fileupload_dropzone:focus,
.transparent-mode .ff_fileupload_wrap .ff_fileupload_dropzone:active {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .ff_fileupload_wrap .ff_fileupload_dropzone {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .ff_fileupload_wrap .ff_fileupload_dropzone:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .sp-replacer {
  border-color: rgba(255, 255, 255, 0.2);
  background: var(--transparent-body);
  color: #fff;
}

.transparent-mode .sp-replacer:hover,
.transparent-mode .sp-replacer.sp-active {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.transparent-mode .sp-container {
  background: var(--transparent-body);
  box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .sp-picker-container {
  border-left: 0;
}

.transparent-mode .ui-timepicker-wrapper {
  background: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.07);
  box-shadow: 0 16px 18px 0 #0e0f2e;
}

.transparent-mode .ui-timepicker-list li {
  color: rgba(255, 255, 255, 0.8);
}

.transparent-mode .datepicker .datepicker-switch,
.transparent-mode .datepicker td,
.transparent-mode .datepicker th {
  color: #fff !important;
}

.transparent-mode .datepicker .datepicker-switch:hover,
.transparent-mode .datepicker .prev:hover,
.transparent-mode .datepicker .next:hover,
.transparent-mode .datepicker tfoot tr th:hover {
  color: rgba(255, 255, 255, 0.7) !important;
}

.transparent-mode .datepicker-dropdown.datepicker-orient-top:after,
.transparent-mode .datepicker-dropdown.datepicker-orient-top:before {
  border-top-color: var(--transparent-body);
}

.transparent-mode .datepicker table tr td span.old,
.transparent-mode .datepicker table tr td span.new {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .SumoSelect > .CaptionCont {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .SumoSelect.disabled > .CaptionCont {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.transparent-mode .SumoSelect.open > .optWrapper {
  background: var(--transparent-body);
}

.transparent-mode .SumoSelect > .optWrapper {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .SumoSelect > .optWrapper > .options li.opt {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .SumoSelect > .optWrapper > .options li.opt:hover {
  background-color: #262641;
}

.transparent-mode .SumoSelect.open .search-txt {
  background: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .SumoSelect .select-all {
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .SumoSelect > .optWrapper > .MultiControls {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode
  .SumoSelect
  > .optWrapper.multiple
  > .MultiControls
  > p:hover {
  background-color: #393958;
}

.transparent-mode datepicker-dropdown.datepicker-orient-top:after,
.transparent-mode datepicker-dropdown.datepicker-orient-top:before {
  border-top: 7px solid #3e3e50;
}

.transparent-mode datepicker-dropdown:after,
.transparent-mode datepicker-dropdown:before {
  border-bottom-color: #2e2e4a;
}

.transparent-mode .datepicker table tr td span:hover,
.transparent-mode .datepicker table tr td span.focused {
  background: rgba(0, 0, 0, 0.2) !important;
  color: #dedefd !important;
}

.transparent-mode .datepicker .prev,
.transparent-mode .datepicker .next {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .datepicker .prev:hover,
.transparent-mode .datepicker .next:hover {
  background: rgba(0, 0, 0, 0.2) !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.transparent-mode .datepicker-dropdown:after {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .datepicker-dropdown::before {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .ms-choice {
  color: rgba(255, 255, 255, 0.7);
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .ms-choice.disabled {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .ms-drop {
  color: rgba(255, 255, 255, 0.7);
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .ms-drop.bottom {
  box-shadow: 0px 8px 14.72px 1.28px rgba(34, 34, 61, 0.8);
}

.transparent-mode .select2-dropdown {
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode
  .select2-container--default
  .select2-results__option[aria-selected='true'] {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode
  .select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .ms-search input {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .transfer-double {
  background-color: transparent;
}

.transparent-mode .transfer-double-content-tabs {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .transfer-double-content-tabs .tab-item-name.tab-active {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .transfer-double-content-left,
.transparent-mode .transfer-double-content-right {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .transfer-double-list-footer {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .transfer-double-list-search-input {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .transfer-double-list-main::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .checkbox-group label:before {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .transfer-double-content-param {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .transfer-double-selected-list-search-input {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .btn-select-arrow {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .multi-wrapper {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .multi-wrapper .search-input {
  border-bottom-color: rgba(255, 255, 255, 0.2);
  background: transparent;
}

.transparent-mode .multi-wrapper .item-1 {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .multi-wrapper .selected-wrapper,
.transparent-mode .multi-wrapper .item-group-1 .group-label {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .multi-wrapper .non-selected-wrapper {
  background: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .iti input {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.transparent-mode .iti input[type='tel'],
.transparent-mode .iti input[type='text'] {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.transparent-mode .iti__selected-flag {
  border-right-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode
  .iti--allow-dropdown
  .iti__flag-container:hover
  .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.2);
  border-top-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .iti__country-list {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: var(--transparent-body);
  border-right-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .iti__divider {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .note-editor.note-frame.panel.panel-default .panel-heading {
  background-color: transparent;
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode
  .note-editor.note-frame.panel.panel-default.fullscreen
  .panel-heading.note-toolbar {
  background-color: var(--transparent-body);
}

.transparent-mode .note-btn.btn-default {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.transparent-mode .panel-default.fullscreen .note-editable {
  background-color: var(--transparent-body);
}

.transparent-mode .note-editor.note-airframe,
.transparent-mode .note-editor.note-frame {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode
  .note-editor
  .btn-default:not(:disabled):not(.disabled):active {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.transparent-mode .form-control-file::-webkit-file-upload-button {
  background-color: var(--transparent-body);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .note-editor.note-airframe .note-editing-area .note-codable,
.transparent-mode .note-editor.note-frame .note-editing-area .note-codable {
  background-color: var(--transparent-body);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .richText .richText-editor {
  background-color: transparent;
  border-left-color: rgba(255, 255, 255, 0);
}

.transparent-mode .richText .richText-toolbar ul {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .richText .richText-toolbar ul li a {
  color: #fff;
}

.transparent-mode .richText .richText-toolbar ul li a:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode
  .richText
  .richText-toolbar
  ul
  li
  a
  .richText-dropdown-outer
  .richText-dropdown {
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode
  .richText
  .richText-toolbar
  ul
  li
  a
  .richText-dropdown-outer
  .richText-dropdown
  .richText-dropdown-close {
  background: var(--transparent-body);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .richText .richText-form input[type='text'],
.transparent-mode .richText .richText-form input[type='file'],
.transparent-mode .richText .richText-form input[type='number'] {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.transparent-mode .richText .richText-form select {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.transparent-mode
  .richText
  .richText-toolbar
  ul
  li
  a
  .richText-dropdown-outer
  ul.richText-dropdown
  li
  a {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode
  .richText
  .richText-toolbar
  ul
  li
  a
  .richText-dropdown-outer
  ul.richText-dropdown
  li
  a:hover {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .richText .richText-undo,
.transparent-mode .richText .richText-redo {
  border-right-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .ql-toolbar.ql-snow {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .ql-toolbar.ql-snow .ql-picker-label {
  border-color: rgba(255, 255, 255, 0.2);
  background: var(--transparent-body);
  color: #fff;
}

.transparent-mode .ql-container.ql-snow {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .ql-snow.ql-toolbar button,
.transparent-mode .ql-snow .ql-toolbar button {
  border-color: rgba(255, 255, 255, 0.2);
  background: var(--transparent-body);
  color: #fff;
}

.transparent-mode .ql-snow.ql-toolbar button:last-child,
.transparent-mode .ql-snow .ql-toolbar button:last-child {
  border-right-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options {
  background-color: var(--transparent-body);
}

.transparent-mode
  .ql-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .ql-snow .ql-formats {
  color: #fff;
}

.transparent-mode .ql-snow .ql-tooltip {
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  margin-left: 100px;
}

.transparent-mode .ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.5) !important;
}

.transparent-mode .ql-snow.ql-toolbar button:hover,
.transparent-mode .ql-snow .ql-toolbar button:hover,
.transparent-mode .ql-snow.ql-toolbar button:focus,
.transparent-mode .ql-snow .ql-toolbar button:focus,
.transparent-mode .ql-snow.ql-toolbar button.ql-active,
.transparent-mode .ql-snow .ql-toolbar button.ql-active,
.transparent-mode .ql-snow.ql-toolbar .ql-picker-label:hover,
.transparent-mode .ql-snow .ql-toolbar .ql-picker-label:hover,
.transparent-mode .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.transparent-mode .ql-snow .ql-toolbar .ql-picker-label.ql-active,
.transparent-mode .ql-snow.ql-toolbar .ql-picker-item:hover,
.transparent-mode .ql-snow .ql-toolbar .ql-picker-item:hover,
.transparent-mode .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.transparent-mode .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .ql-tooltip.ql-editing input[type='text'],
.transparent-mode .ql-tooltip.ql-editing input[type='file'],
.transparent-mode .ql-tooltip.ql-editing input[type='number'] {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.transparent-mode .ql-bubble .ql-tooltip {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .sw-theme-dots > ul.step-anchor {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .sw-theme-dots > ul.step-anchor:before {
  background-color: var(--transparent-body);
}

.transparent-mode .sw-theme-dots > ul.step-anchor > li > a:before {
  background: var(--transparent-body);
}

.transparent-mode .sw-theme-dots > ul.step-anchor > li.active > a::after {
  background: rgba(255, 255, 255, 0.7);
}

.transparent-mode .sw-theme-dots > ul.step-anchor > li.done > a {
  color: #fff;
}

.transparent-mode .sw-theme-dots .step-content {
  background-color: transparent;
}

.transparent-mode .sw-theme-dots .sw-toolbar {
  background: transparent;
}

.transparent-mode .wizard {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
  border-radius: 3px;
}

.transparent-mode .wizard > .content {
  border-top-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .wizard > .content > .title {
  color: #dedefd;
}

.transparent-mode .wizard > .actions .disabled a {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.62);
}

.transparent-mode .wizard > .steps .current a .number,
.transparent-mode .wizard > .steps .current a:active .number,
.transparent-mode .wizard > .steps .current a:hover .number {
  background-color: var(--primary-bg-color);
}

.transparent-mode .wizard > .steps a .number,
.transparent-mode .wizard > .steps a:active .number,
.transparent-mode .wizard > .steps a:hover .number {
  background-color: rgba(255, 255, 255, 0.1);
}

.transparent-mode .wizard.vertical > .content,
.transparent-mode .wizard.vertical > .actions {
  border-left-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .wizard > .steps .done a .number,
.transparent-mode .wizard > .steps .done a:active .number,
.transparent-mode .wizard > .steps .done a:hover .number {
  background-color: #0dcd94;
}

.transparent-mode .wizard > .steps a .number,
.transparent-mode .wizard > .steps a:active .number,
.transparent-mode .wizard > .steps a:hover .number {
  background-color: rgba(255, 255, 255, 0.1);
}

.transparent-mode .owl-nav button {
  background: var(--transparent-body) !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .flot-text {
  color: rgba(255, 255, 255, 0.7) !important;
}

.transparent-mode tspan {
  fill: rgba(255, 255, 255, 0.7) !important;
}

.transparent-mode .nvd3 text {
  fill: rgba(255, 255, 255, 0.7);
}

.transparent-mode .nvd3 .nv-axis line {
  stroke: rgba(119, 119, 142, 0.2);
}

.transparent-mode .nvd3 .nv-discretebar .nv-groups text,
.transparent-mode .nvd3 .nv-multibarHorizontal .nv-groups text {
  fill: #fff;
}

.transparent-mode .countdown li {
  background: var(--transparent-body);
  border: 5px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}

.transparent-mode .app-sidebar.sidemenu-scroll .side-header {
  background: var(--primary-bg-color) !important;
}

.transparent-mode .bg-recentorder:before {
  background: none;
}

.rtl.transparent-mode .app-sidebar {
  border-left-color: rgba(255, 255, 255, 0.1);
}

.rtl.transparent-mode .side-header {
  border-left-color: rgba(255, 255, 255, 0.1);
}

.rtl.transparent-mode .vtimeline .timeline-wrapper .timeline-panel:after {
  border-right: 10px solid rgba(255, 255, 255, 0.1);
  border-left: 0 solid rgba(255, 255, 255, 0.1);
}

.rtl.transparent-mode
  .vtimeline
  .timeline-wrapper.timeline-inverted
  .timeline-panel:after {
  border-right: 0 !important;
  border-left: 10px solid rgba(255, 255, 255, 0.1) !important;
  right: -10px !important;
  left: auto;
}

.rtl.transparent-mode .notification .notification-body:before {
  border-left-color: rgba(0, 0, 0, 0.2) !important;
  border-right-color: transparent !important;
}

.rtl.transparent-mode .border-end {
  border-left-color: rgba(255, 255, 255, 0.1) !important;
  border-right: transparent !important;
}

.rtl.transparent-mode .main-content-body-chat .border-start-0 {
  border-left-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .buttons-columnVisibility.dropdown-item:hover,
.transparent-mode .buttons-columnVisibility.dropdown-item:focus,
.transparent-mode .buttons-columnVisibility.dropdown-item.active,
.transparent-mode .buttons-columnVisibility.dropdown-item:active {
  color: #fff;
}

.transparent-mode .dataTables_wrapper .selected {
  background: var(--transparent-body);
}

.transparent-mode .dropdown-item:hover,
.transparent-mode .dropdown-item:focus,
.transparent-mode .dropdown-item.active,
.transparent-mode .dropdown-item:active {
  color: #fff;
}

.transparent-mode .list-group-item a {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .container-login100 .text-primary {
  color: rgba(255, 255, 255, 0.6) !important;
}

.transparent-mode .container-login100 .tab-content i,
.transparent-mode .container-login100 .tabs-menu2 ul li .active {
  color: #fff;
}

.transparent-mode .container-login100 .social-login i {
  color: #fff;
}

.transparent-mode .container-login100 .validate-form.login100-form a {
  color: rgb(255, 255, 255) !important;
}

@media (max-width: 991px) {
  .transparent-mode.sidebar-gone.sidenav-toggled.sidebar-mini .side-menu {
    margin-top: 0px !important;
  }

  .transparent-mode .navresponsive-toggler span {
    color: #fff !important;
  }
}

@media (max-width: 991px) {
  .transparent-mode.app.light-menu .app-sidebar {
    top: 74px !important;
  }

  .transparent-mode.app.color-menu .app-sidebar {
    top: 74px !important;
  }

  .transparent-mode.app.gradient-menu .app-sidebar {
    top: 74px !important;
  }

  .transparent-mode.app.dark-menu .app-sidebar {
    top: 74px !important;
  }
}

.transparent-mode.light-menu .hor-header.header {
  border-bottom-color: rgba(233, 237, 244, 0.2);
}

@media (max-width: 991px) {
  .transparent-mode.header-light .navresponsive-toggler span {
    color: var(--primary-bg-color) !important;
  }
}

.transparent-mode .onoffswitch2-label {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: var(--transparent-body);
}

.transparent-mode .onoffswitch2-label:before {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .theme-layout.nav-link-bg.layout-setting {
  display: none;
}

@media (max-width: 991px) {
  .app.sidebar-mini.transparent-mode.header-light
    .side-header
    .header-brand-img.light-logo1 {
    display: none !important;
  }

  .app.sidebar-mini.transparent-mode.header-light
    .side-header
    .header-brand-img.desktop-logo {
    display: block !important;
  }

  .app.sidebar-mini.transparent-mode.light-menu.header-light
    .side-header
    .header-brand-img.light-logo1 {
    display: block !important;
  }

  .app.sidebar-mini.transparent-mode.light-menu.header-light
    .side-header
    .header-brand-img.desktop-logo {
    display: none !important;
  }

  .app.sidebar-mini.sidebar-gone.transparent-mode.light-menu.header-light.sidenav-toggled
    .side-header
    .header-brand-img.light-logo1 {
    display: none !important;
  }
}

.transparent-mode .fc-theme-standard .fc-popover {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: var(--transparent-body);
}

.transparent-mode .fc-theme-standard .fc-popover-header {
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .card-aside.color-card-radius .card-header {
  border-bottom-color: transparent;
}

.transparent-mode
  .ff_fileupload_wrap
  table.ff_fileupload_uploads
  td.ff_fileupload_summary
  .ff_fileupload_filename
  input {
  background: transparent;
  color: #fff;
}

.transparent-mode
  .ff_fileupload_wrap
  table.ff_fileupload_uploads
  td.ff_fileupload_preview
  .ff_fileupload_preview_image {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode
  .ff_fileupload_wrap
  table.ff_fileupload_uploads
  td.ff_fileupload_actions
  button {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .dropify-wrapper .dropify-preview .dropify-render img {
  background-color: transparent;
}

.transparent-mode.rtl .table.border-dashed thead th:last-child {
  border-left-color: transparent;
}

.transparent-mode.rtl .table thead th:last-child {
  border-left-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .fc .fc-list-event:hover td {
  color: #fff;
}

.transparent-mode #navbar-example2 .nav-link {
  color: #fff;
}

.transparent-mode.icontext-menu .side-menu__item:hover .side-menu__icon,
.transparent-mode.icontext-menu .side-menu__item:hover .side-menu__label,
.transparent-mode.icontext-menu .side-menu__item:focus .side-menu__icon,
.transparent-mode.icontext-menu .side-menu__item:focus .side-menu__label {
  color: #fff !important;
}

.transparent-mode.hover-submenu .slide-menu a.active {
  color: #fff;
}

.transparent-mode.hover-submenu .slide-menu a.active .sub-side-menu__label {
  color: #fff;
}

.transparent-mode.hover-submenu1 .slide-menu a.active {
  color: #fff;
}

.transparent-mode.hover-submenu1 .slide-menu a.active .sub-side-menu__label {
  color: #fff;
}

.transparent-mode.horizontal .slide-menu a.active {
  color: #fff;
}

.transparent-mode.horizontal .slide-menu a.active .sub-side-menu__label {
  color: #fff;
}

.transparent-mode.hover-submenu.sidenav-toggled .side-menu,
.transparent-mode.hover-submenu1.sidenav-toggled .side-menu {
  height: 100vh;
}

.transparent-mode.hover-submenu .side-menu,
.transparent-mode.hover-submenu1 .side-menu {
  height: 150vh;
}

.transparent-mode .qty:focus {
  background: transparent;
}

.transparent-mode .card-transparent.bg-primary-transparent,
.transparent-mode .card-transparent.bg-secondary-transparent,
.transparent-mode .card-transparent.bg-info-transparent,
.transparent-mode .card-transparent.bg-success-transparent,
.transparent-mode .card-transparent.bg-warning-transparent,
.transparent-mode .card-transparent.bg-danger-transparent {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode .btn-primary-light {
  color: #fff;
  background: var(--primary06) !important;
  border-color: var(--primary06) !important;
}

.transparent-mode .multi-wrapper .search-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

@media screen and (max-width: 991px) {
  .transparent-mode.horizontal .horizontal-main .slide .slide-menu,
  .transparent-mode.horizontal .horizontal-main .slide .sub-slide-menu,
  .transparent-mode.horizontal .horizontal-main .slide .sub-slide-menu2 {
    box-shadow: none !important;
  }
}

.transparent-mode .list-group-item a {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .nav-pills .nav-link.active,
.transparent-mode .nav-pills .show > .nav-link {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .nav.nav-pills .nav-item .nav-link {
  color: #fff !important;
}

.transparent-mode
  .data-table-extensions
  > .data-table-extensions-filter
  > .filter-text {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .nav-tabs .nav-item.show .nav-link,
.transparent-mode .nav-tabs .nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .table-border {
  border-color: rgba(255, 255, 255, 0.2) !important;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.transparent-mode .fc-theme-standard td,
.transparent-mode .fc-theme-standard th,
.transparent-mode .fc-theme-standard .fc-scrollgrid {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .swal2-popup {
  background: var(--transparent-body);
  color: #fff;
}

.transparent-mode .alert-dismissible .btn-close {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .rodal-dialog {
  background: var(--transparent-body);
}

.transparent-mode .panel.panel-tabs-boxed button.nav-link.active,
.transparent-mode .panel.panel-tabs-border button.nav-link.active,
.transparent-mode .tab_wrapper.right_side > .nav-item a {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .tab_wrapper.right_side > .nav-item:last-child a {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .dropzone {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .MuiDropzoneArea-root {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .Select-sm__control,
.transparent-mode .Select-lg__control,
.transparent-mode .Select2 {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .w-color-compact {
  background: rgba(0, 0, 0, 0.2) !important;
}

.transparent-mode .rmsc.multi-select .dropdown-container,
.transparent-mode .rdl-actions .rdl-move,
.transparent-mode .select__menu,
.transparent-mode #react-select-2-listbox,
.transparent-mode #react-select-3-listbox,
.transparent-mode #react-select-5-listbox {
  background: var(--transparent-body) !important;
}

.transparent-mode .rmsc.multi-select .dropdown-container,
.transparent-mode .rdl-actions .rdl-move,
.transparent-mode .CountrySelector .react-tel-input .flag-dropdown {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .rdl-control-container .rdl-filter,
.transparent-mode .rdl-control-container .rdl-control,
.transparent-mode .rdl-filter-container .rdl-filter,
.transparent-mode .rdl-filter-container .rdl-control,
.transparent-mode .react-tel-input .country-list,
.transparent-mode .react-tel-input .flag-dropdown.open .selected-flag,
.transparent-mode .react-tel-input .selected-flag:hover,
.transparent-mode .react-tel-input .selected-flag:focus {
  border-color: rgba(255, 255, 255, 0.2);
  background: var(--transparent-body);
  color: #fff;
}

.transparent-mode .react-tel-input .country-list .country.highlight,
.transparent-mode .react-tel-input .country-list .country:hover,
.transparent-mode .borders {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .rdw-editor-toolbar,
.transparent-mode .rdw-dropdown-wrapper {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .rdw-dropdown-wrapper,
.transparent-mode .rdw-option-wrapper {
  border-color: rgba(255, 255, 255, 0.2);
  background: var(--transparent-body);
}

.transparent-mode .border-right {
  border-right-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .slider-carousel .slick-slide.slick-active.slick-current,
.transparent-mode .slider-carousel .slick-dots li {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode svg g text,
.transparent-mode .apexcharts-legend-text {
  fill: rgba(255, 255, 255, 0.7) !important;
  color: rgba(255, 255, 255, 0.7) !important;
}

.transparent-mode .apexcharts-menu,
.transparent-mode .apexcharts-theme-light .apexcharts-menu-item:hover {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 991px) {
  .transparent-mode .navbar-light .navbar-toggler {
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

.transparent-mode .tabs-menu .nav.nav-pills {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode.rtl
  .vtimeline
  .timeline-wrapper.timeline-inverted
  .timeline-panel:after {
  border-right-width: 0;
  border-left-width: 10px;
}

.transparent-mode tbody,
.transparent-mode td,
.transparent-mode tfoot,
.transparent-mode th,
.transparent-mode thead,
.transparent-mode tr {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .panel-heading1 {
  color: rgba(255, 255, 255, 0.8) !important;
}

.transparent-mode .react-tel-input .selected-flag .arrow.up {
  border-bottom-color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .react-tel-input .country-list .country.highlight {
  background-color: var(--primary-bg-color) !important;
}

.transparent-mode .react-tel-input .selected-flag .arrow {
  border-top-color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .react-tel-input .country-list .country .dial-code {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .react-tel-input .country-list .country:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .rdw-dropdown-carettoopen {
  border-left-color: transparent;
}

.transparent-mode .rdw-dropdown-optionwrapper:hover {
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .rdw-dropdown-optionwrapper,
.transparent-mode .rdw-embedded-modal,
.transparent-mode .rdw-image-modal,
.transparent-mode .rdw-emoji-modal,
.transparent-mode .rdw-colorpicker-modal {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: var(--transparent-body);
  box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.1);
}

.transparent-mode .rdw-dropdownoption-highlighted,
.transparent-mode .rdw-image-modal-upload-option {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .rdw-embedded-modal-btn,
.transparent-mode .rdw-image-modal-btn {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .ql-snow .ql-picker {
  color: rgba(255, 255, 255, 0.8);
}

.transparent-mode .handle-counter input {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.transparent-mode .rmsc.multi-select .dropdown-container,
.transparent-mode .rdl-actions .rdl-move {
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 991px) {
  .transparent-mode
    #navbarSupportedContent-4
    .nav-link.icon.no-caret.btn.btn-primary {
    color: #fff !important;
  }
}

.transparent-mode .rodal-close:before,
.transparent-mode .rodal-close:after {
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .rdw-dropdown-carettoopen {
  border-top-color: rgba(255, 255, 255, 0.6);
}

.transparent-mode .ql-snow .ql-fill,
.transparent-mode .ql-snow .ql-stroke.ql-fill {
  fill: rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.7);
}

.transparent-mode .ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.7) !important;
}

.transparent-mode.login-img.bg-img1::before,
.transparent-mode.login-img.bg-img2::before,
.transparent-mode.login-img.bg-img3::before,
.transparent-mode.login-img.bg-img4::before {
  background: transparent;
}

.transparent-mode .payment-info .nav-link i {
  color: #fff !important;
}

.transparent-mode .nav.nav-pricing {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.transparent-mode .nav-pricing.nav .nav-item a {
  background-color: transparent;
}

.transparent-mode .rdt_Table,
.transparent-mode .data-table-extensions > .data-table-extensions-filter {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .rdt_TableCell:not(:last-child) {
  border-right-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode #delete-datatable td input {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .Select-sm__option:hover,
.transparent-mode .Select-lg__option:hover,
.transparent-mode .select2 {
  background-color: rgba(0, 0, 0, 0.2) !important;
  color: #fff !important;
}

.transparent-mode .rdt_TableRow:hover {
  border-color: rgba(255, 255, 255, 0.2);
  outline: none;
}

.transparent-mode .MuiSlider-mark {
  color: #fff;
}

.transparent-mode .MuiTypography-root,
.transparent-mode .MuiInputLabel-root,
.transparent-mode .MuiOutlinedInput-root {
  color: #fff;
}

.transparent-mode .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .MuiPaper-root {
  background-color: var(--transparent-body);
  color: #fff;
}

.transparent-mode .MuiClockPicker-root span {
  color: #fff;
}

.transparent-mode .MuiButtonBase-root {
  background-color: var(--transparent-body);
  color: #fff;
}

.transparent-mode .w-color-editable-input input {
  color: rgba(255, 255, 255, 0.9);
}

.transparent-mode .w-color-editable-input span {
  color: #fff;
}

.transparent-mode .react-tel-input .form-control,
.transparent-mode .CountrySelector .react-tel-input .flag-dropdown {
  background-color: rgba(0, 0, 0, 0.2);
}

.transparent-mode .rdl-filter::-webkit-input-placeholder {
  color: #fff;
}

.transparent-mode .table-inbox a:hover {
  color: #fff !important;
}

.transparent-mode.sidebar-mini .fixed-header .side-header {
  backdrop-filter: blur(20px);
}

.transparent-mode.sidebar-mini
  .scrollbar-container.ps.ps-scrooling-y
  .side-header {
  backdrop-filter: blur(20px);
}

.transparent-mode .MuiSlider-colorPrimary,
.transparent-mode .MuiSlider-markLabel {
  color: #fff;
}

.transparent-mode .shadow-none .nav.nav-pills .nav-item .nav-link.active {
  color: #fff !important;
}

.transparent-mode .Select__control,
.transparent-mode .Select__menu,
.transparent-mode .Select-sm__control,
.transparent-mode .Select-lg__control,
.transparent-mode .Select-sm__menu,
.transparent-mode .Select-lg__menu {
  background-color: var(--transparent-body) !important;
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .select__menu-list {
  color: #fff;
  background: var(--transparent-body);
}

.transparent-mode .Select__option:hover {
  color: #fff;
  background: var(--transparent-body);
}

.transparent-mode
  .tabs-menu-body.description
  .nav-tabs
  .nav-item.show
  .nav-link,
.transparent-mode .tabs-menu-body.description .nav-tabs .nav-link.active {
  background-color: transparent;
}

.transparent-mode .tabs-menu-body.description .nav-link:focus,
.transparent-mode .tabs-menu-body.description .nav-link:hover {
  color: #fff !important;
}

.transparent-mode .multi-select .dropdown-content {
  background-color: var(--transparent-body);
}

.transparent-mode .multi-select .dropdown-content div {
  background-color: var(--transparent-body);
}

.transparent-mode .multi-select .dropdown-content div label:hover,
.transparent-mode .multi-select .dropdown-content div label.selected {
  background-color: rgba(255, 255, 255, 0.1);
}

.transparent-mode .rmsc .dropdown-container {
  background-color: var(--transparent-body);
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .rmsc .dropdown-content {
  background-color: var(--transparent-body) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode .rmsc .gray {
  color: #dedefd !important;
}

.transparent-mode .rmsc.multi-select .dropdown-content {
  background-color: var(--transparent-body) !important;
}

.transparent-mode .Select2__option {
  background-color: rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}

.transparent-mode .Select2__option:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px 3px 0 0;
}

.transparent-mode .Select2__option:last-child {
  border-radius: 0 0 3px 3px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.transparent-mode .Select2__option:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.transparent-mode .Select2__menu,
.transparent-mode .Select2__multi-value {
  background-color: var(--transparent-body) !important;
}

.transparent-mode .Select2__control {
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .Select2__multi-value__label {
  color: #fff;
}

.transparent-mode .sun-editor .se-toolbar {
  background-color: var(--transparent-body);
  outline: none;
}

.transparent-mode .sun-editor {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.transparent-mode .sun-editor .se-btn-module-border {
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .sun-editor button {
  color: #fff;
}

.transparent-mode .sun-editor .se-btn:enabled:focus,
.transparent-mode .sun-editor .se-btn:enabled:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .sun-editor-editable {
  color: #fff;
  background-color: var(--transparent-body);
}

.transparent-mode .sun-editor .se-resizing-bar {
  border-top-color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
}

.transparent-mode .sun-editor .se-list-layer,
.transparent-mode .sun-editor .se-btn:enabled:active {
  background-color: var(--transparent-body);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .sun-editor .se-btn-list:focus,
.transparent-mode .sun-editor .se-btn-list:hover,
.transparent-mode .sun-editor .se-btn:enabled.on {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .sun-editor .se-btn:enabled:active {
  box-shadow: none;
}

.transparent-mode .sun-editor .se-btn-list.default_value {
  background-color: var(--transparent-body);
  border-top-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.transparent-mode .text-editor {
  background-color: var(--transparent-body);
  color: #fff;
}

.transparent-mode .border-style {
  border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.transparent-mode #chart #apexchartscandles .apexcharts-tooltip,
.transparent-mode .apexcharts-legend {
  color: #2a2a4a;
}

.transparent-mode .Select-sm__single-value,
.transparent-mode .Select-lg__single-value {
  color: #fff;
}

.transparent-mode .search-result {
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  background-color: var(--transparent-body);
}
/*# sourceMappingURL=transparent-style.css.map */
