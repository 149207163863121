body {
  /* min-width: 1400px; */
}
.app-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.app-sidebar .main-sidemenu {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.app-header {
  background: #f1f3f6;
}
.side-header {
  border: 0;
}
.side-menu {
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
.side-menu .slide {
  border-radius: 8px;
  margin-bottom: 12px;
}
.side-menu .slide:last-child {
  margin-bottom: 0;
}
.side-menu .slide.active {
  position: relative;
  /* background: #f2fbfe; */
}
.side-menu .slide.active:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background: #99d3f2;
  border-radius: 4px 0 0 4px;
  top: 0;
  right: -24px;
}
@media (max-width: 992px) {
  .side-menu .slide.active:after {
    display: none;
  }
}
.side-menu .slide.active .side-menu__item {
  font-weight: bold;
  color: #333e46;
}
.side-menu .slide.active .side-menu__icon {
  color: #333e46 !important;
}
.side-menu .side-menu__icon {
  font-size: 13px;
  color: #ced5dd;
  line-height: 20px;
  margin-right: 12px;
}
.side-menu .side-menu__icon.fa-truck {
  font-size: 15px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.side-menu .angle {
  font-size: 14px;
  color: #ced5dd;
}
.side-menu__item {
  height: 52px;
  font-size: 18px;
  color: #475160;
  padding: 16px;
  margin: 0;
}
.side-menu__item:focus,
.side-menu__item:hover {
  color: #333e46;
  background: #f9fafb;
}
.side-menu__item:focus .side-menu__icon,
.side-menu__item:hover .side-menu__icon {
  color: #ced5dd;
}
.side-menu__item:focus .side-menu__label,
.side-menu__item:hover .side-menu__label {
  color: #333e46;
}
.side-menu__label {
  margin-top: 0;
  margin-left: 10px;
}
.side-menu__noti {
  position: absolute;
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  background: #bee7c6;
  border-radius: 6px;
  padding: 0 6px;
  top: 14px;
  right: 14px;
}
.slide.is-expanded .angle {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.slide-menu {
  padding-left: 0;
}
.slide-menu li {
  border-bottom: 1px solid #f1f3f6;
}
.slide-menu li .slide-item {
  height: 52px;
  font-size: 14px;
  color: #92a3b0;
  line-height: 52px;
  padding: 0 0 0 20px;
}
.slide-menu li .slide-item:before {
  display: none;
}
.slide-menu li .slide-item.active,
.slide-menu li .slide-item:focus,
.slide-menu li .slide-item:hover {
  color: #333e46 !important;
}
.toggled-log-img {
  display: none;
}
.side-footer {
  padding: 24px;
}
.side-footer .app-sidebar__toggle {
  width: 38px;
  height: 38px;
  border: 1px solid #e2e5e9;
  border-radius: 8px;
}
.side-footer .app-sidebar__toggle:after,
.side-footer .app-sidebar__toggle:before {
  display: none;
}
.side-footer .separator-line {
  height: 1px;
  background: #f1f3f6;
  margin: 24px 0;
}
.side-footer .dark-light-panel {
  background: #f7f7f7;
  border-radius: 8px;
}
.side-footer .dark-light-panel .layout-setting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 4px;
  cursor: pointer;
}
.side-footer .dark-light-panel .layout-setting > span {
  width: 50%;
  height: 36px;
  font-size: 14px;
  text-align: center;
  color: #afbbca;
  line-height: 36px;
  border-radius: 4px;
}
.side-footer .dark-light-panel .layout-setting svg {
  opacity: 0.5;
  margin-right: 6px;
  margin-top: -2px;
}
.light-mode .side-footer .dark-light-panel .light-layout-btn {
  color: #333e46;
  background: #fff;
}
.light-mode .side-footer .dark-light-panel .light-layout-btn svg {
  opacity: 1;
}
.dark-mode .side-footer .dark-light-panel .dark-layout-btn {
  color: #333e46;
  background: #fff;
}
.dark-mode .side-footer .dark-light-panel .dark-layout-btn svg {
  opacity: 1;
}
@media (min-width: 992px) {
  .app.sidebar-mini.sidenav-toggled:not(.sidenav-toggled-open)
    .toggled-log-img {
    display: block;
  }
  .app.sidebar-mini.sidenav-toggled:not(.sidenav-toggled-open)
    .side-menu__noti {
    width: 10px;
    height: 10px;
    font-size: 0;
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 0;
    top: -2px;
    right: -2px;
  }
  .app.sidebar-mini.sidenav-toggled:not(.sidenav-toggled-open) .side-menu {
    padding-left: 10px;
    padding-right: 10px;
  }
  .app.sidebar-mini.sidenav-toggled:not(.sidenav-toggled-open)
    .side-menu__item {
    display: block;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .app.sidebar-mini.sidenav-toggled:not(.sidenav-toggled-open)
    .side-menu__icon {
    color: #ced5dd;
  }
  .app.sidebar-mini.sidenav-toggled:not(.sidenav-toggled-open)
    .active
    .side-menu__icon {
    color: #333e46;
  }
  .app.sidebar-mini.sidenav-toggled:not(.sidenav-toggled-open) .side-footer {
    padding-left: 10px;
    padding-right: 10px;
  }
  .app.sidebar-mini.sidenav-toggled:not(.sidenav-toggled-open)
    .side-footer
    .app-sidebar__toggle {
    left: 10px;
    right: auto;
  }
  .app.sidebar-mini.sidenav-toggled:not(.sidenav-toggled-open)
    .side-footer
    .dark-light-panel
    .layout-setting {
    display: block;
  }
  .app.sidebar-mini.sidenav-toggled:not(.sidenav-toggled-open)
    .side-footer
    .dark-light-panel
    .layout-setting
    > span {
    display: block;
    width: 100%;
    font-size: 0;
  }
  .app.sidebar-mini.sidenav-toggled:not(.sidenav-toggled-open)
    .side-footer
    .dark-light-panel
    .layout-setting
    > span
    svg {
    margin-right: 0;
  }
  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__icon {
    color: #ced5dd;
  }
  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open
    .active
    .side-menu__icon {
    color: #333e46;
  }
  .app.sidebar-mini.sidenav-toggled .side-footer .app-sidebar__toggle {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}
.header-main-title-page {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-main-title-page .main-title-icon,
.header-main-title-page h2 {
  color: #92a3b0;
}
.header-main-title-page div .main-title-icon,
.header-main-title-page .main-title-icon {
  margin-top: -2px;
  margin-right: 5px;
}
.header-main-title-page h2 {
  font-size: 16px;
  margin-bottom: 3px;
}
.header-notification-block {
  position: relative;
  height: 34px;
  top: 10px;
}
.header-notification-block.active .header-notification-listing-block {
  display: block;
}
.header-notification-block.has-notification:after {
  content: "";
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  border: 2px solid #f1f3f6;
  border-radius: 50%;
  background: #bee7c6;
  top: -2px;
  right: 1px;
}
.header-notification-block i {
  font-size: 24px;
  line-height: 1;
}
.header-notification-block > i {
  cursor: pointer;
}
.header-notification-listing-block {
  display: none;
  position: absolute;
  width: 275px;
  border-radius: 4px;
  background: #fff;
  padding: 12px;
  top: 45px;
  left: -125px;
  -webkit-box-shadow: 0px 16px 16px -8px rgba(171, 174, 196, 0.2);
  box-shadow: 0px 16px 16px -8px rgba(171, 174, 196, 0.2);
}
.header-notification-listing-block:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid #fff;
  top: -12px;
  right: 128px;
}
.header-notification-listing-block .header-notification-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px;
  padding: 12px;
  gap: 12px;
  margin-top: 5px;
}
.header-notification-listing-block .header-notification-item:first-child {
  margin-top: 0;
}
.header-notification-listing-block .header-notification-item:hover {
  background: #f2fbfe;
}
.header-notification-listing-block .header-notification-item .message-text {
  font-size: 12px;
  line-height: 14px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.header-notification-listing-block .header-notification-item .time-text {
  font-size: 10px;
  color: #92a3b0;
  white-space: nowrap;
}
.header-notification-listing-block .view-all-notifications-block {
  text-align: center;
  border-top: 1px solid #f1f3f6;
  padding-top: 14px;
}
.header-notification-listing-block .view-all-notifications-block a {
  color: #61b8e7;
  padding-top: 0;
}
.header-notification-listing-block .view-all-notifications-block a i {
  display: inline-block;
  vertical-align: top;
  font-size: 22px;
}
.user-panel-block {
  margin-left: 20px;
}
.user-panel-block .user-image-block {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #fff;
  padding: 0;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.user-panel-block .user-name-block {
  margin-left: 10px;
  margin-right: 20px;
}
.user-panel-block .user-name-block .user-name-text {
  font-size: 16px;
  font-weight: bold;
  color: #475160;
}
.user-panel-block .user-name-block .user-role-text {
  font-size: 14px;
  color: #92a3b0;
  line-height: 1;
}
.user-panel-block .header-user-nav {
  width: 38px;
  height: 38px;
  border: 1px solid #e2e5e9;
  border-radius: 8px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.user-panel-block .header-user-nav i {
  font-size: 20px;
  color: #333e46;
}
body {
  background: #f5f7f9;
}
.app-content .side-app {
  background: #f5f7f9;
  padding: 30px 30px 0 30px;
}
.welcome-text-block h1 {
  font-size: 30px;
  font-weight: bold;
  color: #99d3f2;
  margin-bottom: 10px;
}
.welcome-text-block p {
  margin-bottom: 0;
}
.last-data-refresh {
  /* -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end; */
  text-align: right;
}
.last-data-refresh .last-data-label {
  color: #92a3b0;
}
.last-data-refresh .time-data {
  margin: 0 5px;
}
.last-data-refresh .refresh-icon {
  font-size: 14px;
  color: #ced5dd;
}
.dashboard-panel-block {
  margin-top: 30px;
  gap: 10px;
}
.calendar-block .form-select {
  width: 145px;
  height: 44px;
  border: 0;
  border-radius: 12px 0 0 12px;
}
.dashboard-datepicker-block {
  background: #fff;
  border-left: 1px solid #f5f7f9;
  border-radius: 0 12px 12px 0;
  padding-left: 10px;
  overflow: hidden;
}
.dashboard-datepicker-block .input-datepicker {
  width: 90px;
  border: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.dashboard-datepicker-block .bi-calendar-minus-fill {
  font-size: 16px;
  line-height: 42px;
  margin-right: 10px;
}
.dashboard-datepicker-block .bi-arrow-right-short {
  font-size: 24px;
  line-height: 42px;
}
.select-border-field-block {
  border: 1px solid #e2e5e9;
  border-radius: 12px;
  padding: 4px 4px 4px 10px;
  gap: 10px;
}
.select-border-field-block .label-block {
  margin-top: 5px;
}
.select-border-field-block .form-select {
  width: 135px;
  border: 0;
  border-radius: 8px;
  height: 34px;
  line-height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.dashboard-section-wrapper {
  margin-top: 20px;
  gap: 20px;
}
.dashboard-section .row {
  margin-top: 20px;
}
.dashboard-section .row:first-child {
  margin-top: 0;
}
.dashboard-card {
  height: 100%;
  border: 1px solid #f1f3f6;
  border-radius: 20px;
  background: #fff;
  padding: 24px;
}
.dashboard-card h3 {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 10px;
  padding-left: 10px;
}
.dashboard-card .deliver-label {
  font-size: 18px;
  line-height: 1;
  border: 1px solid #f1f3f6;
  border-radius: 8px;
  padding: 10px 14px;
  margin-top: -8px;
}
.dashboard-card .title-note {
  color: #afbbca;
}
.dashboard-card .chart-block {
  height: 235px;
  margin-top: 10px;
}
.dashboaord-info-card-section .info-card {
  /* width: 280px; */
  /* height: 150px; */
  height: 200px;
  background: #fff;
  border-radius: 20px;
  padding: 24px;
  margin-bottom: 20px;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
  position: relative;
}
.dashboaord-info-card-section .info-card:hover {
  color: #fff;
}
.dashboaord-info-card-section .info-card:hover .icon-block,
.dashboaord-info-card-section .info-card:hover .title-text {
  color: #fff;
}
.dashboaord-info-card-section .info-card:hover .icon-block i,
.dashboaord-info-card-section .info-card:hover .title-text i {
  color: #fff !important;
}
.dashboaord-info-card-section .info-card:hover .num-right-block p:nth-child(2) {
  color: #fff;
}
.dashboaord-info-card-section .info-card .title-text {
  color: #475160;
}
.dashboaord-info-card-section .info-card .icon-block {
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 1;
  border-radius: 12px;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.dashboaord-info-card-section .info-card .icon-block .bi-clipboard-minus {
  font-size: 18px;
  line-height: 48px;
}
.dashboaord-info-card-section .info-card .icon-block .fa-truck {
  font-size: 20px;
  line-height: 48px;
}
.dashboaord-info-card-section .info-card .num-block-wrapper {
  margin-top: 20px;
  gap: 10px;
}
.dashboaord-info-card-section .info-card .num-block {
  font-size: 32px;
  line-height: 1;
}
.dashboaord-info-card-section .info-card .num-right-block {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.dashboaord-info-card-section .info-card .num-right-block p {
  line-height: 1;
  margin: 0;
}
.dashboaord-info-card-section .info-card .num-right-block p:nth-child(2),
.since-last {
  color: #afbbca;
  font-size: 12px;
}
.dashboaord-info-card-section
  .info-card
  .num-right-block
  .bi-arrow-right-short {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.dashboaord-info-card-section .info-card.gmw-card:hover {
  background: #99d3f2;
}
.dashboaord-info-card-section .info-card.gmw-card:hover .icon-block {
  background: #bde4f5;
}
.dashboaord-info-card-section .info-card.gmw-card .icon-block {
  background: #e3f5fb;
}
.dashboaord-info-card-section .info-card.gmw-card .icon-block i {
  color: #99d3f2;
}
.dashboaord-info-card-section .info-card.credits-card:hover {
  background: #ecb888;
}
.dashboaord-info-card-section .info-card.credits-card:hover .icon-block {
  background: #f5d49e;
}
.dashboaord-info-card-section .info-card.credits-card:hover .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.credits-card:hover p:first-child {
  color: #fff;
}
.dashboaord-info-card-section .info-card.credits-card .icon-block {
  background: #fbf0cc;
}
.dashboaord-info-card-section .info-card.credits-card .icon-block i {
  color: #ecb888;
}
.dashboaord-info-card-section .info-card.credits-card .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.credits-card p:first-child {
  color: #f5d49e;
}
.dashboaord-info-card-section .info-card.deliveries-card:hover {
  background: #add5b7;
}
.dashboaord-info-card-section .info-card.deliveries-card:hover .icon-block {
  background: #bee7c6;
}
.dashboaord-info-card-section
  .info-card.deliveries-card:hover
  .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.deliveries-card:hover p:first-child {
  color: #fff;
}
.dashboaord-info-card-section .info-card.deliveries-card .icon-block {
  background: #daf3d7;
}
.dashboaord-info-card-section .info-card.deliveries-card .icon-block i {
  color: #add5b7;
}
.dashboaord-info-card-section .info-card.deliveries-card .bi-arrow-up-right,
.dashboaord-info-card-section .info-card.deliveries-card p:first-child {
  color: #add5b7;
}
.dashboaord-info-card-section .info-card.visits-card:hover {
  background: #475160;
}
.dashboaord-info-card-section .info-card.visits-card:hover .icon-block {
  background: #92a3b0;
}
.dashboaord-info-card-section .info-card.visits-card .icon-block {
  background: #e2e5e9;
}
.dashboaord-info-card-section .info-card.visits-card .icon-block i {
  color: #92a3b0;
}
.main-title-page {
  font-size: 30px;
  font-weight: bold;
  color: #475160;
}
.search-bar-block {
  width: 300px;
  height: 44px;
  background: #f1f3f6;
  border-radius: 12px;
}
.search-bar-block.light-style {
  background: #fff;
}
.search-bar-block .bi-search {
  font-size: 18px;
  color: #ced5dd;
  line-height: 44px;
  padding: 0 5px 0 10px;
}
.search-bar-block .search-field {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: 0;
  border: 0;
  padding-left: 10px;
}
.activity-listing-block {
  margin-top: 30px;
}
.activity-listing-block .timeline-header-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 62px;
  padding-right: 12px;
}
@media (max-width: 992px) {
  .activity-listing-block .timeline-header-block {
    display: none;
  }
}
.activity-listing-block .timeline-header-block .header-block {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 16.6666666667%;
  font-size: 12px;
  color: #92a3b0;
  text-transform: uppercase;
  padding-left: 12px;
}
.activity-listing-block .timeline-listing {
  position: relative;
  padding: 0 0 0 50px;
  margin: 12px 0 0;
  list-style: none;
}
.activity-listing-block .timeline-listing:before {
  content: "";
  position: absolute;
  width: 1px;
  height: calc(100% - 100px);
  background: #707786;
  top: 100px;
  left: 25px;
}
.activity-listing-block .timeline-item-block {
  position: relative;
  border-radius: 16px;
  background: #fff;
  padding: 0;
  margin-top: 12px;
}
.activity-listing-block .timeline-item-block:before {
  content: "";
  position: absolute;
  display: block;
  width: 28px;
  height: 28px;
  border: 8px solid #e2e5e9;
  border-radius: 50%;
  background: #475160;
  top: 80px;
  left: -38px;
}
.activity-listing-block .timeline-item-block:first-child {
  margin-top: 0;
}
.activity-listing-block .timeline-item-block.active .product-listing-block {
  display: block;
}
.activity-listing-block .timeline-item-block.active .see-detail-toggle {
  display: none;
}
.activity-listing-block .timeline-item-block.active .hide-detail-toggle {
  display: block;
}
.activity-listing-block .timeline-info-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
}
@media (max-width: 992px) {
  .activity-listing-block .timeline-info-item {
    display: block;
  }
}
.activity-listing-block .timeline-info-item .header-block {
  display: none;
  font-size: 12px;
  color: #92a3b0;
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media (max-width: 992px) {
  .activity-listing-block .timeline-info-item .header-block {
    display: block;
  }
}
.activity-listing-block .timeline-info-item h3 {
  font-size: 16px;
  font-weight: bold;
  color: #333e46;
  line-height: normal;
}
.activity-listing-block .timeline-info-item .role-label {
  display: inline-block;
  font-size: 12px;
  color: #92a3b0;
  line-height: 20px;
  background: #f9fafb;
  border-radius: 4px;
  padding: 0 8px;
}
.activity-listing-block .timeline-info-item .logo-block {
  margin-top: 75px;
}
@media (max-width: 992px) {
  .activity-listing-block .timeline-info-item .logo-block {
    margin-top: 20px;
  }
}
.activity-listing-block .timeline-info-item .bi-geo-alt-fill {
  color: #ced5dd;
}
.activity-listing-block .timeline-info-item .time-in-out {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 110px;
  margin-bottom: 8px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.activity-listing-block .timeline-info-item .time-duration {
  width: 100%;
  max-width: 110px;
  font-weight: bold;
  color: #333e46;
  border-top: 1px solid #f1f3f6;
  padding-top: 10px;
  margin-top: 10px;
}
.activity-listing-block .timeline-info-item .time-duration .bi {
  font-size: 18px;
  margin-right: 14px;
}
.activity-listing-block .check-in-out-block,
.activity-listing-block .event-block,
.activity-listing-block .location-block,
.activity-listing-block .shelf-after-block,
.activity-listing-block .shelf-before-block,
.activity-listing-block .supply-status-block {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 16.6666666667%;
  padding: 24px 12px;
}
@media (max-width: 992px) {
  .activity-listing-block .check-in-out-block,
  .activity-listing-block .event-block,
  .activity-listing-block .location-block,
  .activity-listing-block .shelf-after-block,
  .activity-listing-block .shelf-before-block,
  .activity-listing-block .supply-status-block {
    max-width: none;
    border-bottom: 1px solid #f1f3f6;
  }
}
.activity-listing-block .supply-status-block .credit-status,
.activity-listing-block .supply-status-block .delivery-status,
.activity-listing-block .supply-status-block .stock-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 160px;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
}
.activity-listing-block .supply-status-block .credit-status span,
.activity-listing-block .supply-status-block .delivery-status span,
.activity-listing-block .supply-status-block .stock-status span {
  width: 34px;
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
}
.activity-listing-block .supply-status-block .stock-status {
  background: #f2fbfe;
}
.activity-listing-block .supply-status-block .stock-status span {
  color: #61b8e7;
}
.activity-listing-block .supply-status-block .credit-status {
  background: #fbf0cc;
}
.activity-listing-block .supply-status-block .credit-status span {
  color: #ecb888;
}
.activity-listing-block .supply-status-block .delivery-status {
  background: #f1fce4;
}
.activity-listing-block .supply-status-block .delivery-status span {
  color: #8eb99d;
}
.activity-listing-block .shelf-after-block a,
.activity-listing-block .shelf-before-block a {
  position: relative;
  display: block;
}
.activity-listing-block .shelf-after-block a:after,
.activity-listing-block .shelf-before-block a:after {
  content: "\f62c";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  font-family: bootstrap-icons !important;
  background: #fff;
  border-radius: 8px;
  text-align: center;
  line-height: 32px;
  top: 12px;
  right: 12px;
}
.activity-listing-block .shelf-after-block img,
.activity-listing-block .shelf-before-block img {
  width: 100%;
  height: auto;
}
.activity-listing-block .product-listing-block {
  display: none;
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
}
.activity-listing-block .product-listing-block:before {
  content: "";
  position: absolute;
  display: block;
  width: calc(100% - 48px);
  height: 1px;
  background: #f1f3f6;
  top: 0;
  left: 24px;
}
.activity-listing-block .product-listing-block .product-header-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}
@media (max-width: 992px) {
  .activity-listing-block .product-listing-block .product-header-list {
    display: none;
  }
}
.activity-listing-block .product-listing-block .product-header {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 12px;
  color: #92a3b0;
  text-transform: uppercase;
  padding-left: 24px;
}
.activity-listing-block .product-listing-block .product-header:first-child,
.activity-listing-block .product-listing-block .product-header:nth-child(3) {
  max-width: 20%;
}
.activity-listing-block .product-listing-block .product-header:nth-child(2),
.activity-listing-block .product-listing-block .product-header:nth-child(4) {
  max-width: 30%;
}
.activity-listing-block .product-listing {
  padding: 0;
  margin: 0;
  list-style: none;
}
.activity-listing-block .product-listing li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 992px) {
  .activity-listing-block .product-listing li {
    display: block;
  }
}
.activity-listing-block .product-listing .product-header {
  display: none;
  max-width: none !important;
  padding-left: 0;
  margin-bottom: 10px;
}
@media (max-width: 992px) {
  .activity-listing-block .product-listing .product-header {
    display: block;
  }
}
.activity-listing-block .product-listing .photo-block,
.activity-listing-block .product-listing .product-name-block,
.activity-listing-block .product-listing .quantity-block,
.activity-listing-block .product-listing .sku-block {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 6px 24px;
}
@media (max-width: 992px) {
  .activity-listing-block .product-listing .photo-block,
  .activity-listing-block .product-listing .product-name-block,
  .activity-listing-block .product-listing .quantity-block,
  .activity-listing-block .product-listing .sku-block {
    max-width: none !important;
    border-bottom: 1px solid #f1f3f6;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
.activity-listing-block .product-listing .photo-block,
.activity-listing-block .product-listing .quantity-block {
  max-width: 20%;
}
.activity-listing-block .product-listing .product-name-block,
.activity-listing-block .product-listing .sku-block {
  max-width: 30%;
}
.activity-listing-block .product-listing .product-thumbnail {
  width: max-content;
  height: max-content;
  border: 1px solid #f1f3f6;
  border-radius: 12px;
  overflow: hidden;
}
.activity-listing-block .product-listing .product-thumbnail img {
  width: 35px;
  height: auto;
}
.activity-listing-block .product-listing .product-name-block {
  font-size: 16px;
  color: #475160;
}
.activity-listing-block .product-listing .quantity-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
.activity-listing-block .product-listing .credit-count,
.activity-listing-block .product-listing .delivery-count,
.activity-listing-block .product-listing .stock-count {
  width: 28px;
  height: 28px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 28px;
  border-radius: 4px;
}
.activity-listing-block .product-listing .stock-count {
  color: #99d3f2;
  background: #f2fbfe;
}
.activity-listing-block .product-listing .credit-count {
  color: #ecb888;
  background: #fbf0cc;
}
.activity-listing-block .product-listing .delivery-count {
  color: #8eb99d;
  background: #f1fce4;
}
.activity-listing-block .product-listing .sku-block {
  color: #92a3b0;
}
.activity-listing-block .toggle-more-detail-item {
  position: relative;
  padding: 24px;
}
.activity-listing-block .toggle-more-detail-item:before {
  content: "";
  position: absolute;
  display: block;
  width: calc(100% - 48px);
  height: 1px;
  background: #f1f3f6;
  top: 0;
  left: 24px;
}
.activity-listing-block .toggle-more-detail-item .bi-calendar-minus-fill {
  margin-right: 10px;
}
.activity-listing-block .toggle-more-detail-item .hide-detail-toggle,
.activity-listing-block .toggle-more-detail-item .see-detail-toggle {
  cursor: pointer;
}
.activity-listing-block .toggle-more-detail-item .hide-detail-toggle {
  display: none;
}
.brand-btn.btn {
  border: 0;
  border-radius: 8px;
  line-height: 44px;
  padding: 0 25px;
}
.brand-btn.btn.btn-primary {
  font-size: 16px;
  color: #2d99d2 !important;
  background: #d1edf5 !important;
}
.product-listing-table {
  margin-top: 20px;
}
.product-listing-table .dataTables_wrapper > .row:first-child {
  display: none;
}
.product-listing-table .thumbnail-product-image {
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid #f1f3f6;
  border-radius: 6px;
  overflow: hidden;
}
.product-listing-table .thumbnail-product-image img {
  width: 100%;
  height: auto;
}
.brand-data-table-style.light-style .table thead th {
  background: #fff;
}
.brand-data-table-style .dataTables_wrapper > .row {
  margin-top: 20px;
}
.brand-data-table-style .dataTables_wrapper > .row:last-child > div {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 50%;
}
.brand-data-table-style .table thead th {
  font-size: 12px;
  color: #92a3b0;
  border-bottom: 0;
  background: #f9fafb;
  padding: 13px 24px;
}
.data-table-inner-activity .table thead th:first-child {
  border-radius: 12px 0 0 0 !important;
}
.data-table-inner-activity .table thead th:last-child {
  border-radius: 0 12px 0 0 !important;
}
.brand-data-table-style .table thead tr th {
  background: #f9fafb;
}
.brand-data-table-style .table tbody td {
  color: #475160;
  background: #fff;
  border-top: 1px solid #f1f3f6;
  border-bottom: 0;
  padding: 16px 24px;
  vertical-align: middle;
}
.brand-data-table-style-activity .table thead th {
  font-size: 12px;
  color: #92a3b0;
  border-bottom: 0;
  background: #f9fafb;
  padding: 13px 24px;
}
.brand-data-table-style-activity .table tbody td {
  color: #475160;
  border-top: 1px solid #f1f3f6;
  border-bottom: 0;
  padding: 16px 24px;
  vertical-align: middle;
}
.no-data {
  color: #475160 !important;
  background: transparent !important;
  border-bottom: 0;
  padding: 16px 24px;
  vertical-align: middle;
}

.brand-data-table-style table.dataTable > thead .sorting:after,
.brand-data-table-style table.dataTable > thead .sorting:before,
.brand-data-table-style table.dataTable > thead .sorting_asc:after,
.brand-data-table-style table.dataTable > thead .sorting_asc:before,
.brand-data-table-style table.dataTable > thead .sorting_desc:after,
.brand-data-table-style table.dataTable > thead .sorting_desc:before {
  bottom: 12px;
}
.brand-data-table-style .text-center .action-btn {
  margin-left: auto;
  margin-right: auto;
}
.brand-data-table-style .barcode-col {
  color: #92a3b0 !important;
}
.brand-data-table-style .action-btn {
  display: block;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 8px;
  background: #f5f7f9;
}
.brand-data-table-style .action-btn:hover {
  background: #ced5dd;
}
.brand-data-table-style .action-btn:hover i {
  color: #475160;
}
.brand-data-table-style .action-btn i {
  font-size: 20px;
  color: #92a3b0;
  line-height: 32px;
}
.brand-data-table-style .view-btn {
  cursor: pointer;
}
.brand-data-table-style .edit-btn i {
  font-size: 15px;
}
.brand-data-table-style .reason-btn {
  font-size: 12px;
}
.brand-data-table-style .delete-btn:hover {
  background: #fdeaea;
}
.brand-data-table-style .delete-btn:hover i {
  color: #ea7b7b;
}
.brand-data-table-style .delete-btn i {
  font-size: 18px;
}
.dataTables_info {
  font-size: 12px;
  color: #92a3b0;
}
.brand-data-table-style .pagination li {
  margin-right: 4px;
}
.brand-data-table-style .pagination li:last-child {
  margin-right: 0;
}
.brand-data-table-style .page-link {
  font-weight: bold;
  color: #707786;
  border-radius: 8px;
}
.brand-data-table-style .page-link:focus,
.brand-data-table-style .page-link:hover {
  color: #707786;
}
.brand-data-table-style .page-item:first-child .page-link,
.brand-data-table-style .page-item:last-child .page-link {
  border-radius: 8px;
}
.brand-data-table-style .page-item:first-child {
  margin-right: 14px;
}
.brand-data-table-style .page-item:last-child {
  margin-left: 10px;
}
.brand-data-table-style .page-item.active .page-link {
  border-color: #707786;
  background: #707786;
}
.custom-switch-indicator.brand-switch-indicator {
  border: 0;
}
.custom-switch-input:checked ~ .custom-switch-indicator.brand-switch-indicator {
  background: #add5b7;
}
.custom-switch-indicator.brand-switch-indicator:before {
  top: 2px;
}
.view-product-modal .modal-dialog {
  max-width: 1166px;
}
.view-product-modal .modal-content {
  background: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
}
.view-product-modal .modal-body {
  padding: 0;
}
.view-product-modal .view-product-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 810px;
  gap: 12px;
}
.view-product-modal .product-info-sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 350px;
  height: 100%;
  border-radius: 16px;
  background: #fff;
  overflow: hidden;
}
.view-product-modal .product-price-table-section {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  border-radius: 16px;
  background: #fff;
  overflow: hidden;
}
.view-product-modal .product-image-block {
  background: #f1f3f6;
  padding: 12px;
}
.view-product-modal .product-image-block .product-image-inner {
  border-radius: 12px;
  overflow: hidden;
}
.view-product-modal .product-image-block a {
  position: relative;
  display: block;
}
.view-product-modal .product-image-block a:after {
  content: "\f62c";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  font-family: bootstrap-icons !important;
  background: #fff;
  border-radius: 8px;
  text-align: center;
  line-height: 32px;
  top: 12px;
  right: 12px;
}
.view-product-modal .product-image-block img {
  width: 100%;
  height: auto;
}
.view-product-modal .product-info-block {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}
.view-product-modal .product-info-card {
  border-bottom: 1px solid #f5f7f9;
  padding: 24px;
}
.view-product-modal .branch-info {
  font-size: 18px;
  color: #92a3b0;
}
.view-product-modal .product-code {
  font-size: 18px;
  font-weight: bold;
  color: #ecb888;
}
.view-product-modal .product-name {
  font-size: 23px;
  font-weight: bold;
  color: #475160;
  line-height: 24px;
  margin-top: 12px;
}
.view-product-modal .product-attr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  color: #475160;
  gap: 10px;
  margin-top: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.view-product-modal .product-note {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 24px 12px 0 24px;
  overflow-y: auto;
}
.view-product-modal .product-note h3 {
  font-size: 16px;
  font-weight: bold;
  color: #475160;
  line-height: 18px;
  margin-bottom: 20px;
}
.view-product-modal .product-upc-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
}
.view-product-modal .product-upc-info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
  padding-left: 16px;
}
.view-product-modal .product-upc-info:first-child {
  position: relative;
  padding-left: 0;
}
.view-product-modal .product-upc-info:first-child:after {
  content: "";
  position: absolute;
  display: block;
  width: 1px;
  height: 44px;
  background: #f1f3f6;
  top: -5px;
  right: 0;
}
.view-product-modal .product-upc-info svg {
  width: 12px;
  margin-top: 3px;
}
.view-product-modal .product-upc-info h4 {
  font-size: 14px;
  font-weight: normal;
  color: #475160;
  line-height: 16px;
  margin-bottom: 4px;
}
.view-product-modal .product-upc-info p {
  font-size: 12px;
  color: #92a3b0;
  line-height: 14px;
  margin-bottom: 0;
}
.view-product-modal .product-price-table-section h2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  color: #475160;
  padding: 20px 24px;
  margin-bottom: 0;
}
.filter-by-section {
  background: #f5f7f9;
  padding: 14px 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.filter-by {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 14px;
}
.view-product-filter-by-nav .nav-item {
  margin-right: 8px;
}
.view-product-filter-by-nav .nav-link {
  font-size: 14px;
  line-height: 1;
  color: #707786;
  border: 0;
  border-radius: 6px;
  background: #e2e5e9;
  padding: 4px 8px;
}
.view-product-filter-by-nav .nav-link.active {
  color: #f9fafb;
  background: #475160;
}
.show-base-price-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}
.show-base-price-section .title-label {
  font-size: 12px;
  line-height: 14px;
  color: #92a3b0;
}
.product-price-table {
  margin-top: -5px;
}
.product-price-table .table thead th {
  padding: 8px 12px;
}
.product-price-table .table tbody td {
  font-size: 12px;
  color: #707786;
  line-height: 14px;
  padding: 8px 12px;
}
.product-price-table .table tbody td,
.product-price-table .table thead th {
  white-space: nowrap;
}
.product-price-table .base-price-col {
  background: #f2fbfe !important;
}
.product-price-table .base-price-colth {
  color: #61b8e7 !important;
}
.product-price-table .promo-price-col {
  background: #f1fce4 !important;
}
.product-price-table .promo-price-colth {
  color: #71a784 !important;
}
.product-price-table .base-price-val,
.product-price-table .promo-price-val {
  display: inline-block;
  color: #475160;
  border-radius: 4px;
  padding: 4px 8px;
}
.product-price-table .base-price-val {
  background: #e3f5fb;
}
.product-price-table .promo-price-val {
  background: #daf3d7;
}
.product-price-table.brand-data-table-style
  table.dataTable
  > thead
  .sorting:after,
.product-price-table.brand-data-table-style
  table.dataTable
  > thead
  .sorting:before,
.product-price-table.brand-data-table-style
  table.dataTable
  > thead
  .sorting_asc:after,
.product-price-table.brand-data-table-style
  table.dataTable
  > thead
  .sorting_asc:before,
.product-price-table.brand-data-table-style
  table.dataTable
  > thead
  .sorting_desc:after,
.product-price-table.brand-data-table-style
  table.dataTable
  > thead
  .sorting_desc:before {
  bottom: 8px;
}
.request-listing-table {
  margin-top: 20px;
}
.request-listing-table .dataTables_wrapper > .row:first-child {
  display: none;
}
.request-listing-table.brand-data-table-style .table tbody td {
  vertical-align: top;
}
.brand-badge {
  display: inline-block;
  font-size: 12px;
  color: #475160;
  line-height: 14px;
  white-space: nowrap;
  border-radius: 4px;
  background: #f5f7f9;
  padding: 5px 10px;
}
.brand-badge.success {
  color: #588e6b;
  background: #f1fce4;
}
.brand-badge.warning {
  color: #f1833f;
  background: #f9f6e2;
}
.brand-badge.danger {
  color: #dc4747;
  background: #fdeaea;
}
.brand-badge.blue {
  background: #e3f5fb;
}
.brand-badge.black {
  color: #fff;
  background: #000;
}
.brand-badge.num {
  color: #333e46;
}
.filter-control-block {
  gap: 10px;
}
.filter-btn {
  width: 155px;
  height: 44px;
  font-size: 16px;
  color: #475160;
  line-height: 44px;
  border: 1px solid #92a3b0;
  border-radius: 8px;
  background: 0;
  overflow: hidden;
}
.filter-btn.active .show-filter-label {
  display: none;
}
.filter-btn.active .hide-filter-label {
  display: block;
}
.filter-btn .hide-filter-label {
  display: none;
  color: #fff;
  background: #707786;
}
.filter-btn .hide-filter-label i {
  color: #fff;
}
.filter-btn i {
  position: relative;
  font-size: 10px;
  color: #475160;
  margin-right: 5px;
  top: -2px;
}
.export-dropdown-block {
  position: relative;
}
.export-dropdown-block .export-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 145px;
  height: 44px;
  font-size: 16px;
  color: #fff;
  line-height: 44px;
  border: 0;
  border-radius: 8px;
  background: #475160;
  gap: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.export-dropdown-block .export-btn i {
  position: relative;
  font-size: 10px;
  color: #fff;
  top: 1px;
}
.export-dropdown-listing {
  display: none;
  position: absolute;
  width: 200px;
  font-size: 14px;
  color: #333e46;
  border-radius: 12px;
  background: #fff;
  padding: 8px;
  top: 44px;
  right: 0;
  z-index: 2;
  -webkit-box-shadow: 0px 16px 16px -8px rgba(171, 174, 196, 0.2);
  box-shadow: 0px 16px 16px -8px rgba(171, 174, 196, 0.2);
}
.export-dropdown-listing.active {
  display: block;
}
.export-dropdown-listing:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 16px solid #fff;
  top: -12px;
  right: 23px;
}
.export-dropdown-listing .export-item {
  position: relative;
  height: 44px;
  line-height: 44px;
  border-bottom: 1px solid #f1f3f6;
  padding-left: 30px;
  cursor: pointer;
}
.export-dropdown-listing .export-item:last-child {
  border-bottom: 0;
}
.export-dropdown-listing .export-item .arrow-icon {
  position: absolute;
  display: block;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 3px;
  left: -6px;
}
.export-dropdown-listing .export-item .bi-arrow-right-short {
  font-size: 22px;
  color: #afbbca;
  padding-left: 10px;
}
.report-filter-section {
  display: none;
  background: #f1f3f6;
  border-radius: 20px;
  padding: 15px;
  margin-top: 18px;
}
.report-filter-section.active {
  display: block;
}
.report-filter-section > .d-flex {
  gap: 12px;
  margin-bottom: 15px;
}
.report-filter-section > .d-flex:last-child {
  margin-bottom: 0;
}
.multiple-dropdown-block .select2-container .select2-selection--single,
.multiple-dropdown-block
  .select2-container--default
  .select2-selection--multiple {
  height: 34px !important;
  border-radius: 8px;
}
.multiple-dropdown-block
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 1rem;
  color: #76839a;
  line-height: 34px !important;
}
.multiple-dropdown-block
  .select2-container
  .select2-selection--multiple
  .select2-selection__rendered {
  display: block;
  height: 30px;
  overflow: hidden;
}
.multiple-dropdown-block
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  border: 0 !important;
  background: #475160 !important;
  margin: 0 !important;
  height: 30px;
  line-height: 30px;
}
.multiple-dropdown-block .select2-container .select2-search--inline {
  display: none;
  position: absolute;
  width: 300px;
  background: #fff;
  border-radius: 12px 12px 0 0;
  margin-top: 10px;
  margin-left: -108px;
  padding: 4px;
}
.multiple-dropdown-block .select2-container .select2-search--inline:before {
  content: "\f52a";
  position: absolute;
  font-family: bootstrap-icons !important;
  color: #ced5dd;
  line-height: 34px;
  top: 4px;
  left: 15px;
}
.multiple-dropdown-block
  .select2-container
  .select2-search--inline
  .select2-search__field {
  width: 100% !important;
  height: 34px;
  font-size: 14px;
  line-height: 34px;
  color: #475160;
  border: 1px solid #707786;
  border-radius: 12px;
  padding-left: 35px;
  margin: 0;
}
.multiple-dropdown-block
  .select2-container--focus.select2-container
  .select2-search--inline,
.multiple-dropdown-block
  .select2-container--open.select2-container
  .select2-search--inline {
  display: block;
}
.multiple-dropdown-block
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:focus,
.multiple-dropdown-block
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  background: #475160 !important;
}
.select2-container--open .multiple-dropdown-container.select2-dropdown--below {
  width: 300px !important;
  border: 0;
  background: #fff;
  padding: 4px;
  margin-top: 50px;
  margin-left: -95px;
  -webkit-box-shadow: 0px 16px 16px -8px rgba(171, 174, 196, 0.2);
  box-shadow: 0px 16px 16px -8px rgba(171, 174, 196, 0.2);
}
.select2-container--open
  .multiple-dropdown-container
  .select2-results
  > .select2-results__options {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-container--open .multiple-dropdown-container .select2-results__option {
  position: relative;
  padding: 14px 50px 14px 14px;
}
.select2-container--open
  .multiple-dropdown-container
  .select2-results__option:not(.select2-results__message):after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid #e2e5e9;
  border-radius: 3px;
  top: 15px;
  right: 20px;
}
.select2-container--open
  .multiple-dropdown-container
  .select2-results__option.select2-results__option--selected {
  background: 0;
}
.select2-container--open
  .multiple-dropdown-container
  .select2-results__option.select2-results__option--selected:after {
  content: "\f26e";
  font-family: bootstrap-icons !important;
  line-height: 20px;
  text-align: center;
}
.select2-container--open
  .multiple-dropdown-container
  .select2-results__option--highlighted.select2-results__option--selectable {
  color: #282f53;
  background: 0;
}
.data-table-hide-top-cp .dataTables_wrapper > .row:first-child {
  display: none;
}
.report-table {
  margin-top: 20px;
}
.report-table .dataTables_wrapper > .row:first-child {
  display: none;
}
.report-table.brand-data-table-style .table tbody td {
  vertical-align: top;
}
.report-table .date-time-col {
  width: 130px;
}
.report-table .date-time-col > div {
  margin-bottom: 8px;
}
.report-table .date-time-col > div:last-child {
  margin-bottom: 0;
}
.report-table .date-time-col > div i {
  margin-right: 5px;
}
.report-table .store-col {
  width: 120px;
}
.report-table .invoice-col .brand-badge {
  float: left;
  margin-bottom: 4px;
  clear: both;
}
.report-table .invoice-col .brand-badge:last-child {
  margin-bottom: 0;
}
@media (max-width: 1200px) {
  .mb-space-xl {
    margin-bottom: 20px;
  }
}
.edit-profile-wrapper {
  /* display: -webkit-box;
  display: -ms-flexbox; */
  /* display: flex; */
  border-radius: 16px;
  background: #fff;
  /* padding: 32px 40px; */
  /* gap: 40px; */
}
.edit-profile-wrapper .profile-detail-section,
.edit-profile-wrapper .profile-image-section {
  /* -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; */
  /* max-width: 50%; */
}
.edit-profile-wrapper .section-title {
  border-bottom: 1px solid #f1f3f6;
  padding-bottom: 30px;
  margin-bottom: 40px;
}
.edit-profile-wrapper .section-title.no-border {
  border-bottom: 0;
  padding-bottom: 0;
  margin-top: 24px;
  margin-bottom: 24px;
}
.edit-profile-wrapper .profile-image-section {
  text-align: center;
}
.edit-profile-wrapper .profile-image-section h5 {
  font-size: 16px;
  line-height: 20px;
  color: #333e46;
  margin-bottom: 8px;
}
.edit-profile-wrapper .profile-image-section p {
  font-size: 12px;
  line-height: 14px;
  color: #92a3b0;
  margin-bottom: 0;
}
.edit-profile-wrapper .profile-upload-section {
  border: 1px solid #e2e5e9;
  border-radius: 16px;
  padding: 30px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;
  /* height: 685px; */
}
.edit-profile-wrapper .profile-upload-section .upload-image-wrapper {
  /* position: relative;
  width: 255px;
  height: 255px;
  margin: 80px auto; */
}
.edit-profile-wrapper .profile-upload-section .upload-image-wrapper img {
  /* width: 100%;
  height: 100%; */
  -o-object-fit: cover;
  object-fit: contain;
  min-height: 300px;
}
.edit-profile-wrapper .profile-upload-section .upload-image-inner {
  width: 100%;
  height: 100%;
  border: 1px solid #f1f3f6;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 24px;
}
.edit-profile-wrapper .profile-upload-section .upload-image-btn {
  position: absolute;
  width: 40px;
  height: 40px;
  text-align: center;
  background: #ced5dd;
  border-radius: 10px;
  right: 24px;
  bottom: 0;
}
.edit-profile-wrapper .profile-upload-section .upload-image-btn .fe {
  font-size: 18px;
  color: #475160;
  line-height: 40px;
}
.edit-profile-wrapper .profile-detail-section .form-item-set {
  padding-left: 24px;
  padding-right: 24px;
}
.edit-profile-wrapper .profile-detail-section fieldset {
  position: relative;
  border: 1px solid #e2e5e9;
  border-radius: 12px;
  padding: 0;
  margin-bottom: 24px;
}
.edit-profile-wrapper
  .profile-detail-section
  fieldset.select-field
  .form-control {
  width: calc(100% - 14px);
  height: 42px;
  background: #f9fafb;
  margin: 7px;
}
.edit-profile-wrapper .profile-detail-section fieldset.disabled {
  border-color: #f1f3f6;
}
.edit-profile-wrapper .profile-detail-section fieldset.disabled legend {
  color: #e2e5e9;
}
.edit-profile-wrapper .profile-detail-section fieldset.disabled .form-control {
  background: 0;
  opacity: 0.5;
}
.edit-profile-wrapper .profile-detail-section fieldset.invalid {
  border-color: #ea7b7b;
}
.edit-profile-wrapper .profile-detail-section fieldset.invalid legend {
  color: #ea7b7b;
}
.edit-profile-wrapper .profile-detail-section fieldset.invalid .form-control {
  background: #fdeaea;
}
.edit-profile-wrapper .profile-detail-section legend {
  position: absolute;
  width: auto;
  font-size: 12px;
  line-height: 14px;
  color: #92a3b0;
  background: #fff;
  padding-left: 4px;
  padding-right: 4px;
  margin: -8px 0 0 10px;
  z-index: 2;
}
.edit-profile-wrapper .profile-detail-section .form-control {
  height: 56px;
  border: 0;
  background: 0;
  padding-left: 16px;
}
.edit-profile-wrapper .profile-detail-section textarea.form-control {
  min-height: 120px;
}
.edit-profile-wrapper .profile-detail-section .input-with-icon {
  position: relative;
  padding-right: 32px;
}
.edit-profile-wrapper .profile-detail-section .toggle-password-btn {
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 8px;
  background: #f5f7f9;
  top: 12px;
  right: 12px;
}
.edit-profile-wrapper .profile-detail-section .form-btn-set {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 8px;
  padding-right: 24px;
}
.edit-profile-wrapper .profile-detail-section .form-btn-set .discard-btn,
.edit-profile-wrapper .profile-detail-section .form-btn-set .submit-btn {
  font-size: 16px;
  border-radius: 8px;
  padding: 10px 27px;
}
.edit-profile-wrapper .profile-detail-section .form-btn-set .discard-btn {
  color: #61b8e7;
  border: 1px solid #bde4f5;
  background: #fff;
}
.edit-profile-wrapper .profile-detail-section .form-btn-set .submit-btn {
  color: #2d99d2;
  border: 0;
  background: #d1edf5;
}
.edit-profile-wrapper
  .profile-detail-section
  .form-btn-set
  .submit-btn[disabled] {
  color: #bde4f5;
  background: #f2fbfe;
}
.product-edit-tab .nav-tabs .nav-link {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: 50%;
  background: #f1f3f6;
  text-align: center;
  border-radius: 16px 16px 0 0;
  padding: 8px 8px 0;
}
.product-edit-tab .nav-tabs .nav-link:hover:not(.disabled) {
  background: #f1f3f6;
}
.product-edit-tab .nav-tabs .nav-link.active {
  background: #fff;
}
.product-edit-tab .nav-tabs .nav-link.active:hover:not(.disabled) {
  background: #fff;
}
.product-edit-tab .nav-tabs .nav-link.active .nav-tab-inner {
  font-weight: bold;
  color: #333e46;
  background: #f2fbfe;
}
.product-edit-tab .nav-tabs .nav-link .nav-tab-inner {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #475160;
  border-radius: 10px;
  gap: 10px;
  padding: 15px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.product-edit-tab-content {
  background: #fff;
  border-radius: 0 0 16px 16px;
  padding: 12px;
}
.product-edit-tab-content .tab-pane {
  display: none;
}
.product-edit-tab-content .tab-pane.active {
  display: block;
}
.product-edit-tab-content .edit-profile-wrapper {
  position: relative;
}
.product-edit-tab-content .edit-profile-wrapper .profile-upload-section {
  position: relative;
}
.product-edit-tab-content
  .edit-profile-wrapper
  .profile-upload-section
  .upload-image-inner {
  border: 0;
  border-radius: 0;
}
.product-edit-tab-content
  .edit-profile-wrapper
  .profile-upload-section
  .upload-image-btn {
  right: 24px;
  bottom: 24px;
}
.product-edit-tab-content .edit-profile-wrapper .section-title i {
  font-size: 16px;
  margin-right: 3px;
}
.product-edit-tab-content
  .edit-profile-wrapper
  .profile-detail-section
  .form-btn-set
  .submit-btn {
  color: #f2fbfe;
  background: #475160;
}
.product-edit-tab-content .show-base-price-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 22px;
}
.product-edit-tab-content .show-base-price-wrapper .add-price-btn {
  font-size: 16px;
  color: #fff;
  border: 0;
  background: #475160;
  border-radius: 8px;
  padding: 6px 12px;
}
.product-edit-tab-content .filter-by-section {
  border-radius: 12px;
  background: #f9fafb;
}
.edit-status-notification {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 335px;
  color: #475160;
  background: #daf3d7;
  border-radius: 12px;
  padding: 16px 50px 14px 18px;
  left: 12px;
  bottom: 12px;
  gap: 12px;
}
.edit-status-notification .status-block {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.edit-status-notification h6,
.edit-status-notification p {
  line-height: 1;
  margin-bottom: 0;
}
.edit-status-notification h6 {
  font-weight: bold;
  margin-bottom: 5px;
}
.edit-status-notification .bi-check-circle {
  font-size: 16px;
  color: #71a784;
  margin-top: 5px;
}
.edit-status-notification button {
  position: absolute;
  line-height: 1;
  background: 0 !important;
  border: 0 !important;
  top: 22px;
  right: 18px;
}
.edit-status-notification button i {
  font-size: 22px;
  color: #475160;
}
.view-price-modal .modal-dialog {
  max-width: 830px;
}
.view-price-modal .modal-content {
  background: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
}
.view-price-modal .modal-body {
  background: #fff;
  border-radius: 16px;
  padding: 0;
}
.view-price-modal .modal-body-inner {
  padding: 22px 30px;
}
.view-price-modal .edit-profile-wrapper {
  display: block;
  padding: 0;
}
.view-price-modal .edit-profile-wrapper .profile-detail-section {
  max-width: none;
}
.view-price-modal h2 {
  font-size: 23px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 35px;
}
.view-price-modal h4 {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 24px;
}
.view-price-modal h4 i {
  font-size: 14px;
  margin-right: 5px;
  vertical-align: top;
}
.view-price-modal .form-item-set {
  border-bottom: 1px solid #e2e5e9;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
}
.view-price-modal .form-item-set.no-border {
  border: 0;
  margin-bottom: 0;
}
.view-price-modal .datepicker-icon {
  position: absolute;
  top: 18px;
  right: 15px;
}
.view-price-modal .show-promo-price-section-label {
  position: relative;
  top: 3px;
  margin-left: 8px;
}
.view-price-modal .button-set-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f5f7f9;
  padding: 15px;
}
.view-price-modal .button-set-block .form-btn-set {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  gap: 8px;
}
.view-price-modal .button-set-block .form-btn-set .discard-btn,
.view-price-modal .button-set-block .form-btn-set .submit-btn {
  font-size: 16px;
  border-radius: 8px;
  padding: 10px 27px;
}
.view-price-modal .button-set-block .form-btn-set .discard-btn {
  color: #61b8e7;
  border: 1px solid #bde4f5;
  background: 0;
}
.view-price-modal .button-set-block .form-btn-set .submit-btn {
  color: #fff;
  border: 0;
  background: #475160;
}
.view-price-modal .button-set-block .form-btn-set .submit-btn[disabled] {
  color: #bde4f5;
  background: #f2fbfe;
}
.view-price-modal .required-label {
  color: #ea7b7b;
  margin-left: 30px;
}
.view-price-modal .required-label i {
  margin-right: 10px;
}
.datepicker {
  z-index: 9999999 !important;
}
.swal2-container {
  z-index: 9999 !important;
}
.text-right {
  text-align: right;
}
.container-fluid-cu {
  margin: 0;
  max-width: 100% !important;
  width: 101rem;
}

.ico-filter {
  max-width: unset;
}

.select-single-value .select__single-value {
  padding-left: 10px;
}
.select-single-value .select__placeholder {
  padding-left: 10px;
}

.wrap-tag-clear-filter-with-key {
  padding: 5px;
}
.wrap-tag-clear-filter-with-key img {
  vertical-align: initial;
}
.tag-clear-filter-with-key {
  padding-left: 0;
  margin: 0;
}


.hover-disable-color {
  background-color: transparent !important;
  color: inherit !important;
  cursor: default !important;
}
fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
}
.side-menu__item.active {
  background: #f2fbfe;
  font-weight: bold !important;
  color: #333e46 !important;
}

.calendar-block .select2-container--default .select2-selection--single {
  width: 145px;
  height: 44px !important;
  border: 0 !important;
  border-radius: 12px 0 0 12px;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.customs-select
  .select2-container--default
  .selection
  .select2-selection--single {
  border-radius: 12px;
  border: 0 !important;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.customs-select .select2-container--default {
  /* width: 100% !important; */
}

.edit-status-notification-errors {
  position: absolute;
  display: flex;
  width: 335px;
  color: #0000;
  background: rgb(237, 84, 84);
  border-radius: 12px;
  padding: 16px 50px 14px 18px;
  left: 12px;
  bottom: 12px;
  gap: 12px;
}
.edit-status-notification-errors .status-block {
  flex: 1;
}
.edit-status-notification-errors h6,
.edit-status-notification-errors p {
  line-height: 1;
  margin-bottom: 0;
  color: white;
}
.edit-status-notification-errors h6 {
  font-weight: bold;
  margin-bottom: 5px;
}

.edit-status-notification-errors .bi-exclamation-circle {
  font-size: 16px;
  color: rgb(255, 255, 255);
  margin-top: 5px;
}
.edit-status-notification-errors button {
  position: absolute;
  line-height: 1;
  background: 0 !important;
  border: 0 !important;
  top: 22px;
  right: 18px;
  color: white;
}
.edit-status-notification-errors button i {
  font-size: 22px;
  color: #000000;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  font-size: 14px;
}
.select-border-field-block .label-block {
  margin-top: 8px !important;
}
.multiple-dropdown-block
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  display: flex;
  align-items: center;
  font-size: 14px !important;
}
.product-select .select2-container--default {
  height: 58px;
  display: flex;
  align-items: center;
}
.product-select .select2-container--default .selection {
  width: 100%;
}
.product-select .select2-container--default .select2-selection--single {
  justify-content: flex-start !important;
}
.product-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 11px !important;
}
.edit-del
  .select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  margin-right: 30px !important;
}

.edit-del
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 14px !important;
}

.mySlides {
  display: none;
}
.mySlides img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.slides_img-edit .prev,
.slides_img-edit .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.slides_img-edit .next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.slides_img-edit .prev:hover,
.slides_img-edit .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */

/* Number text (1/3 etc) */

/* The dots/bullets/indicators */
/* .dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
} */

.dot:hover {
  background-color: #717171;
}

/* Fading animation */

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .slides_img-edit .prev,
  .slides_img-edit .next {
    font-size: 11px;
  }
}
.slides_img-edit {
  background-color: #f5f7f9;
}
.edit-profile-wrapper
  .profile-detail-section
  fieldset.select-field
  .form-control {
  background-color: rgb(255, 255, 255) !important;
}
.field-disabled .edit-profile-wrapper .profile-detail-section .form-control {
  background-color: rgb(227, 227, 227) !important;
}
.product-drop
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 0px !important;
}
/* 
body *::-webkit-scrollbar{
    width: auto !important;
    height: auto !important;
    transition: none !important;
} */

.span-file-name {
  position: absolute;
  top: 23px;
  left: 100px;
  background: white;
  padding: 8px;
  color: #545e7a;
  width: 300px;
  padding-left: 11px;
}
