.side-header-toggle-menu {
  display: none;
}

#navbarSupportedContent-4 .btn.show,
:not(.btn-check) + .btn:active {
  border: unset !important;
}
.header-title {
  margin-left: 5px;
}
.header-main-title-page {
  display: block;
}

/* Styles for iPads */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* CSS rules for iPad */
  .txt-term-policy,
  .txt-footer-copyright {
    text-align: center;
    margin: 5px;
  }
  .txt-term-policy a {
    margin-bottom: 5px;
  }
  .side-header-toggle-menu {
    display: block;
    width: 38px;
    height: 38px;
    border: 1px solid #e2e5e9;
    border-radius: 8px;
    margin-left: 15px;
  }
  .wrap-row-padding,
  .wrap-row-padding,
  .wrap-row-padding {
    padding: 0 !important;
  }
  .input-bg-search {
    margin-bottom: 20px;
  }
  .input-100 {
    width: 100%;
  }
  .wrap-body-activity {
    padding: 0 !important;
  }
  .field-set {
    margin-bottom: 20px;
  }
  .wrap-dashboard-card {
    /* margin-top: 20px; */
    margin-bottom: 20px;
  }
  .dashboard-card {
    padding: 10px 5px 25px 5px !important;
  }
  .wrap-header-menu-top {
    padding: 15px !important;
    text-align: center;
  }
  .last-data-refresh {
    text-align: center;
  }
  .wrapper-menu-bar {
    margin-bottom: 15px;
  }
  .wrap-header-menu {
    margin-top: 15px;
  }

  .wrap-btn-export-header {
    text-align: right;
    margin-top: 1rem !important;
  }
  .header-main-title-page {
    display: none !important;
  }
  .user-panel-block {
    margin-left: 5px;
  }
  .responsive-navbar .navbar-collapse,
  .sidebar-mini .responsive-navbar .navbar-collapse {
    margin-left: 0;
  }

  .profile-1 {
    justify-content: space-around;
    width: 100%;
  }
  .responsive-navbar .dropdown-menu.dropdown-menu-end.show {
    top: 1.99rem !important;
  }
}

/* Styles for iPad Air */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  /* CSS rules for iPad Air */
  .txt-term-policy,
  .txt-footer-copyright {
    text-align: center;
    margin: 5px;
  }
  .txt-term-policy a {
    margin-bottom: 5px;
  }
  .side-header-toggle-menu {
    display: block;
    width: 38px;
    height: 38px;
    border: 1px solid #e2e5e9;
    border-radius: 8px;
    margin-left: 15px;
  }

  .wrap-row-padding,
  .wrap-row-padding,
  .wrap-row-padding {
    padding: 0 !important;
  }
  .input-bg-search {
    margin-bottom: 20px;
  }
  .input-100 {
    width: 100%;
  }
  .wrap-body-activity {
    padding: 0 !important;
  }
  .field-set {
    margin-bottom: 20px;
  }
  .wrap-dashboard-card {
    /* margin-top: 20px; */
    margin-bottom: 20px;
  }
  .dashboard-card {
    padding: 10px 5px 25px 5px !important;
  }
  .wrap-header-menu-top {
    padding: 15px !important;
    text-align: center;
  }
  .last-data-refresh {
    text-align: center;
  }
  .wrapper-menu-bar {
    margin-bottom: 15px;
  }
  .wrap-header-menu {
    margin-top: 15px;
  }
  .wrap-filter-sort-by .field-set {
    margin-bottom: 0px;
  }
  .wrap-btn-export-header {
    text-align: right;
    margin-top: 1rem !important;
  }
  .header-main-title-page {
    display: none !important;
  }
  .user-panel-block {
    margin-left: 5px;
  }
  .responsive-navbar .navbar-collapse,
  .sidebar-mini .responsive-navbar .navbar-collapse {
    margin-left: 0;
  }

  .profile-1 {
    justify-content: space-around;
    width: 100%;
  }
  .responsive-navbar .dropdown-menu.dropdown-menu-end.show {
    top: 1.99rem !important;
  }
}

/* Styles for mobile devices */
@media only screen and (max-width: 600px) {
  /* CSS rules for mobile devices */
  .txt-term-policy,
  .txt-footer-copyright {
    text-align: center;
    margin: 5px;
  }
  .txt-term-policy a {
    margin-bottom: 5px;
  }
  .side-header-toggle-menu {
    display: block;
    width: 38px;
    height: 38px;
    border: 1px solid #e2e5e9;
    border-radius: 8px;
    margin-left: 15px;
    display: flex;
    align-items: center;
  }

  .header-main-title-page {
    display: none !important;
  }
  .wrap-row-padding,
  .wrap-row-padding,
  .wrap-row-padding {
    padding: 0 !important;
  }
  .input-bg-search {
    margin-bottom: 20px;
  }
  .input-100 {
    width: 100%;
  }
  .wrap-body-activity {
    padding: 0 !important;
  }
  .field-set {
    margin-bottom: 20px;
  }
  .wrap-dashboard-card {
    /* margin-top: 20px; */
    margin-bottom: 20px;
  }
  .dashboard-card {
    padding: 10px 5px 25px 5px !important;
  }
  .wrap-header-menu-top {
    padding: 15px !important;
    text-align: center;
  }
  .last-data-refresh {
    text-align: center;
  }
  .wrapper-menu-bar {
    margin-bottom: 15px;
  }
  .wrap-header-menu {
    margin-top: 15px;
  }
  .wrap-filter-sort-by .field-set {
    margin-bottom: 0px;
  }
  .responsive-paginate {
    flex: auto;
    flex-direction: column !important;
    text-align: center;
  }

  .wrap-btn-clear-all-activity-page {
    text-align: right;
  }
  .select-single-value {
    margin-top: 15px;
  }
  .padding-zero-right .select-single-value {
    margin-top: 0px;
  }
  .wrap-select-sort-by {
    width: 100% !important;
  }
  .wrap-select-sort-by .select-single-value {
    margin-top: unset;
  }
  .wrap-btn-export-header {
    text-align: right;
    margin-top: 1rem !important;
  }
  .user-panel-block {
    margin-left: 5px;
  }
  .responsive-navbar .navbar-collapse,
  .sidebar-mini .responsive-navbar .navbar-collapse {
    margin-left: 0;
  }
  .profile-1 {
    justify-content: space-around;
    width: 100%;
  }
  .responsive-navbar .dropdown-menu.dropdown-menu-end.show {
    top: 1.99rem !important;
  }
}
